import { Inject, Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LocalStorageService } from 'angular-2-local-storage';
import * as moment from 'moment';
import 'moment-timezone';
import { NgxSpinnerService } from 'ngx-spinner';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { HttpCoreService } from '../../../../services/http-core.service';
import { environment } from '../../../../../environments/environment';
import { GenerateAuditBusinessService } from './generate-audit-business.service';

@Injectable()
export class GenerateAuditHttpService {

	auditRestaurant: string = environment.treeDataUrl;
	generateAudit: string = environment.generateAuditLog;
	
	constructor(
		private localStorageService: LocalStorageService,
		private generateAuditBusinessService: GenerateAuditBusinessService,
		private httpCoreService: HttpCoreService,
		private spinnerService: NgxSpinnerService,
		@Inject(SESSION_STORAGE) private sessionStorage: StorageService
    ) { }
	
	public getRestaurantData(apiService) {
		this.spinnerService.show();
		let req = this.restaurantRequestData();
		req['auditLog'] = true;
		return this.httpCoreService.getHttpUrl(apiService, req, 'post').pipe(
		map((response) => {
			const tempArray = [];
			tempArray.push(response);
			this.spinnerService.hide();
			return tempArray;
		}),
		catchError((e: any) => observableThrowError(this.errorHandler(e))),);
	}
	
	public getUserData(apiService, reqParams) {
		const options = {};
		const requestData = this.getUserRequestData(reqParams);
	
		return this.httpCoreService.getHttpUrl(apiService, requestData, 'post');
	}
	
	public getMenuItemList(apiService, data): Observable<any> {
		return this.httpCoreService.getHttpUrl(apiService, data, 'post');
	}
	
	public getLoadLookup(apiService, data): Observable<any> {
		return this.httpCoreService.getHttpUrl(apiService, data, 'post');
	}
	
	public getModulesData(apiService) {
		const options = {};
		this.spinnerService.show();
		let reqData = this.restaurantRequestData();
		reqData.moduleName = 'AL';
	
		return this.httpCoreService.getHttpUrl(apiService, reqData, 'post').pipe(
		map((response) => {
			const tempArray = [];
			tempArray.push(response);
			this.spinnerService.hide();
			return tempArray;
		}
		),catchError((e: any) => observableThrowError(this.errorHandler(e))),);
	}
	
	public getDropdownData(apiService) {
		return this.httpCoreService.getHttpUrl(apiService, this.restaurantRequestData(), 'post');
	}
	
	public generateAuditWithShortcut(apiService, shortcut, days, modulesData) {
		const reqData = this.auditShortcutRequest(shortcut, days, modulesData);
		return this.httpCoreService.getHttpUrl(apiService, reqData, 'post');
	}
	
	public generateAuditLog(apiService, dates, modulesDefault) {
		const reqData = this.generateAuditRequest(dates, modulesDefault);
		return this.httpCoreService.getHttpUrl(apiService, reqData, 'post');
	}
	
	public treeSearch(apiService, reqData, currentTree): Observable<any> {
		return this.httpCoreService.getHttpUrl(apiService,
				this.treeSearchRequest(reqData, currentTree), 'post').pipe(
				map((response) => {
					const tempArray = [];
					if (response) {
						tempArray.push(response);
					}
					return tempArray;
				}
				),catchError((e: any) => observableThrowError(this.errorHandler(e))),);
	}
	
	public restaurantRequestData() {
		const userDetails = this.getValidationDetail();
		const marketIdList = [];
		marketIdList.push(userDetails.mkt);
		const treeRequest = {
				'marketIdList': marketIdList,
				'userIDLong': userDetails.userId,
				'userId': userDetails.eid,
				'localeLangID': '1',
				'moduleName': marketIdList[0] == 0 ? '' : 'USER',
						'searchString': null,
						'searchOption': null,
						'selectByID': null,
						'isSearchRequired': false
		};
		return treeRequest;
	}
	
	public getUserRequestData(reqParams) {
		const userDetails = this.getValidationDetail();
		let status = '';
		const selectedNodeId = [];
		if (reqParams.status === 'Active') {
			status = 'Y';
		} else if (reqParams.status === 'Inactive') {
			status = 'N';
		}
		reqParams.market.forEach(element => {
			selectedNodeId.push(element.id);
		});
		const requestData = {
				'eid': userDetails.eid,
				'mkt': userDetails.mkt,
				'sortFieldName': '',
				'currentSortCriteria': 'ASC',
				'pageNo': '',
				'searchText': reqParams.searchString,
				'status': status,
				'exactMatch': reqParams.exact ? 'Y' : 'N',
						'fullList': !reqParams.searchString && !status ? true : false,
								'selectedNodeId': selectedNodeId
		};
		return requestData;
	}
	
	getValidationDetail() {
		let eid = '';
		let marketId = '';
		let userContext = '';
		let userId = '';
		if (this.localStorageService.get('userEid')) {
			eid = this.localStorageService.get('userEid');
		} else {
			eid = this.sessionStorage.get('userEid');
		}
		if (this.localStorageService.get('marketId')) {
			marketId = this.localStorageService.get('marketId');
		} else {
			marketId = this.sessionStorage.get('marketId');
		}
		if (this.sessionStorage.get('userId')) {
			userId = this.sessionStorage.get('userId');
		} else {
			userId = this.localStorageService.get('userDetails')['id'];
		}
		return {
			eid: eid, mkt: marketId,
			userId: userId
		};
	}
	
	errorHandler(error: any): void {
		console.log(error);
		this.spinnerService.hide();
	}
	
	
	treeSearchRequest(reqObj, currentTree) {
		const userDetails = this.getValidationDetail();
		const marketIdList = [];
		marketIdList.push(userDetails.mkt);
		const req = {
				'marketIdList': marketIdList,
				'userId': userDetails.eid,
				'localeLangID': '1',
				'moduleName': currentTree === 'restaurant' ? 'USER' : 'AL',
						'searchString': '',
						'searchOption': 'null',
						'selectByID': 'null',
						'isSearchRequired': 'false',
						'searchCriteria': reqObj,
						'auditLog': currentTree === 'restaurant' ? true : false
		};
		if (marketIdList[0] == 0 && currentTree === 'restaurant') {
			req.moduleName = '';
		}
		return req;
	}
	
	auditShortcutRequest(shortcut: string, days: number, modulesData) {
		const userDetails = this.getValidationDetail();
		const startDate = days === 0 ? this.formatDate(new Date()) : this.getPreviousDate(days);
		const endDate = this.formatDate(new Date());
		// populating usernames
		const selectedUserNames = [];
		selectedUserNames.push(userDetails.eid);
		// selectedUserNames.push(userDetails.userName);
		const req = {
				'eid': userDetails.eid,
				'mkt': userDetails.mkt,
				// 'restaurantTree': [],
				'searchCriteria': {
					'startDate': startDate,
					'endDate': endDate,
					'selectedCheckBox': shortcut,
					'selectedUserIds': this.getSelectedUserId(),
					'selectedUserNames': [],
					'selectedOperationsIds': modulesData,
					'restSelectionOption': '1'
				}
		};
		return req;
	}
	
	getSelectedUserId() {
		const userDetails = this.getValidationDetail();
		// poulate Id
		const selectedUserIds = [];
		selectedUserIds.push(userDetails.eid);
		return selectedUserIds;
	}
	
	generateAuditRequest(dates, modulesDefault) {
		const userDetails = this.getValidationDetail();
		const userTableData = this.generateAuditBusinessService.createUsersArrays();
		// const userId = userTableData.ids.length ? userTableData.ids :
		// this.getSelectedUserId();
		const modulesOprsIds = this.generateAuditBusinessService.createModulesListForRequest(modulesDefault);
		const req = {
				'eid': userDetails.eid,
				'mkt': userDetails.mkt,
				'restaurantTree': this.generateAuditBusinessService.createRestaurantListForRequest(),
				'searchCriteria': {
					'startDate': dates.startDate,
					'endDate': dates.endDate,
					'selectedCheckBox': '',
					'selectedUserIds': userTableData.ids,
					'selectedUserNames': userTableData.names,
					'selectedOperationsIds': modulesOprsIds,
					'restSelectionOption': '2'
				}
		};
		return req;
	}
	
	getTimezone() {
		let timeZone;
		if (this.localStorageService.get('userDetails')['timeZone']){
			timeZone = this.localStorageService.get('userDetails')['timeZone'];
		} else {
			timeZone = this.sessionStorage.get('timeZone');
		}
		if (!timeZone) {
			timeZone = 'GMT';
		}
		return timeZone;
	}
	
	formatDate(value) {
		const dateFormat = 'MM/DD/YYYY';
		// const formattedDate = value.getMonth() + 1 + '/' + value.getDate() + '/'
		// + value.getFullYear();
		const formattedDate = moment().tz(this.getTimezone()).format('MM/DD/YYYY');
		return formattedDate;
	}
	
	getPreviousDate(days) {
		const currentDate = new Date();
		currentDate.setDate(currentDate.getDate() - days);
		const previousDate = moment(currentDate).tz(this.getTimezone()).format('MM/DD/YYYY');
		return previousDate;
	}

}
