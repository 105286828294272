

import { BehaviorSubject } from 'rxjs';
import { Injectable } from "@angular/core";


@Injectable()
export class HeaderService {

    private isChangeMade = new BehaviorSubject<boolean>(false);
    isChangeMadeCast = this.isChangeMade.asObservable();

    isChangeMadeFunction(isChangeMade) {
        this.isChangeMade.next(isChangeMade);
    }

    errorHandler(error: any): void {
        console.log(error);
    }
}