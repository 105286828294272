import { Injectable } from '@angular/core';
import {UserBean} from '../models/userbean';

@Injectable() 
export class DataService {
	menuItemDeleteList: any;
	menuItemUpdateList: any;
	restaurantDbList: any;
	restaurantPTAList: any;
	promotionUpdateList: any;
	masterBatchList: any;
	reportInfoList: any;
	pkgGenRestaurantList: any;
	jasperReportValue: number;
	
	headerRestNum: string;
	headerRestName: string;
	headerFieldOffice: string;
	headerCoop: string;
	headerPackageId: string;
	headerEffDate: string;
	headerStatus: string;
	headerDeliveryStaus: string;
	reportTitle: string;

	reportName:string;
	reportHeader:string;
	productId: number;
	productCode: number;
	productName: string;
	productStatus: string;
	reportType: string;
	reportTypeData: string;
	searchText: string;
	familyGroup: number;
	approvalStatus: string;
	savedAsPartial: string;
	deleteFlag: string;
	
	checked: boolean;
	
	userBean: UserBean;
	
	setMenuItemDeleteList(menuItemDeleteList){
		this.menuItemDeleteList = menuItemDeleteList;
	}
	getMenuItemDeleteList(){
		return this.menuItemDeleteList;
	}
}