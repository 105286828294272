import { Inject, Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { OwnerOperatorHomepageService } from "../services/owner-operator-homepage.service";

@Injectable({
  providedIn: "root",
})
export class RedirectGuard implements CanActivate {
  userPermissionArray: Array<any> = [];
  userPermission: number = -1;
  userPermissionProfile: number = -1;

  constructor(
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    private oopService: OwnerOperatorHomepageService
  ) {
    this.userPermissionArray = this.sessionStorage.get("permissionMap");
  }

  canActivate(
    route: ActivatedRouteSnapshot
  ): boolean {
    const queryParams = this.buildQueryParams(route.queryParams);
    const isCategory = route.data["externalUrl"].toLowerCase().includes("category");
    const isProfile = route.data["externalUrl"].toLowerCase().includes("profile");
    this.userPermission =
      this.userPermissionArray["REFRESH_OWNER_OPERATOR_HOMEPAGE_GMA_CATEGORY"];
    this.userPermissionProfile = 
      this.userPermissionArray["REFRESH_OWNER_OPERATOR_RESTAURANT_PROFILE"];
    if (this.userPermission < 4 && isCategory) window.location.href = route.data["externalUrl"]+queryParams;
    if (this.userPermissionProfile < 4 && isProfile) window.location.href = route.data["externalUrl"];
    return false;
  }

  buildQueryParams(params: any){
    let result = "";
    if(!params) return result;
    const oopConfigData = this.oopService.oopConfigData;
    const requiredParams = [
      "selectedLanguage",
      "marketId",
      "marketName",
      "localeLangID",
      "userEid",
      "accessToken",
      "deviceType",
      "localeLangDesc",
      "firstName",
      "lastName",
      "messageCount",
      "rfmRefreshPage",
      "selectedDateFormat",
      "timeZone"
    ];
    const userLanguages = this.sessionStorage.get("userDetails")
      ["languageMap"]
      ["refreshUserContext"]
      ["marketLanguageMap"]
      [`${this.sessionStorage.get("marketId")}_${this.sessionStorage.get("localeLangID")}`];
    const messageMap = this.sessionStorage.get("messageMap");
    const permissionMap = this.sessionStorage.get("permissionMap");
    const environmentName = messageMap["environmentName"];
    const sideMenuData = oopConfigData.sort((e1:any,e2:any)=>{
      if (e1.Tile_ID <= e2.Tile_ID)return -1;
      return 1;
    }).map((e:any)=>{
      return {
        name: messageMap[`${e.Tile_ID}_tileTitle`],
        link: e.tileQuickLink
      }
    });
    requiredParams.forEach(key=>{
      result+=`${key}=${JSON.stringify(this.sessionStorage.get(key))}&`
    });
    result += `permission=${JSON.stringify(permissionMap["REFRESH_OWNER_OPERATOR_HOMEPAGE_GMA_CATEGORY"])}&`;
    result += `userLanguages=${JSON.stringify(userLanguages)}&`;
    result += `environmentName=${JSON.stringify(environmentName)}&`;
    result += `sideMenuData=${JSON.stringify(sideMenuData)}`;
    return "?" + btoa(encodeURIComponent(result));
  }
}
