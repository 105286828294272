import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { cloneDeep } from 'lodash';
import { MassPromotionUpdateModel } from '../model/masspromotionupdate.model';
import { MassPromoResultsPipe } from '../utilities/mass-promo-results.pipe';
import { DropdownService } from '../../../services/dropdown.service';
import { DataService } from '../../../models/dataservice';
import { connection } from '../../../../environments/connection.properties';
import { HeaderComponent } from '../../header/header.component';

declare var $: any;

@Component({
  selector: 'app-masspromotionupdate-results',
  templateUrl: './masspromotionupdate.results.component.html',
  styleUrls: ['./masspromotionupdate.results.component.css'],
  providers: [MassPromoResultsPipe]
})
export class MassPromotionResultsComponent implements OnInit {

  	num = 0;
	sortColumn = 'promoCode';
	msg = '0 records found for the criteria.';
	page: any;
  
	//STATUS FILTER - TABLE RESULT LIST
	promoStatusFilter = [];
  	statusResultTableSettings = {};
  
	constructor(
		public massPromotionUpdateModel: MassPromotionUpdateModel,
		public dataService: DataService,
		private http: HttpClient,
		public dropdownService: DropdownService,
		private spinnerService: NgxSpinnerService,
		private promoFilterPipe: MassPromoResultsPipe,
		private headerComponent : HeaderComponent){}
    
	onlineModel = this.massPromotionUpdateModel.online;

	promoUpdateModel = this.massPromotionUpdateModel.online.updateAndReview;
	promoSelectionModel = this.massPromotionUpdateModel.online.promoSelection;
	restSelectionModel = this.massPromotionUpdateModel.online.restSelection;

	resultsModel = this.massPromotionUpdateModel.online.results;
	resultsFilterModel = this.massPromotionUpdateModel.online.results.filter;
	resultsSortModel = this.massPromotionUpdateModel.online.results.sortAscending;

	urlToCall : string ='';

	ngOnInit(){
  		if(connection.RELATIVE_PATH == "true" || connection.RELATIVE_PATH == 'true'){
			this.urlToCall = location.protocol + "//" + location.hostname + ":" + 
				connection.RELATIVE_API_PORT + connection.RELATIVE_API_PATH;
		} else {
			this.urlToCall = connection.API_FULL_URL;
		}
		
		//filter settings
		this.statusResultTableSettings = this.dropdownService.dropDownResultTableSettings;
  	}
  	
	/**
	* Function used to sort the table.
	* @param key - Column key.
	*/
	sort(key) {
		this.sortColumn = key;
		this.resultsModel.table.sort((a, b) => {
			if (a[key] === b[key]) {
				return 0;
			}
		
			if (a[key] === '' || a[key] === null) {
				return 1;
			} else if (b[key] === '' || b[key] === null) {
				return -1;
			} else if (a[key] > b[key]) {
				return 1;
			} else if (a[key] < b[key]) {
				return -1;
			}
			return 0;
		});

		if(this.resultsModel.sortAscending[key]){
			this.resultsModel.table.reverse();
		}
		this.resultsModel.sortAscending[key] = !this.resultsModel.sortAscending[key];
		//this.triggerPipe();
	}
  
  	/**
	* Function used to refresh the view by triggering the pipe transform function.
	*/
	triggerPipe() {
		this.num = Math.random();
	}
  
	generateMassPromotionUpdateReportFile(typeFile){
		if(typeFile === "PRTSCR"){
			
			const printContent = document.getElementById("massPromoUpdateResultsSection");
			const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
			WindowPrt.document.write(printContent.innerHTML);
			WindowPrt.document.close();
			WindowPrt.focus();
			WindowPrt.print();
			WindowPrt.close();
			

			// window.print();
			return false;
		}
	
		//get file from service
		this.dataService.promotionUpdateList = this.resultsModel.table;
    	
    	this.dataService.reportName = "MassPromotionUpdateReport";
		this.dataService.reportType = typeFile;
		this.dataService.reportTypeData = 'abstract';
		this.dataService.reportHeader = 'MassPromotionUpdateReview';
		this.dataService.userBean = this.massPromotionUpdateModel.userBean;
		
		const options  = {
			headers: new HttpHeaders({
			  'Content-Type': 'application/json; charset=utf-8'
			}),
			responseType: 'text' as 'text'
		};
        this.http.post(this.urlToCall+'generateMassPromotionUpdateReport', JSON.stringify(this.dataService), options) 
    	.subscribe(
        	(response) => { 
   			let generatePDFUrlGetFile = "getGeneratedReport?reportName="+response+"&reportType="+typeFile+"&reportHeader="+this.dataService.reportHeader;
  			let generatePDFUrl = this.urlToCall+generatePDFUrlGetFile;
  			window.open(generatePDFUrl, '_blank');
    	})
    };

	filterPromo(){
		if (this.resultsModel.table.length === this.resultsModel.duplicatePromoResultList.length) {
		    this.resultsModel.duplicatePromoResultList = cloneDeep(this.resultsModel.table);
		}
		this.resultsModel.table = this.promoFilterPipe.transform(
		    this.resultsModel.duplicatePromoResultList, this.resultsFilterModel, this.promoStatusFilter);
	}

	doneChanges(){
		this.headerComponent.goToRFM();
	}

}
