import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopModoleMessComponent } from './pop-modole-mess.component';

@NgModule({
  declarations: [PopModoleMessComponent],
  imports: [
    CommonModule
  ],
  exports: [
    PopModoleMessComponent
  ]
})
export class PopModoleMessModule { }
