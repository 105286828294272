import { Injectable, Inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, CanLoad } from '@angular/router';
import { Observable } from 'rxjs';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Router } from '@angular/router';
import { PermissionExpressionEvaluatorService } from '../../services/permission-expression-evaluator.service'
import { Expression, Operators, Rule } from '../../services/permission-expression-evaluator.service';
import { Permissions } from './auth-gaurd-permissions';

@Injectable({
  providedIn: 'root'
})


export class OwnerOperatorAuthGuard implements CanActivate {

  private permissions: any = {};


  constructor(@Inject(SESSION_STORAGE) private sessionStorage: StorageService, private router: Router, private permEvaluator: PermissionExpressionEvaluatorService) {

    this.permissions[Permissions.ActionItemAdminPermission] = new Expression(Operators.AND, [
      new Rule('REFRESH_ACTION_ITEM_MANAGEMENT', ['1', '2','3'], Operators.IN)     
    ]);
    this.permissions[Permissions.ActionItemEditPermission] = new Expression(Operators.AND, [
      new Rule('REFRESH_ACTION_ITEM_MANAGEMENT', ['1', '2','3'], Operators.IN),
      new Rule('REFRESH_ACTION_ITEM_MANAGEMENT_EDIT', ['1', '2','3'], Operators.IN)      
    ]);
    this.permissions[Permissions.ActionItemCreatePermission] = new Expression(Operators.AND, [
      new Rule('REFRESH_ACTION_ITEM_MANAGEMENT', ['1', '2','3'], Operators.IN),
      new Rule('REFRESH_ACTION_ITEM_MANAGEMENT_CREATE', ['1', '2'], Operators.IN)     
    ]);

    this.permissions[Permissions.PackageGenerationPermission] = new Expression(Operators.AND, [
      new Rule('REFRESH_OWNER_OPERATOR_HOMEPAGE_PACKAGE_GENERATION', ['1', '2', '3'], Operators.IN),
      new Rule('REFRESH_PACKAGE_GENERATION_GENERATE_NOW', ['1', '2', '3'], Operators.IN)
    ]);

    this.permissions[Permissions.MIIPermission] = new Expression(Operators.AND, [new Rule('REFRESH_OWNER_OPERATOR_HOMEPAGE_MENU_ITEM_INACTIVATION', ['1', '2', '3'], Operators.IN)]);

    this.permissions[Permissions.GenerateReportPermission] = new Expression(Operators.AND, [new Rule('REFRESH_OWNER_OPERATOR_HOMEPAGE_REPORTS', ['1', '2', '3'], Operators.IN)]);

    this.permissions[Permissions.POSLayoutPermission] = new Expression(Operators.AND, [new Rule('REFRESH_OWNER_OPERATOR_HOMEPAGE_POS_LAYOUT', ['1', '2', '3'], Operators.IN)]);

    this.permissions[Permissions.PTAPermission] = new Expression(Operators.AND, [new Rule('REFRESH_OWNER_OPERATOR_HOMEPAGE_PRICE_TAX_AND_ACTIVATE', ['1', '2', '3'], Operators.IN)]);

    this.permissions[Permissions.OOPHomePermission] = new Expression(Operators.AND, [new Rule('REFRESH_OWNER_OPERATOR_HOMEPAGE', ['1', '2', '3'], Operators.IN)]);

    this.permissions[Permissions.MassMIDeletePermission] = new Expression(Operators.AND, [new Rule('REFRESH_OWNER_OPERATOR_MASS_MI_DELETE', ['1', '2', '3'], Operators.IN)]);
    
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (route.data.permissionExpression) {
      return this.permEvaluator.evaluate(this.permissions[route.data.permissionExpression]);
    }

    return true;
  }
}
