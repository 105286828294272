import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// containers
import { FooterComponent } from './footer.component';
 
// routes
export const ROUTES: Routes = [
  { path: '', component: FooterComponent }
];

@NgModule({
  imports: [ 
    RouterModule.forChild(ROUTES)
  ],
  declarations: [    
    FooterComponent 
  ],
  exports: [FooterComponent]
})
export class FooterModule {}
