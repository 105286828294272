import {Router, ActivatedRoute, Params} from '@angular/router';
import {OnInit, Component} from '@angular/core';
import {LocalStorageService} from 'angular-2-local-storage';


@Component({
  selector: 'app-data-transfer',
  templateUrl: './data-transfer.component.html'
})

export class DataTransferComponent implements OnInit {

	constructor(
		private route: ActivatedRoute,
		private router: Router,		
  		private localStorageService: LocalStorageService
	){}

	ngOnInit() {
        //Using Observable
    	this.route.queryParams.subscribe(params => {
        	console.log(params);
			this.localStorageService.set('localeLangDesc', params.localeLangDesc);
			this.localStorageService.set('selectedLanguage', params.selectedLanguage);
			this.localStorageService.set('marketId', params.marketId);
			this.localStorageService.set('token', params.token);
			this.localStorageService.set('accessToken', params.accessToken);
			this.localStorageService.set('userEid', params.userEid);
            this.localStorageService.set('redirect', params.redirect);
			this.localStorageService.set('rfmRefreshPage', params.rfmRefreshPage);
			this.localStorageService.set('ptaURL', params.ptaURL);
			this.localStorageService.set('layeringLogicType', params.layeringLogicType);
			this.localStorageService.set('menuItemCode', params.menuItemNumber);
			this.localStorageService.set('menuItemName', params.menuItemName);
			this.localStorageService.set('menuItId', params.menuItemId);
			this.localStorageService.set('deleteFlag', params.deleteFlag);
			this.localStorageService.set('localeLangID', params.localeLangID);
			this.localStorageService.set('selectedDateFormat', params.selectedDateFormat);
			this.localStorageService.set('marketName', params.marketName);
			this.localStorageService.set('userToken', params.userToken);
			this.localStorageService.set('timeZone', params.timeZone);
			
      	});

        console.log("ngOnInit before rfmRefreshPage" + this.localStorageService.get('rfmRefreshPage'));
        
            window.location.href = this.localStorageService.get('rfmRefreshPage');

		console.log("ngOnInit after rfmRefreshPage");
  	}

}