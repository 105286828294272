import { Pipe, PipeTransform } from '@angular/core';
import { MassMenuItemDeleteReportService } from '../../components/massmenuitemdeletereport/services/massmenuitemdeletereport.service';
// import { MassMenuItemDeleteReportService } from '../../services/massmenuitemdeletereport.service';

@Pipe({
	name: 'menuLazyLoadFilter'
})

export class MenuLazyLoadFilterReportPipe implements PipeTransform {
	constructor(
		private massMenuItemDeleteReportService : MassMenuItemDeleteReportService
	){}

	transform(items: any, idSearch: any, nameSearch: any, classSearch:any, statusSearch:any, startIndex: any): any{
    	if (items && items.length){
	      	let filtered = false;
	        return items.filter(item =>{
				if(nameSearch && item.menuItemName.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1){
		        	this.massMenuItemDeleteReportService.filteredStartCount = 0;
		            this.massMenuItemDeleteReportService.originalStartCount = 0;
		            return false;
		        }
		        if(idSearch && item.menuItemCode.toString().indexOf(idSearch.toString()) === -1) {
					this.massMenuItemDeleteReportService.filteredStartCount = 0;
					this.massMenuItemDeleteReportService.originalStartCount = 0;
					return false;
				}
				
				if(classSearch && item.hasAssociation.toLowerCase().indexOf(classSearch.toLowerCase()) === -1) {
					this.massMenuItemDeleteReportService.filteredStartCount = 0;
					this.massMenuItemDeleteReportService.originalStartCount = 0;
					return false;
				}
				
				if (statusSearch){
					if (item.viewDetail !=  null){
						if(item.viewDetail.toLowerCase().indexOf(statusSearch.toLowerCase()) === -1) {
							this.massMenuItemDeleteReportService.filteredStartCount = 0;
							this.massMenuItemDeleteReportService.originalStartCount = 0;
							return false;
						}
						}else{
							return false;
						}
				}
				
		        return true;
			});
	    } else {
	        return items;
	    }
	}

}