import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pop-modole-mess',
  templateUrl: './pop-modole-mess.component.html',
  styleUrls: ['./pop-modole-mess.component.scss']
})
export class PopModoleMessComponent {

    modalReference: NgbModalRef;
    @Input() popHeader: string = '';
    @Input() popBody: string;
    @Input() popBodySections: boolean = false;
    @Input() popBodySectionsSubHeader: string;
    @Input() popBodySectionsBodyText: string;
    @Input() firstButtonName: string;
    @Input() secondButtonName: string;
    @Input() firstButtonDisplay: boolean = true;
    @Input() secondButtonDisplay: boolean = true;
    @Output() actionName = new EventEmitter();
    @ViewChild('modal') textModal: PopModoleMessComponent;

    constructor(private modalService: NgbModal){}

    open() {
        this.modalReference = this.modalService.open(this.textModal);
    }

    closeEvent() {
        this.actionName.emit("closeEvent");
        this.modalReference.close();
    }

    FirstButton(value) { // Yes
        this.actionName.emit(value);
        this.modalReference.close();
    }

    SecondButton(value) {  // close button (No)
        this.actionName.emit(value);
        this.modalReference.close();
    }
}
