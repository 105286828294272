import {Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'dataFormat'
})
export class DataFormatPromoFilterPipe implements PipeTransform {
    transform(items: any){
        if(items){
            if (items == '0') {
                return 'Inactive';
            }
            if (items == '1') {
                return 'Active';
            }
        } else {
            return items;
        }
    }
}
