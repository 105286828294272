export const MMID_CONSTANTS = {

    analyticsData: {
        //RFM-22513, RFM-22514
        MMIDConfirmModalAction: { 
            pageName: "Mass Menu Item Delete - Review",
            pageType: 'Modal',
            // only one value to be sent based on option selected
            clickName: '[Yes] or [No]',
            clickTrackSection: 'Content',
            linkType: 'CTA',
            addInfo: 'Delete Confirmation'
        },
        //RFM-22451, RFM-22452
        MMIDClickEventData: {
            pageName: "Mass Menu Item Delete",
            pageType: 'Modal',
            clickName: '',
            clickTrackSection: 'Content',
            linkType: 'CTA',
            addInfo: 'Menu Items Shown'
        },
        //RFM-22511, RFM-22512
        MMIDCancelModalAction: { 
            pageName: "Mass Menu Item Delete - Review",
            pageType: 'Modal',
            // only one value to be sent based on option selected
            clickName: '[Yes] or [No]',
            clickTrackSection: 'Content',
            linkType: 'CTA',
            addInfo: 'Delete Cancellation'
        },
        MMIDReviewPageViewButton : {
            pageName: 'Mass Menu Item Delete - Review',
            pageType: 'Modal',
            pageUrl: 'tbd',
            clickName: 'View',
            clickTrackSection: 'Content',
            linkType: 'CTA'           
        },
        //RFM-22450
        MMIDSearchClick: {
            pageName: "Mass Menu Item Delete",
            pageType: 'Modal',
            clickName: 'Search',
            clickTrackSection: 'Content',
            linkType: 'CTA'
        },
      MMIDAssociations: {
        'pageName': 'Mass Menu Item Delete - Details',
        'pageType': 'Modal',
        'pageUrl': 'tbd',
        'clickName': 'Send or Cancel',
        'clickTrackSection': 'Content',
        'addInfo': 'Email Export',
        'linkType': 'CTA'
        },

        //RFM-22721
        MMIDDynamicModalOKClick: {
            pageName: "Mass Menu Item Delete",
            pageType: 'Modal',
            clickName: 'OK',
            pageUrl : 'tbd',
            clickTrackSection: 'Content',
            linkType: 'CTA'
        },

        //RFM-22722 
        MMIDDynamicModalView: {
            pageName: "Mass Menu Item Delete - Details",
            pageType: 'Modal',
            pageUrl : 'tbd'
        },

        MMIDDynamicModalOKShow: {
            pageName: "Mass Menu Item Delete - Details",
            pageType: 'Modal',
            clickName: 'OK',
            pageUrl : 'tbd',
            clickTrackSection: 'Content',
            linkType: 'CTA'
        },

        //RFM-22681
        MMIDClearClick: {
            pageName: "Mass Menu Item Delete",
            pageType: 'Modal',
            clickName: 'Clear',
            pageUrl : 'tbd',
            clickTrackSection: 'Content',
            addInfo: 'Menu Items Shown',
            linkType: 'CTA'
        },
        MMIDConfirmEmailModalAction: { 
            pageName: "Mass Menu Item Delete - Confirmation",
            pageType: 'Modal',
            // only one value to be sent based on option selected
            clickName: '',
            pageUrl : '',
            clickTrackSection: 'Content',
            linkType: 'CTA',
            addInfo: 'Email Export'
        },
        MMIDConfirmFinishButtonAction: { 
            pageName: "Mass Menu Item Delete - Confirmation",
            pageType: 'Modal',
            // only one value to be sent based on option selected
            clickName: 'Finish',
            pageUrl : '',
            clickTrackSection: 'Content',
            linkType: 'CTA'
        },
        //RFM-22453
        MMIDReviewPageView : {
            pageName: "Mass Menu Item Delete - Review",
            pageType: 'Modal'
        },
        //RFM-22508
        MMIDReviewCancelData: {
            pageName: "Mass Menu Item Delete - Review",
            pageType: 'Modal',
            clickName: 'Cancel',
            pageUrl : '',
            clickTrackSection: 'Content',
            linkType: 'CTA'
        }, 
        //RFM-22675
        MMIDAssociationModalOKClick: { 
            pageName: "Mass Menu Item Delete - Details",
            pageType: 'Modal',
            pageUrl : '',
            clickName: 'OK',
            clickTrackSection: 'Content',
            linkType: 'CTA'
        },
        //RFM-22676
        MMIDAssociationModalExportAction: { 
            pageName: "Mass Menu Item Delete - Details",
            pageType: 'Modal',
            // only one value to be sent based on option selected
            clickName: '[PDF] , [CSV] or [Email]',
            clickTrackSection: 'Content',
            linkType: 'Dropdown'
        },
        //RFM-22679
        MMIDClassDropdown: {
            'pageName': 'Mass Menu Item Delete',
            'pageType': 'Modal',
            'pageUrl': '',
            'clickName': '',
            'clickTrackSection': 'Class',
            'linkType': 'Dropdown'
        },
        //RFM-22680
        MMIDApprovalStatusDropdown: {
            'pageName': 'Mass Menu Item Delete',
            'pageType': 'Modal',
            'pageUrl': '',
            'clickName': '',
            'clickTrackSection': 'Approved Status',
            'linkType': 'Dropdown'
        },
        //RFM-22510
        MMIDReviewDeleteData: {
            'pageName': 'Mass Menu Item Delete - Review',
            'pageType': 'Modal',
            'pageUrl': '',
            'clickName': 'Delete',
            'clickTrackSection': 'Content',
            'linkType': 'CTA'
        },
        //RFM-22509
        MMIDConfirmationPageView: {
            'pageName': 'Mass Menu Item Delete - Confirmation',
            'pageType': 'Modal',
            'pageUrl': '',
        },

        //RFM-22516
        MMIDAssociationModalDynLinkClick: {
            'pageName': 'Mass Menu Item Delete - Details',
            'pageType': 'Modal',
            // calculated base on functional area
            'clickName': '[Functional Area]',
            'clickTrackSection': 'Content',
            'linkType': 'List'
        },
        //RFM-22515
        MMIDAssociationModalDynLinkPageView: {
            // calculated base on functional area
            'pageName': 'Mass Menu Item Delete – [Functional Area]',
            'pageType': 'Modal'
        },
        //RFM-24665
        MMIDSearchCancelModalAction: {
            'pageName': 'Mass Menu Item Delete',
            'pageType': 'Modal',
            // only one value to be sent based on option selected
            'clickName':  '[Yes] OR [No]',
            'clickTrackSection': 'Content',
            'linkType': 'CTA',
            'addInfo': 'Delete Cancellation'
        },
        //RFM-22715
        MMIDConfirmationExportAction: {
            pageName: "Mass Menu Item Delete - Confirmation",
            pageType: 'Modal',
            // only one value to be sent based on option selected
            clickName: '[PDF] , [CSV] or [Email]',
            clickTrackSection: 'Content',
            linkType: 'Dropdown'
        },
    }
};