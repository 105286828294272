export const connection = {
RELATIVE_PATH: 'true',
RELATIVE_API_PATH: '/rfmRefreshApp/api/',
RELATIVE_API_PORT: '443',
API_FULL_URL: 'http://localhost:9080/rfmRefreshApp/api/',
RFM_HOME_API_PATH: '/rfm2OnlineApp/topMenu.action?param=HOME&token=',
RFM_HOME_FULL_URL: 'http://localhost:9080',
HEADER_ENV: 'AWSPROD',
PARAM_LOGIN: 'TEST-RDI',
RELATIVE_IMAGE_URL: "/rfm2OnlineApp/images/",
RFM_IMAGE_TILE_URL: "http://localhost:4200/rfm2OnlineApp/images/",
};
