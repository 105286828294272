import { Injectable, Inject } from '@angular/core';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { OOP_CONST } from '../constants/rfmOOP.constant';

@Injectable({
  providedIn: 'root'
})
export class OwnerOperatorPermissionsService {
  userPermissionArray: Array<any> = [];
  userPermission: number;
  constructor(@Inject(SESSION_STORAGE) private sessionStorage: StorageService) { }

  ownerOperatorPermissions(permissionFlag){
    this.userPermissionArray = this.sessionStorage.get(OOP_CONST.PERMISSIONMAP);
    if(permissionFlag !=''){
        this.userPermission=this.userPermissionArray[permissionFlag];
    } 
    return this.userPermission;
  }
}
