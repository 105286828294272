import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'treeFilter'
})
export class TreeFilterPipe implements PipeTransform {

  transform(tree: any, filterValue: string, expand?: boolean): any {
    if (filterValue) {
      let filterArray = [];
      // Passing the region data to filter
      let regions = tree[0].children;
      let restExist = false;
      // let 
      if (regions && regions.length) {
        tree[0].expanded = true;
        let obj = {
          name: tree[0].name,
          children: []
        }
        filterArray.push(obj);
        let filteredRegions = filterArray[0].children;
        //Iterating the regions.
        for (let i = 0; i < regions.length; i++) {
          let regionPresent = false;
          let coopPresent = false;
          let coopParent = false;
          let restPresent = false;
          let restParent = false;
          if (regions[i].name.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1) {
            regions[i].visible = true;
            regionPresent = true;
          } else {
            regions[i].visible = false;
          }
          // Iterating the coop
          if (regions[i] && regions[i].children) {
            coopPresent = false;
            for (let j = 0; j < regions[i].children.length; j++) {
              if (regions[i].children[j].name.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1) {
                regions[i].children[j].visible = true;
                // Showing and expanding the parent(region) node.
                regions[i].visible = true;
                regions[i].expanded = true;
                coopPresent = true;
                coopParent = true;
              } else {
                regions[i].children[j].visible = false;
              }
              // Iterating the restaurant.
              if (regions[i] && regions[i].children && regions[i].children[j] && regions[i].children[j].children) {
                restPresent = false;
                for (let k = 0; k < regions[i].children[j].children.length; k++) {
                  if (regions[i].children[j].children[k].name.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1) {
                    // Showing the current restaurant.
                    regions[i].children[j].children[k].visible = true;
                    // Showing and expanding the parent(coop) node.
                    regions[i].children[j].visible = true;
                    regions[i].children[j].expanded = true;
                    // Showing and expanding the parent(region) node.
                    regions[i].visible = true;
                    regions[i].expanded = true;
                    restPresent = true;
                    restParent = true;
                  } else {
                    regions[i].children[j].children[k].visible = false;
                  }
                }
                if (!restPresent && !restParent && (regions[i].children[j].visible)) {
                  this.makeAllNodesVisible(regions[i].children[j].children);
                }
              }
              // restaurant loop end
              if (!restPresent && !restParent && !coopParent && !coopPresent && regions[i].visible) {
                this.makeAllNodesVisible(regions[i].children);
              }

            }
          }
        }
      }
    }
    return tree;
  }

  makeAllNodesVisible(nodes) {
    nodes.forEach(node => {
      node.visible = true;
      if (node.children && node.children.length > 0) {
        this.makeAllNodesVisible(node.children);
      }
    });
  }
}
