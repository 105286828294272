export const MII_CONST = {



    /*Menu Item Inactivation constants*/
    MSG_MAP_KEY: 'messageMap',
    USER_EID_KEY: 'userEid',
    MARKET_ID_KEY: 'marketId',
    YYYY_MM_DD: 'YYYY-MM-DD',
    DATE_FORMAT_KEY: 'selectedDateFormat',
    MM_DD_YYYY: "MM/dd/yyyy",
    API_FORMAT: "MM/DD/YYYY",
    PERMISSION_MAP_KEY: 'permissionMap',
    LOCALE_LANG_KEY: 'localeLangID',
    INACTIVE_KEY: 'INACTIVE',
    NOT_APPROVED_KEY: 'NOTAPPROVED',
    Not_Approved: 'Not Approved',
    SHOW: 'Show',
    HIDE: 'Hide',
    TRUE: 'true',
    CLOSE_EVENT: 'closeEvent',
    GENERATE_MII_REPORT_KEY: 'generateMenuItemInactivationReportPDF',

    /*Analytics Constant*/
    pageName: "Menu Item Inactivation",
    confirmation: "Confirmation",
    finish: "Finish"
}