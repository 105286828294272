import { Injectable, Inject } from '@angular/core';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';


/**
 * Operator constants
 */
export class Operators {
  public static AND: string = 'AND';
  public static OR: string = 'OR';
  public static EQUALS: string = 'equals';
  public static NOT_EQUALS: string = 'not equals';
  public static CONTAINS: string = 'contains';
  public static NOT_CONTAINS: string = 'not contains';
  public static IN: string = 'in';
  public static NOT_IN: string = 'not in';
}

export class Rule {
  private permissionName: string
  private expectedValue: any
  private operator: string

  public constructor(permissionName: string, expectedValue: any, operator: string) {
    this.permissionName = permissionName;
    this.expectedValue = expectedValue;
    this.operator = operator;
  }

  public getPermissionName(): string {
    return this.permissionName
  }

  public check(value: any): boolean {
    switch(this.operator) {
      case Operators.CONTAINS:
        return value && value.indexOf(this.expectedValue) >= 0;
      case Operators.EQUALS:
          return value && value == this.expectedValue;
      case Operators.NOT_EQUALS:
        let inverseEqualsRule = new Rule(this.permissionName, this.expectedValue, Operators.EQUALS);
       
        return !inverseEqualsRule.check(value);
      case Operators.IN: 
        for(let i = 0; i < this.expectedValue.length; i++) {
          let equalsRule = new Rule(this.permissionName, this.expectedValue[i], Operators.EQUALS);
         
          if(equalsRule.check(value)) {
            return true;
          }
        }
        return false;
      case Operators.NOT_IN: 
        let inverseInRule = new Rule(this.permissionName, this.expectedValue, Operators.IN);
       
        return !inverseInRule.check(value);
    }
    return true;
  }
}

export class Expression {
  private gop: string
  private rules: Rule[] = []

  public constructor(gop: string, rules: Rule[]) {
    this.gop = gop;
    this.rules = rules;
  }

  evaluate(context: any): boolean {

    for(let i = 0; i < this.rules.length; i++) {
      let rule = this.rules[i];
      let ruleResult = rule.check(context[rule.getPermissionName()]);

      if(this.gop == Operators.AND && !ruleResult) {
        return ruleResult;
      }

      if(this.gop == Operators.OR && ruleResult) {
        return ruleResult;
      }

    }

    if(this.gop == Operators.OR) {
      return false;
    }

    return true;
  }
}

@Injectable({
  providedIn: 'root'
})
export class PermissionExpressionEvaluatorService {

  private exprEvalCache: Map<Expression, boolean> = new Map<Expression, boolean>();

  constructor(@Inject(SESSION_STORAGE) private sessionStorage: StorageService) { 
  }


  evaluate(expr: Expression): boolean {
    let cachedResult = this.exprEvalCache.get(expr);
    if(cachedResult) {
      return cachedResult;
    }

    let permissionMap = this.sessionStorage.get('permissionMap');

    let result = expr.evaluate(permissionMap);

    this.exprEvalCache.set(expr, result);
    
    return result;
  }
}
