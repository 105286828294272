import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { Inject } from '@angular/core';
import { StorageService, SESSION_STORAGE } from 'ngx-webstorage-service';

@Pipe({
  name: 'formatDateOne'
})
export class FormatDatePipeOne implements PipeTransform {
  constructor(
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
  ) { }

  transform(value: any, toFormat: string, fromFormat: string): any {
    if (value) {
      return moment(value, fromFormat).isValid() ? moment(value, fromFormat).format(toFormat) : '';
    }
    else return '';
  }

}
