import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'restaurantFilter'
})
export class RestaurantFilterPipe implements PipeTransform {

  transform(items: any, value: any, field: string): any {
    if (items && items.length) {
      return items.filter(item => {
        if (value && field === 'number' && item.restaurant.restNu.toLowerCase().indexOf(value.toLowerCase()) === -1) {
          return false;
        }
        // name
        if (value && field === 'name' && item.restaurant.name.toLowerCase().indexOf(value.toLowerCase()) === -1) {
          return false;
        }
        // region
        if (value && field === 'region' && item.region.name.toLowerCase().indexOf(value.toLowerCase()) === -1) {
          return false;
        }
        // coop
        if (value && field === 'coop' && item.coop.name.toLowerCase().indexOf(value.toLowerCase()) === -1) {
          return false;
        }
        return true;
      });
    } else {
      return items;
    }
  }
}
