import { Pipe, PipeTransform } from '@angular/core';



@Pipe({

    name: 'arrayLimit'

})

export class ArrayLimitPipe implements PipeTransform {
    transform(val: any[], args: number = 0) {

        if (args === undefined) {
            return val;
        }
        if (val.length > args) {
            return val.splice(args);
        } else {
            return val;
        }

    }



}