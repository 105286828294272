import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

// containers
import { DataTransferComponent } from './data-transfer.component'; 

// routes
export const ROUTES: Routes = [
  { path: '', component: DataTransferComponent }
];

@NgModule({
  imports: [ 
    RouterModule.forChild(ROUTES),
    CommonModule
  ],
  declarations: [
    DataTransferComponent
  ],
  exports : [
    DataTransferComponent
  ]
})
export class DataTransferModule {}
