export class UserBean {
	userEid: string;
	marketId: number;
	localeLangId: string;
}
export class UserData {
	actionItemId: number;
	actionItemStatus: string;
	actionItemSubject: string;
	actionItemType: number;
	mktId: number;
	sendDate: string;
	marketId: string;
	userEid: string;
}
