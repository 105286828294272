import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class BatchSchedulingService {
  private showHeadMessage = new BehaviorSubject<boolean>(false);
  cast = this.showHeadMessage.asObservable();

  private classCollapseTwo = new BehaviorSubject<String>("collapsed");
  classCollapseTwoCast = this.classCollapseTwo.asObservable();

  private selectedNodes = new BehaviorSubject<Array<String>>([]);
  selectedNodesCast = this.selectedNodes.asObservable();

  private selectedBatchSchedule = new BehaviorSubject<String>("");
  selectedBatchScheduleCast = this.selectedBatchSchedule.asObservable();

  private createOrEdit = new BehaviorSubject<boolean>(false);
  createOrEditCast = this.createOrEdit.asObservable();

  private batchSchedulingDetails = new BehaviorSubject<Array<any>>([]);
  batchSchedulingDetailsCast = this.batchSchedulingDetails.asObservable();

  private filterEmpty = new BehaviorSubject<boolean>(false);
  filterEmptyCast = this.filterEmpty.asObservable();

  constructor() { }

  editShowHeadMessage(newshowHeadMessage) {
    this.showHeadMessage.next(newshowHeadMessage);
  }

  editClassCollapseTwo() {

    if (this.classCollapseTwo.value === "collapsed") {
      this.classCollapseTwo.next("accordianpadd");
    } else {
      this.classCollapseTwo.next("collapsed");
    }
  }

  fillNodesSelectedOfBatchScheduleEdit(BatchScheduling) {

    if (BatchScheduling != "") {
      this.selectedBatchSchedule.next(BatchScheduling);
      let nodes = [];
      for (let res of BatchScheduling.nodes) {
        let node = {
          "name": res.nodeName,
          "restNu": res.restNumber,
          "child": res.nodeNumber
        };
        nodes.push(node);
      }
      this.selectedNodes.next(nodes);
    }else{
      this.selectedBatchSchedule.next("");
      this.selectedNodes.next([]);
    }
  }

  createOrEditFunc(value) {
    this.createOrEdit.next(value);
  }

  editBatchSchedulingDetails(batchSchedulingDetails) {
    this.batchSchedulingDetails.next(batchSchedulingDetails);
  }

  editFilterEmpty(filterEmpty) {
    this.filterEmpty.next(filterEmpty);
  }

}
