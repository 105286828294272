export const appconstant = {

    AUDIT_LOG_REPORT_FUNC_ID: 'AUDIT_LOG_REPORT_FUNC_ID',
    VIEW_GENERATED_REPORTS_FUNC_ID: 'VIEW_GENERATED_REPORTS_FUNC_ID',
    PKG_SCHEDULE_FUNC_ID: 'PKG_SCHEDULE_FUNC_ID',
    VIEW_AUDIT_LOG_DETAILS_ID: 'VIEW_AUDIT_LOG_DETAILS_ID',
    MASS_MENU_ITEM_DELETION_FUNC_ID: 'MASS_MENU_ITEM_DELETION_FUNC_ID',
    REST_DB_PARAMETER_CUSTOMIZATION_FUNC_ID: 'REST_DB_PARAMETER_CUSTOMIZATION_FUNC_ID',
    GENERATE_PTA_FUNC_ID: 'GENERATE_PTA_FUNC_ID',
    REST_DB_PARAM_FUNC_ID: 'REST_DB_PARAM_FUNC_ID',
    MASS_MENU_ITEM_UPDATE_FUNC_ID: 'MASS_MENU_ITEM_UPDATE_FUNC_ID',
    MASS_PROMOTION_UPDATE_FUNC_ID: 'MASS_PROMOTION_UPDATE_FUNC_ID',
    PACKAGE_CANCEL_MESSAGE: 'Are sure you want to cancel? Any unsaved data will be lost',
    PACKAGE_SAVE_MESSAGE: 'Your changes have been saved',
    MSG_INVALID_INPUT: 'Invalid input.',
    MASTER_BATCH_PKG_SCHEDULE_FUNC_ID: 'MASTER_BATCH_PKG_SCHEDULE_FUNC_ID',
    PACKAGE_GENERATION_GENERATE_NEW_PACKAGE: 'PACKAGE_GENERATION_GENERATE_NEW_PACKAGE',
    OWNER_OPERATOR_HOMEPAGE_REPORTS: "OWNER_OPERATOR_HOMEPAGE_REPORTS",
    ACTION_ITEM_MANAGEMENT_FUNC_ID: "ACTION_ITEM_MANAGEMENT_FUNC_ID",
    MESSAGE_CREATION_ADMIN_FUNC_ID: "MESSAGE_CREATION_ADMIN_FUNC_ID",
    MESSAGE_VIEW_OOP_FUNC_ID: "MESSAGE_VIEW_OOP_FUNC_ID",
    OWNER_OPERATOR_HOMEPAGE_POS_LAYOUT: "OWNER_OPERATOR_HOMEPAGE_POS_LAYOUT",
    PRICE_TAX_ACTIVATION_FUNC_ID: "PRICE_TAX_ACTIVATION_FUNC_ID",
    MENU_ITEM_INACTIVATION_FUNC_ID: "MENU_ITEM_INACTIVATION_FUNC_ID",
    API_SQL_DT_FORMAT: "YYYY-MM-DD",
    MASS_MENUITEM_DELETE_FUNC_ID: "MASS_MENUITEM_DELETE_FUNC_ID",
    DIMENSION_GROUPS_FUNC_ID:"DIMENSION_GROUPS_FUNC_ID",
    MASS_MENUITEM_ACT_INACT_FUNC_ID:"MASS_MENUITEM_ACT_INACT_FUNC_ID",
    STATUS_DROPDOWN_OPTIONS: [
        {
            'id': '0',
            'itemName': 'All'
        },
        {
            'id': '1',
            'itemName': 'Active'
        },
        {
            'id': '2',
            'itemName': 'Inactive'
        }
    ],
    STATUS_MENU_DROPDOWN_OPTIONS: [
        {
            'id': '',
            'itemName': 'All'
        },
        {
            'id': '1',
            'itemName': 'Active'
        },
        {
            'id': '0',
            'itemName': 'Inactive'
        }],

    PACKAGE_REQUEST_TYPES: [
        {
            'id': 1,
            'itemName': 'Create Schedule'
        },
        {
            'id': 2,
            'itemName': 'Generate Now'
        },
        {
            'id': 3,
            'itemName': 'Adhoc Schedule'
        },
        {
            'id': 5,
            'itemName': 'Partial Export'
        }
    ],
    /*AUDIT_LOG_FUNCTION_OPTIONS : If we add more element in this drop down, then we have to add 
    that function Id in this file : /rfm2Services/src/main/java/com/mcd/rfm2/report/dao/ReportUtilityDAO.java 
    searchReportAll()*/
    AUDIT_LOG_FUNCTION_OPTIONS: [
        {
            'id': '0',
            'itemName': 'All'
        },
        {
            'id': '6001',
            'itemName': 'Audit Log Report'
        },
        {
            'id': '7320',
            'itemName': 'Restaurant DB Parameter Customization Report'
        },
        {
            'id': '7321',
            'itemName': 'Restaurant PTA Report'
        },
        {
            'id': '7240',
            'itemName': 'GMA Validation Report'
        },
        {
            'id': '7210',
            'itemName': 'Validation Report'
        }


    ],
    PACKAGE_REQUEST_INSTRUCTION: [
        {
            'id': 1,
            'itemName': 'Regular'
        },
        {
            'id': 2,
            'itemName': 'New/Replaced images only'
        },
        {
            'id': 3,
            'itemName': 'All images'
        },
        {
            'id': 4,
            'itemName': 'No images'
        }
    ],
    PACKAGE_EXPORT_INSTRUCTION: {
        packageContentTypeDropToggleObject: {
            'id': 1
        },
        packageContentTypeDropDownObject: [
            {
                'id': 2,
                'itemName': 'Messages and File Output'
            },
            {
                'id': 3,
                'itemName': 'Messages Only'
            }
        ]
    },
    MASS_MENU_UPDATE_METHOD: [
        {
            'id': '1',
            'itemName': 'Online'
        },
        {
            'id': '2',
            'itemName': 'Excel'
        }
    ],
    MI_UPDATE_TYPE: [
        {
            'id': '1',
            'itemName': 'General Settings - Description'
        },
    ],
    CHECKED_UNCHECKED_ENTRIES: [
        // {
        //     'id': null,
        //     'itemName': 'View All'
        // },
        {
            'id': true,
            'itemName': 'Checked'
        },
        {
            'id': false,
            'itemName': 'Unchecked'
        }
    ],


    //PTA price screen starts
    STATUS_PRICE_DROPDOWN_SETTINGS: {
        'singleSelection': false,
        'enableCheckAll': true,
        'idField': 'item_id',
        'textField': 'item_text',
        'itemsShowLimit': 0,
    'classes': 'myclass custom-class',
    'unSelectAllText': 'Unselect All',
    },

    STATUS_TAX_DROPDOWN_SETTINGS: {
        'singleSelection': true,
        'enableCheckAll': false,
        'idField': 'entryCode',
        'textField': 'translatedValue',
        'itemsShowLimit': 0,
        'classes': 'myclass custom-class'
    },

    STATUS_PRICE_DROPDOWN_OPTIONS: [
        {
            'item_id': 1,
            'item_text': 'Show Delivery Price'
        },
        {
            'item_id': 2,
            'item_text': 'Show RFM & Reference Price'
        },

        {
            'item_id': 3,
            'item_text': 'Show Restaurants'
        }
    ],
    //PTA price screen ends

    STATUS_MENU_DROPDOWN_STATUS: [
        {
            'type': 1,
            'value': 'Active'
        },
        {
            'type': 2,
            'value': 'Inactive'
        },
        {
            'type': 3,
            'value': 'VARIES'
        }
    ],
    // effective date paramater format for validation request
    VALIDATION_REPORT_API_DT_FORMAT: "MM/DD/YYYY",
    POS_LAYOUT_CONFIRMATION_EMAIL_SUB: "POSLayoutConfirmation_",
    POS_LAYOUT_CONFIRMATION_FILE_NAME_TIME_STAMP: "MMDDYYYY",
    POS_LAYOUT_View_EMAIL_Name: "POSLayoutScreenshots_",
    POS_LAYOUT_SELECTION_PAGESIZE: 100,
    DESKTOP: "Desktop",
    TABLET: "Tablet",
    MOBILE: "Mobile",
    CLICK: 'click',
    HOVER: 'hover',
    MINWIDTH: 768,
    MAXWIDTH: 1024,
    MOBILECHARLIMIT: 25,
    DESKTOPCHARLIMIT: 50,
    MMID_DESKTOPCHARLIMIT: 600,
    MMID_MOBILECHARLIMIT: 300,
    pageType: "Modal",
    pageUrl: "",
    clickName: "",
    clickTrackSection: "Content",
    ctaLinkType: "CTA",
    linkType: "Link",
    iconLinkType: "Icon",
    dropdownLinkType: "Dropdown",
    topNav: "TopNav",
    button: "Button",
    TRACKINGKEYS : ["pageName", "pageType", "pageUrl", "clickName", "clickTrackSection", "linkType", "addInfo", "platform"],

    /*Analytics Constant*/
    effDateEvent: "Effective Date",
    restaurantEvent: "Restaurant",
    menuItemEvent: "Menu Items",
    priceEvent: "Price",
    taxEvent: "Tax",
    cancelEvent: "Cancel",
    finishEvent: "Finish",
    generateEvent: "Generate",
    generateNewEvent: "Generate New Package",
    alertEvent: "Alert",
    alertCloseEvent: "Alert Close",
    viewEvent: "View",
    activateEvent: "Activate:",
    inactivateEvent: "Inactivate",
    openEvent: "Open:",
    nextEvent: "Next:",
    validationReportsComboEvent: "Validation Reports",
    otherReportsComboEvent: "Other Reports",
    messageEvent: "Open",
    messageHeaderEvent: "Messages",
    reportOpenEvent: "Open",
    applyEvent: "Apply:",
    inactivation : "Inactivation",
    NO_CHAR_AUTO_SUGGESTION: 'NO_CHAR_AUTO_SUGGESTION',
    PAGINATION_RECORDS_PER_PAGE: 100,
    PAGINATION_FIRST_PAGE: 1,
    InactivateEventYesConfirmation: "inactivateEventYesConfirmation",
    MMIDPageName: 'Mass Menu Item Delete',
    MMIDSearchClick: 'Search',
    trackRFMHyperLink: 'Hyperlink',
    trackRFMHyperLinkClick: 'Legacy RFM',
    ENABLE_WALKME: 'ENABLE_WALKME',
    MMIAIPageName: "Mass Menu Item Activate/Inactivate: Search",
    MAXIMUM_RESTAURANT_HIERARCHY_LEVEL_1_LIMIT_FOR_RESTAURANT_PTA_REPORT: 'MAXIMUM_RESTAURANT_HIERARCHY_LEVEL_1_LIMIT_FOR_RESTAURANT_PTA_REPORT',
    MAXIMUM_MENU_ITEMS_LIMIT_FOR_RESTAURANT_PTA_REPORT: 'MAXIMUM_MENU_ITEMS_LIMIT_FOR_RESTAURANT_PTA_REPORT'
};

