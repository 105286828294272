
import {merge as observableMerge,  Observable } from 'rxjs';

import {distinct} from 'rxjs/operators';
import { Injectable } from '@angular/core';
//import { arch } from 'os';

@Injectable()
export class GenerateAuditBusinessService {

  menuSelectedItems = []; // modules tree selection items.
  modulesTree = [];
  dropdownData = [];
  selectedRestaurantItems = [];
  selectedModules = [];
  userTableData = [];
  miList = [];
  formDirty = false;
  layeringLogicType: any;
  constructor() { }

  logData() {
    console.log('selectedRestaurantItems', this.selectedRestaurantItems);
  }

  createRestaurantListForRequest() {
    const tempArray = [];
    this.selectedRestaurantItems.forEach(element => {
      const tempItem = {
        name: '',
        nodeid: '',
        mktId: ''
      };
      tempItem.name = element.name;
      tempItem.nodeid = element.child;
      tempItem.mktId = element.mktID;
      tempArray.push(tempItem);
    });
    return tempArray;
  }

  createModulesListForRequest(modulesDefaultValue) {
    const tempArray = [];
    const modulesArray = [];
    this.pushModulesTreeNode(this.modulesTree, tempArray);
    const tempString = tempArray.toString();
    if (tempArray.length > 0 && tempString) {
    modulesArray.push(tempString);
    return modulesArray;
    } else {
      return modulesDefaultValue;
    }
  }

  pushModulesTreeNode(array, reqArray) {
    array.forEach(element => {
      if (element.selected && element.visible) {
        reqArray.push(element.child);
      }
      if (element.children && element.children.length > 0) {
        this.pushModulesTreeNode(element.children, reqArray);
      }
    });
  }

  createUsersArrays() {
    const names = [];
    const ids = [];
    this.userTableData.forEach(element => {
      if (element.selected) {
        names.push(element.userName);
        ids.push(element.userEid);
      }
    });
    return {
      names: names,
      ids: ids
    };
  }

  public getClassDropdownData(data) {
    if (data && data.length) {
      const tempArray = [];
      // const length = data.length;
      data.forEach(item => {
        const tempObject = {
          id : '',
          itemName : ''
        };
        tempObject.id = item.entryCode;
        tempObject.itemName = item.translatedValue;
        tempArray.push(tempObject);
      });
      return tempArray;
    }
  }

 /*  public getFilterClassDropdownData(data) {
    if (data && data.length) {
      const tempArray = [];
      
      const tempObject = {
        id : '',
        itemName : 'All'
      };
      tempArray.push(tempObject);
      data.forEach(item => {
        const tempObject = {
          id : '',
          itemName : ''
        };
        tempObject.id = item.entryCode;
        tempObject.itemName = item.translatedValue;
        tempArray.push(tempObject);
      });
      return tempArray;
    }
  } */

  public getMenuItemClass(data) {
    if (data && data.length) {
      const tempArray = [];
      const length = data.length;
      for (let i = 0; i < length; i++) {
        tempArray.push(data[i].id);
      }
      return tempArray;
    }
  }

  public getFltrSts(data, arg, func){
    
   // let filteredData = [];
    const tempArray = [];
    
   // filteredData = Array.from(new Set(data.map((itemInArray) => itemInArray[arg])));
    
    if (data && data.length) {
      
      if(func == "menu"){

      data.forEach(item => {
        const tempObject = {
          id : item[arg],
          itemName : this.getFormatDataForMenu(item[arg])
        };

        tempArray.push(tempObject);
      });
    } else{
      const tempObject = {
        id : '0',
        itemName : 'ALL'
      };
      tempArray.push(tempObject);
      data.forEach(item => {
        const tempObject = {
          id : '',
          itemName : ''
        };
        tempObject.id = this.getFormatDataForUser(item[arg]);
        tempObject.itemName = item[arg];
        tempArray.push(tempObject);
      });
      }
    }
    return tempArray;
  }

  getFormatDataForMenu(val){

    if (this.layeringLogicType == '1') {
      if (val == '0') {
          return 'Not Approved';
      }
      if (val == '1') {
          return 'Approved';
      }
    } else {
      if (val == '0') {
          return 'Inactive';
      }
      if (val == '1') {
          return 'Active';
      }
    }
  }

  getFormatDataForUser(val){
    if (val == 'INACTIVE') {
        return '2';
    } 
    if (val == 'ACTIVE') {
         return '1';
      }
   }
      
  public getUniqueData(data, arg){
  let filteredData = [];
  
  observableMerge(data).pipe(
    distinct((x) => x[arg]))
    .subscribe(y => {
      filteredData.push(y);
    });
    return filteredData;
  }

  public getFltrClassDropdownData(data) {
    if (data && data.length) {
      const tempArray = [];
        data.forEach(item => {
        const tempObject = {
          id : '',
          itemName : ''
        };
        tempObject.id = item.prodClassId;
        tempObject.itemName = item.prodClass;
        tempArray.push(tempObject);
      });
      return tempArray;
    }
  }

}

