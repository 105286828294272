import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OwnerOperatorSetpageTitleService {
/* To set page tiltle */
  constructor(private titleService:Title) { }

  public setpageTitle(pageTiltle){
    pageTiltle = pageTiltle.toUpperCase();
    this.titleService.setTitle(pageTiltle);
  }

//   To get the page heading
    private headerText = new BehaviorSubject<string>('');
    public getHeaderText() {
        return this.headerText.asObservable();
    }
    public setHeaderText(value: string) {
        this.headerText.next(value);
    }
}
