export const environment = {
  production: false,
  envName: 'dev',
  authUserUrl: 'http://localhost:9080/rfmRefreshApp/api/login',
  masterMenuListUrl: 'http://localhost:9080/rfmRefreshApp/api/listMiGroup',
  restaurantListUrl: 'http://localhost:9080/rfmRefreshApp/api/listRest',
  priceDataUrl: 'http://localhost:9080/rfmRefreshApp/api/getPriceData',
  treeDataUrl: 'http://localhost:9080/rfmRefreshApp/api/getTreeGenerator',
  generateAuditLog: 'http://localhost:9080/rfmRefreshApp/api/generateAuditLog',
  auditUsers: 'http://localhost:9080/rfmRefreshApp/api/listSearchUser',
  auditDropdownValues: 'http://localhost:9080/rfmRefreshApp/api/getDropDownValues',
  sessionLiveImageUrl: 'http://localhost:9080/rfm2OnlineApp/images/approve.jpg?',
  packageScheduleUrl: 'http://localhost:9080/rfmRefreshApp/api/performSearch',
  marketRegionCoopList: 'assets/mock-json/market-region-coop.json',
  massMenuItemDeleteDataUrl: 'http://localhost:9080/rfmRefreshApp/api/getMenuItem',
  deletePkgScheduleUrl: 'http://localhost:9080/rfmRefreshApp/api/deletePkgSchedule',
  //RFM-25232, loader timout in milliseconds
  ng4LoaderSpinnerTimeout: 15 * 60 * 1000,
  //RFMP-21083 product redirection
  productURL: "https://us-restaurant-apps-dev.mcd.com/product",
  categoryCloudFrontURL: "https://us-restaurant-apps-qa.mcd.com/rfm-category/",
  dataValidationEnv:'qa',
  dataValidationAppId:'77t4bpi63nd0c9b3q3g2ii4lfm',
  dataValidationAppSecret:'6o4lt8i6fgld139avv84s9dd9q6m5otfcn07jcccs4o8e2ll4v1',
  dataValidationCognitoURL:'https://mcd-qa-restaurant-api.auth.us-east-1.amazoncognito.com',
  dataValidationLoginURL:'https://mcd-qa-restaurant-api.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=/rmsv2/us-qa/gassamlprovider',
  dataValidationCloudFrontURL:'https://d3catpil8a2o0o.cloudfront.net'
};
