import { Inject, Injectable } from '@angular/core';
import { HttpCoreService } from '../../services/http-core.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { LocalStorageService } from 'angular-2-local-storage';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { connection } from '../../../environments/connection.properties';
import { OwnerOperatorHeaderService } from '../services/owner-operator-header.service';
import { OOP_URL_CONST } from '../../shared/constants/rfmOOPUrl.constant';
import { OOP_CONST } from '../../shared/constants/rfmOOP.constant';
import { number } from 'prop-types';

@Injectable({
  providedIn: 'root'
})
export class OwnerOperatorHomepageService {
  userBean: any =
    {
      "userEid": "",
      "marketId": "",
      "localeLangID": "",
      "userToken": "",
      "isRest": "",
      "selectedLanguage": ""
    }
  permissionMap: any = [];
  permissionData: any = [];
  response: any = {};
  urlToCallServerData: any;
  tileData: any = [];
  tileMessageMap: any;
  tileDataAddingMesgMap: any = [];
  tileDatafromMessageMap: any = [];
  headerMessageCount;
  counts:any;
  messageCount = new BehaviorSubject<number>(0);
  messageCountTrack = this.messageCount.asObservable();
  public oopConfigData!:any[];
  constructor(
    private httpCoreService: HttpCoreService,
    private localStorageService: LocalStorageService,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    private http: HttpClient,
    public OwnerOperatorHeaderService: OwnerOperatorHeaderService
    ) {
      if (connection.RELATIVE_PATH === 'true' || connection.RELATIVE_PATH == 'true') {
        this.urlToCallServerData = location.protocol + OOP_CONST.SYMBOL_SLASH + location.hostname + OOP_CONST.SYMBOL_COLON +
          connection.RELATIVE_API_PORT + connection.RELATIVE_IMAGE_URL;
      } else {
        this.urlToCallServerData = connection.RFM_IMAGE_TILE_URL;
      }
  
      let marketId = this.sessionStorage.get(OOP_CONST.MARKET_ID);
      let tileDataJson = this.urlToCallServerData + marketId + '/OOPConfigData.json';
  
      this.http.get<any>(tileDataJson).subscribe((result) => {
        this.oopConfigData = result.ConfigData;
      }, (err) => {
        if (err.status == 404) {
          tileDataJson = this.urlToCallServerData + 'OOPConfigData.json';
          this.http.get<any>(tileDataJson).subscribe((result) => {
            this.oopConfigData = result.ConfigData;
          });
        }
      });
    }

  public getNotificationCount(apiService, data): Observable<any> {
    this.userBean.marketId = this.sessionStorage.get(OOP_CONST.MARKET_ID);
    this.userBean.userEid = this.sessionStorage.get(OOP_CONST.USER_EID);
    this.userBean.localeLangID = this.sessionStorage.get(OOP_CONST.LOCALE_LANG_ID);
    this.userBean.userToken = this.sessionStorage.get(OOP_CONST.USER_TOKEN);
    this.userBean.isRest = this.sessionStorage.get(OOP_CONST.IS_REST);
    this.userBean.selectedLanguage = this.sessionStorage.get(OOP_CONST.SELECTED_LANGUAGE);

    this.permissionData = data;
    if ((this.permissionData == OOP_CONST.PERMISSION_OWNER_OPERATOR_MESSAGES) && (this.permissionData.length == 1)) {
      this.permissionMap = this.permissionData;
    }
    else {
      for (let i = 0; i < this.permissionData.length; i++) {
        this.permissionMap.push(this.permissionData[i][OOP_CONST.PERMISSION]);
      }
    }
    this.response.permissionMap = this.permissionMap;
    this.response.userBean = this.userBean;

    return this.httpCoreService.getHttpUrl(apiService, this.response, OOP_CONST.POST);
  }
  
  public getHomepageData(getValidData){
    if (connection.RELATIVE_PATH === 'true' || connection.RELATIVE_PATH == 'true') {
      this.urlToCallServerData = location.protocol + OOP_CONST.SYMBOL_SLASH + location.hostname + OOP_CONST.SYMBOL_COLON +
        connection.RELATIVE_API_PORT + connection.RELATIVE_IMAGE_URL;
    } else {
      this.urlToCallServerData = connection.RFM_IMAGE_TILE_URL;
    }

    let markerid = this.sessionStorage.get(OOP_CONST.MARKET_ID);
    let tileDataJson = this.urlToCallServerData + markerid + '/OOPConfigData.json';

    this.http.get(tileDataJson).subscribe((result) => {
      getValidData(this.viewTileOnHomepage(result));
    }, (err) => {
      if (err.status == 404) {
        tileDataJson = this.urlToCallServerData + 'OOPConfigData.json';
        this.http.get(tileDataJson).subscribe((result) => {
          getValidData(this.viewTileOnHomepage(result));
        }, (err) => {
          getValidData(err)
        })
        
      }
    });
  }

  private viewTileOnHomepage(jsonData) {
    this.tileData = this.tileChangeLanguage(jsonData);
    this.tileDataAddingMesgMap = this.OwnerOperatorHeaderService.validatePermission(this.tileData);
    return this.tileDataAddingMesgMap;
  }

  public tileChangeLanguage(tileDataLang) {
    this.tileDatafromMessageMap=tileDataLang[OOP_CONST.CONFIGDATA];
    this.tileMessageMap = this.sessionStorage.get(OOP_CONST.MESSAGEMAP);
    
    for (var i = 0; i < this.tileDatafromMessageMap.length; i++) {
      let tile_ID = this.tileDatafromMessageMap[i][OOP_CONST.TILE_ID];
      let accessbtn = this.tileMessageMap[tile_ID + OOP_CONST.ACCESS_BUTTON_TEXT];
      this.tileDatafromMessageMap[i].tileTitle = this.tileMessageMap[tile_ID + OOP_CONST.TILE_TITLE];
      this.tileDatafromMessageMap[i].alertText = this.tileMessageMap[tile_ID + OOP_CONST.ALERT_TEXT];
      this.tileDatafromMessageMap[i].accessButtonText = this.tileMessageMap[tile_ID + OOP_CONST.ACCESS_BUTTON_TEXT];
    }
    return this.tileDatafromMessageMap;
  }

   setMessageHeaderCount(count) {   
    this.counts = count;
    this.messageCount.next(count);
    this.sessionStorage.set('messageCount',count);
  }

   getMessageHeaderCount() : Observable<any>{
    return this.counts;
  }
  public getSystemMessageHeader() {
    return {
      "marketId": this.sessionStorage.get(OOP_CONST.MARKET_ID),
      "userId": this.sessionStorage.get(OOP_CONST.USER_ID),
      "userEid": this.sessionStorage.get(OOP_CONST.USER_EID)
    }
  }

  public getSystemMessages(apiService) : Observable<any>{
    return this.httpCoreService.getHttpUrl(apiService, this.getSystemMessageHeader(), OOP_CONST.POST);
  }
}
