export const MSG_CONST = {

/*URLs*/
    MSG_ADMIN_URL: '',  
    MSG_USERS_URL: 'messages',
    MSG_CREATE_URL: 'create-message',
    MSG_VIEW_URL: 'view-message',
    MSG_MANAGEMENT_URL: 'message-management',
    SELF_URL: '_self',
    MSG_MGMT_FULL_URL: '/message-management',
    MSG_VIEW_FULL_URL: '/message-management/messages',
    MSG_CREATE_FULL_URL: '/message-management/create-message',
    MSG_EDIT_FULL_URL: '/message-management/view-message',

/*API Constants*/
    SELECTED_DATE_FORMAT: 'selectedDateFormat',
    AUDITLOG_DATE_FORMAT: 'auditLogDateFormat',
    LOAD_MSG_LOOKUP: 'loadMessageLookup',
    GET_CURRENT_DATE_FROM_USER: 'getCurrentDateFromUser',
    GET_MSG_FOR_ADMIN: 'getMessageForAdmin',
    GET_EXPIRED_MESSAGE_FOR_ADMIN: 'getExpiredMessageForAdmin',
    GET_REST_LIST_FOR_MESSAGE_AT_ADMIN: 'getRestListForMessageAtAdmin',
    GET_MSG_FOR_USER: 'getMessageForUser',
    GET_REST_LIST_FOR_MESSAGE_AT_USER: 'getRestListForMessageAtUser',
    UPDATE_READ_STATUS: 'updateReadStatus',
    DELETE_CUSTOM_MSG: 'deleteCustomMessage',

//  OTHERS
    MSG_TYPE_LIST: 'messageTypeList',
    MSG_STATUS_LIST: 'messageStatusList',
    TRANSLATEDVALUE: 'translatedValue',
    SELECTED_ROW_LIST: 'selectedRowList',
    ENTRY_ORDER: 'entryOrder',
    CALL_FROM_HEADER: 'calFromHeader',
    IS_EDIT: 'isEdit',
    SELCTED_MESSAGE_INDEX: 'selectedMessageIndex',
    CANCEL_MODAL_BTN: 'cancelModalBtn',
    ERROR_MODAL_BTN: 'errorModalBtn',
    SYSTEM: 'System',
    ENTRY_CODE: 'entryCode',
    CUSTOM: 'Custom',
    UPDATE_CUSTOM_MSG: 'updateCustomMessage',
    CREATE_CUSTOM_MSG: 'createCustomMessage',
    OLD_REST_LIST:'oldRestList',
    CLOSE_EVENT:'closeEvent',

//  CONSOLE MESSAGES
    UPDATE_SUCCESS: 'updated successfully',
    UPDATE_FAILED: 'update failed',
    
//  GENERAL CONSTANTS
    CLEAR: 'clear',
    ARROWS: 'arrows',
    VISIBLE: 'visible',
    API_FORMAT: 'YYYY-MM-DD',
    CURRENT_DATE_FORMAT: 'MM/DD/YYYY',
    EXPIRE_LOWERCASE: 'expire',
    PENDING_LOWERCASE: 'pending',
    DELIVERED_LOWERCASE: 'delivered',
    REST_LIST: 'restList',
    EXPIRED: 'Expired',
    PENDING: 'Pending',
    DELIVERED: 'Delivered',
    ASC: 'asc',
    DESC: 'desc',
    EMPTY: '',
    SPACE: ' ',
    ZERO: '0',
    ONE: '1',
    TWO: '2',
    THREE: '3',
    FOUR: '4',
    R: 'R',
    HYPHEN: ' - ',
    COMMA: ', ',
    ONE_NUM: 1,
    TWO_NUM: 2,
    THREE_NUM: 3,
    FOUR_NUM: 4,
    READ_STATUS: 'readStatus',
    NEG_ONE_NUM: -1,
    DECREASE: 'decrease',
    INCREASE: 'increase',
    RESULT: 'result',
    POST: 'post',
    EDIT: 'edit',
    SELECT_ONE: 'Select One',
    MONTH: 'M',
    DAY: 'D',
    YEAR: 'YYYY',
    HOVER: 'hover',
    SKIP_TO_MESSAGE: 'Skip to Message',
    RETURN_TO_INBOX: 'Return to Inbox',

//  NGPRIME-TABLE 
    REST_NUMBER: 'restNu',
    REST_NAME: 'restName',
    START_DATE: 'strtDt',
    END_DATE: 'endDt',
    SUBJECT: 'subject',
    MSG_BODY: 'messageBody',
    RECIPIENT_NODES: 'recipientNodes',
    STATUS: 'status',
    MSG_TYPE: 'messageType',
    NO_RESULTS: 'noResults',
    MSG_TYPE_SH: 'msgType',
    TYPE: 'type',

//  SESSION_STORAGE
    USER_EID: 'userEid',
    USER_ID: 'userId',
    MARKET_ID: 'marketId',
    LOCALE_LANG_ID: 'localeLangID',
    MASTER_MSG: 'masterMessages',
    MSG_MAP: 'messageMap',
    PERMISSION_MAP: 'permissionMap',
    TIMEZONE: 'timeZone',
    IS_REST: 'isRest',
    SELECTED_MSG_INDEX: 'selectedMessageIndex',
    REFRESH_MESSAGE_MANAGEMENT_CUSTOMIZABLE_MESSAGES: 'REFRESH_MESSAGE_MANAGEMENT_CUSTOMIZABLE_MESSAGES',
    REFRESH_MESSAGE_MANAGEMENT_SYSTEM_MESSAGE: 'REFRESH_MESSAGE_MANAGEMENT_SYSTEM_MESSAGE',
    REFRESH_MESSAGE_MANAGEMENT_CREATE: 'REFRESH_MESSAGE_MANAGEMENT_CREATE',
    REFRESH_MESSAGE_MANAGEMENT_EDIT: 'REFRESH_MESSAGE_MANAGEMENT_EDIT',
    REFRESH_MESSAGE_MANAGEMENT_DELETE: 'REFRESH_MESSAGE_MANAGEMENT_DELETE',
    REFRESH_MESSAGE_MANAGEMENT: 'REFRESH_MESSAGE_MANAGEMENT',
    REFRESH_OWNER_OPERATOR_HOMEPAGE_MESSAGES: 'REFRESH_OWNER_OPERATOR_HOMEPAGE_MESSAGES',

    // restaurant Tree
    Market: 'Market',
    Canada: 'Canada',
    Store: 'Store',
    Restaurant: 'Restaurant',
    Region: 'Region',

    //adobe analytics constant
    Messages: "Messages",
    MSG_MGMT: "Message Management",
    ClickName: "Create Message",
    MSG_MGMT_EDIT_MESSAGE: "Edit Message",
    Save: "Save",
    Cancel: "Cancel",
    MessageManagementCreate: "Message Management - Create",
    MessageManagementEdit: "Message Management - Edit",
    MessageViewpageName: "Messages: Message Management: View Message",
    analyticsData: {
        MSGEditMessege: { 
            pageName: "Messages: Message Management: Edit Message",
            pageType: 'Modal',
            pageUrl:'',
            clickName: 'Cancel',            
            events: 'event19',
            clickTrackSection: 'Content',
            linkType: 'CTA',
        }, 
        MSG_MGMTClearClick: { 
            pageName: "Messages: Message Management",
            pageType: 'Modal',
            pageUrl:'',
            clickName: '',            
            events: 'event19',
            clickTrackSection: 'Content',
            linkType: 'Button',
        },
        trackMesageCreateAndEdit: { 
            pageName: "Messages: Message Management: Create Message",
            pageType: 'Modal',
            pageUrl:'',
            clickName: '',            
            events: 'event19',
            clickTrackSection: 'Content',
            linkType: 'CTA',
        },
        messageConfirmationPageView: { 
            pageName: "Messages: Message Management: Cancellation Confirmation",
            pageType: 'Modal',
            pageUrl:''
        },
        trackMsgStatusDropDownList: { 
            pageName: "Messages: Message Management",
            pageType: 'Modal',
            pageUrl:'',
            clickName: '',            
            events: 'event19',
            clickTrackSection: 'Content',
            linkType: 'Dropdown',
            addInfo:'Status'
        },
        trackMsgFilters: { 
            pageName: "Messages: Message Management",
            pageType: 'Modal',
            pageUrl:'',
            clickName: '',            
            events: 'event19',
            clickTrackSection: 'Content',
            linkType: 'Filter',
            addInfo:'Filter Interaction'
        },
        trackMsgRecipientNode: { 
            pageName: "Messages: Message Management",
            pageType: 'Modal',
            pageUrl:'',
            clickName: '',            
            events: 'event19',
            clickTrackSection: 'Content',
            linkType: 'Hyperlink'
        },
        trackMessageType: {           
            pageName: "Messages: Message Management: Create Message",
            pageType: 'Modal',
            pageUrl:'',
            clickName: '',            
            events: 'event19',
            clickTrackSection: 'Content',
            linkType: 'Dropdown',
            addInfo:'Message Type'
        },
        trackMSGPageView:{
            pageName: "Messages: Message Management: View Message",
            pageType: 'Modal',
            pageUrl:''  
        }
    }
}