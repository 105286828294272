
import {merge as observableMerge,  Observable } from 'rxjs';

import {distinct} from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { HttpCoreService } from '../../../services/http-core.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MassPromotionUpdateModel } from '../model/masspromotionupdate.model';
import { filter, map, pick, partialRight } from 'lodash';

import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';

@Injectable()
export class MassPromotionUpdateHttpService {

  constructor(@Inject(SESSION_STORAGE) public sessionStorage: StorageService,
    private httpClient: HttpClient,
    private localStorageService: LocalStorageService,
    public massPromotionUpdateModel: MassPromotionUpdateModel,
    private httpCoreService: HttpCoreService,
    private spinnerService: NgxSpinnerService
  ) { }

  public getMenuItemList(apiService, data): Observable<any> {
    return this.httpCoreService.getHttpUrl(apiService, data, 'post');
  }

  public getLoadLookup(apiService, data): Observable<any> {
    return this.httpCoreService.getHttpUrl(apiService, data, 'post');
  }

  public loadPromoLookup(apiService, data): Observable<any> {
    return this.httpCoreService.getHttpUrl(apiService, data, 'post');
  }
  
  public loadTemplateList(apiService, data): Observable<any> {
    return this.httpCoreService.getHttpUrl(apiService, data, 'post');
  }

  public loadPromotionList(apiService, data): Observable<any> {
      
      const userDetails = this.getValidationDetail();

      let selectedRestData = data.online.restSelection.selectedRestaurants;
      const selectedRestList = [];
      if (selectedRestData && selectedRestData.length) {
        selectedRestData.forEach(item => {

          const tempObject = {
            restNodeId : 0,
            restName : '',
            restNumber : 0
          };

          tempObject.restNodeId = item.node_id;
          tempObject.restName = item.rest_na;
          tempObject.restNumber = item.rest_nu;

          selectedRestList.push(tempObject);
        });
      }

      selectedRestList.sort((a, b) => {
				if(+a.restNumber > +b.restNumber) {
					return 1;
				}
				if(+a.restNumber < +b.restNumber) {
					return -1;
				}
				return 0;
			});
      
      const reqData  = {
        'searchValue': data.online.promoSelection.promoCode,
        'promoTemplate': data.online.promoSelection.promoTemplate,
        'promoStatus': data.online.promoSelection.promoStatus,
        'searchOption':  data.online.promoSelection.filterType,
        'restaurantList': selectedRestList,
        'userBean': {
          'userEid': userDetails.eid,
          'marketId': userDetails.mkt,
          'localeLangId': userDetails.localeLangId
        }
      };
      
    return this.httpCoreService.getHttpUrl(apiService, reqData, 'post');
  }

  public massPromoUpdate(apiService, data): Observable<any> {
    return this.httpCoreService.getHttpUrl(apiService, this.updatePromotionRequest(data), 'post');
  }

  updatePromotionRequest(tableData) {
    const userDetails = this.getValidationDetail();
    const reqData  = {
      'promotionVOList': tableData.table,
      'promotionVOListOld': tableData.duplicatePromoUpdateList,
      'userBean': {
        'userEid': userDetails.eid,
        'marketId': userDetails.mkt
      }
    };

    return reqData;
  }

  public getStatusDropdownDataRemoveAll(data) {
    if (data && data.length) {
      const tempArray = [];
      data.forEach(item => {
        if(item.translatedValue != "All"){
            tempArray.push(item);
        }
      });
      return tempArray;
    }
  }

  public getStatusDropdownData(data) {
    if (data && data.length) {
      const tempArray = [];
      // const length = data.length;
      data.forEach(item => {
        const tempObject = {
          id : '',
          itemName : ''
        };
        tempObject.id = item.entryCode;
        tempObject.itemName = item.translatedValue;
        tempArray.push(tempObject);
      });
      return tempArray;
    }
  }

  public getSelectedTemplateData(data) {
    let stringTemplates = '';
    if (data && data.length) {
      data.forEach((item, key) => {
        if(Object.is(data.length-1, key)){
          stringTemplates += "'"+item.id+"'";
        } else {
          stringTemplates += "'"+item.id+"',";
        }
      });
    }
    return stringTemplates;
  }

  public getUniqueData(data, arg){
    let filteredData = [];
    observableMerge(data).pipe(
      distinct((x) => x[arg]))
      .subscribe(y => {
        filteredData.push(y);
      });
      return filteredData;
  }

  public getFltrSts(data, arg){
     const tempArray = [];
     if (data && data.length) {
        const tempObject = {
          id : '',
          itemName : ''
        };
        //tempArray.push(tempObject);
        data.forEach(item => {
          const tempObject = {
            id : '',
            itemName : ''
          };
          tempObject.id = item[arg];
          tempObject.itemName = this.getFormatDataForPromo(item[arg]) ;
          tempArray.push(tempObject);
        });
     }
     return tempArray;
  }

  getFormatDataForPromo(val){
      if (val == '0') {
          return 'Inactive';
      }
      if (val == '1') {
          return 'Active';
      }
  }
  
  public getTemplateDropdownData(data, messageMap) {
    if (data && data.length) {
      const tempArray = [];
      // const length = data.length;
      data.forEach(item => {
        const tempObject = {
          id : '',
          itemName : '',
          itemNameCode : '',
        };
        tempObject.id = item.templateId;
        tempObject.itemNameCode = item.templateName;
        tempObject.itemName = messageMap[item.templateName];
        tempArray.push(tempObject);
      });
      return tempArray;
    }
  }

  getUpdateAndReviewReqData () {
    const userDetails = this.getValidationDetail();
    const req  = {
      'userBean': {
        'userEid': userDetails.eid,
        'marketId': userDetails.mkt
      },
      'promotionVOList': this.promoListForOnlineUpdate()
    };
    return req;
  }

  promoListForOnlineUpdate() {
    const filteredData =  filter(this.massPromotionUpdateModel.online.promoSelection.promoList, { 'selected': true as any});
    const reqData =  filteredData.map(obj => {
      return pick(obj, ['promoId', 'promoInstId']);
   });
   return reqData;
  }

  getValidationDetail() {
    let eid = '';
    let marketId = '';
    let userId = '';
    let localeLangId = '';
    if (this.localStorageService.get('userEid')) {
      eid = this.localStorageService.get('userEid');
    } else{
    	eid = this.sessionStorage.get('userEid');
    }
    if (this.localStorageService.get('marketId')) {
      marketId = this.localStorageService.get('marketId');
    } else {
    	marketId = this.sessionStorage.get('marketId');
    }
    if (this.sessionStorage.get('userId')) {
    	userId = this.sessionStorage.get('userId');
    } else {
    	userId = this.localStorageService.get('userDetails')['id'];
    }
    if (this.sessionStorage.get('localeLangID')) {
    	localeLangId = this.sessionStorage.get('localeLangID');
    } else {
    	localeLangId = this.localStorageService.get('userDetails')['localeLangID'];
    }
    return {
      eid: eid, mkt: marketId,
      userId: userId,
      localeLangId: localeLangId
    };
  }

  errorHandler(error: any): void {
    console.log(error);
    this.spinnerService.hide();
  }
	
	getRestInfoListForMPU(apiService, data): Observable<any> {
		return this.httpCoreService.getHttpUrl(apiService, data, 'post');
	}
}
