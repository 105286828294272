import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'restListFilter'
})
export class MassRestSelectionPipe implements PipeTransform {

  transform(items: any, filter: any, coopFilter?: any, regionFieldOfficeFilter?: any, num?: any,): any {
    var flag = false;

    if (items && items.length) {
      return items.filter(item => {

        if(coopFilter.length > 0){
          for(var i = 0; i <= coopFilter.length - 1; i++){
            if(coopFilter && item.coop.toString().indexOf(coopFilter[i].itemName.toString()) === 0){
              flag = true; break;
            } else {
              flag = false;
            }
          }
          return flag;
        }

        if(regionFieldOfficeFilter.length > 0){
          for(var i = 0; i <= regionFieldOfficeFilter.length - 1; i++){
            if(regionFieldOfficeFilter 
               && item.regionFieldOffice.toString().indexOf(regionFieldOfficeFilter[i].itemName.toString()) === 0){
                flag = true; break;
            } else {
              flag = false;
            }
          }
          return flag;
        }
        
        if (filter.rest_nu && item.rest_nu.toString().indexOf(filter.rest_nu) === -1 ||
        (filter.rest_nu && !item.rest_nu)) {
          return false;
        }
        if (filter.rest_na && item.rest_na && item.rest_na.toLowerCase().indexOf(filter.rest_na.toLowerCase()) === -1 ||
        (filter.rest_na && !item.rest_na)) {
          return false;
        }
        return true;
      });
    } else {
      return items;
    }
  }
}
