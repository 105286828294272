import { Injectable } from '@angular/core';
import { Observable } from 'rxjs'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { connection } from '../../environments/connection.properties';
import { map, take } from 'rxjs/operators';


export class RFMApplicationSetting {
  public name: string
  public value: string
}

@Injectable({
  providedIn: 'root'
})
export class RfmApplicationSettingService {

  private API_PATH: string = "getGlobalParam"

  constructor(private httpClient: HttpClient, ) { }


  public get(marketId: number, functionId: number, name: string): Observable<RFMApplicationSetting> {
    let requestBody = {
      marketId: marketId,
      functionId: functionId,
      parameterName: name
    }

    let requestPath = `${connection.API_FULL_URL}${this.API_PATH}`;

    if (connection.RELATIVE_PATH == 'true') {
      requestPath = `${location.protocol}//${location.hostname}:${connection.RELATIVE_API_PORT}${connection.RELATIVE_API_PATH}${this.API_PATH}`
    }

    return this.httpClient.post(requestPath, JSON.stringify(requestBody), { headers: new HttpHeaders({ "Content-Type": "application/json" }), responseType: "text" })
      .pipe(map(res => res)).pipe(map(value => {
        let v = new RFMApplicationSetting();
        v.name = name
        v.value = value
        return v
      })).pipe(take(1));

  }
}
