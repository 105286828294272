import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { PackageScheduleService } from '../services/package-schedule.service';
import { SelectedLangService } from '../../../services/selected-lang.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { TreePageComponent } from './app-page-tree.component';
import { MassMenuItemUpdateHttpService } from '../../mass-menu-item-update/services/mass-menu-item-update-http.service';
import { MassMenuItemUpdateModel } from '../../../../app/components/mass-menu-item-update/model/mass-menu-item-update-model';
import { GenerateAuditHttpService } from '../../audit-log/generate-audit-log/services/generate-audit-http.service';
import { SharedModule } from '../../../layout/shared-modules/shared.module';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    CommonModule, AngularMultiSelectModule, FormsModule, SharedModule,MatAutocompleteModule,MatInputModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [TreePageComponent, ], 
  exports: [TreePageComponent,], 
  providers: [PackageScheduleService, SelectedLangService, MassMenuItemUpdateHttpService,MassMenuItemUpdateModel,GenerateAuditHttpService],
})
export class TreePageModule { }
