import { Injectable } from '@angular/core';
import { Component, OnInit, Inject } from '@angular/core';
import * as moment from 'moment';
import 'moment-timezone';
import { LocalStorageService } from 'angular-2-local-storage';
import { Router } from '@angular/router';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';

import { HeaderComponent } from '../../components/header/header.component';
import { AuthUserService } from '../../services/auth-user.service';
import { connection } from '../../../environments/connection.properties';
import { HeaderService } from '../../services/header.service';
import { OOP_CONST } from '../constants/rfmOOP.constant';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class HelpServiceService {

  timeZone = '';
  startDate: any;
  requestTypeObject: any[];
  requestType: any = [];
  userDetails: any;
  permissions: any;
  messageMap: any;
  marketId: any;
  packageScheduleWindowPeriodValue: number;
  packageWindowdate: any;
  generatedDate: Date;
  selectedQ: number = 0;

  functionId = '';
  localeLangID: any;
  helpFileId = 0;
    helpGuideErrorMsg: string;

  constructor(
    public localStorageService: LocalStorageService,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    private router: Router, public authUserService: AuthUserService,
    private HeaderComponent: HeaderComponent, private headerService: HeaderService,
  ) { }

  GetHelpUrl() {
    this.helpGuideErrorMsg = this.sessionStorage.get(OOP_CONST.MESSAGEMAP).helpGuideErrorMsg;
    if (this.sessionStorage.get('isSessionStorage') == "true" || this.sessionStorage.get('isSessionStorage') == true) {

      this.functionId = this.HeaderComponent.getFunctionId(this.router.url);
      this.localeLangID = this.sessionStorage.get('localeLangID');
      this.marketId = this.sessionStorage.get('marketId');
      let reqData = "getOnlineHelp?functionId=" + this.functionId + "&helpFileId=" + this.helpFileId + "&userEid=" + this.sessionStorage.get("userEid") + "&marketId=" + this.marketId + "&localeLangID=" + this.localeLangID;
      let onlineResp: any;
   
      this.authUserService.getOnlineHelpData(reqData + "&check=true", "").subscribe((data) => {
        onlineResp = data;
        if (onlineResp != null && onlineResp.success == false) {
          this.sessionStorage.set(this.functionId, true);
          this.sessionStorage.set("onlineHelpMsg", this.helpGuideErrorMsg);
          this.headerService.isChangeMadeFunction(this.helpGuideErrorMsg)
        }
        else {
          let url = '';

          if (connection.RELATIVE_PATH == "true" || connection.RELATIVE_PATH == 'true') {
            url = location.protocol + "//" + location.hostname + ":" +
              connection.RELATIVE_API_PORT + connection.RELATIVE_API_PATH;
          } else {
            url = connection.API_FULL_URL;
          }

          var params = {
            'Authorization': `${this.localStorageService.get('accessToken')}`
          };

          const getOnlineHelpUrl = url + reqData + '&check=false';
          var urlOpen = [getOnlineHelpUrl, $.param(params)].join('&');
          window.open(urlOpen, '_blank');

        }
      });
    } else {

      this.functionId = this.HeaderComponent.getFunctionId(this.router.url);
      this.localeLangID = this.localStorageService.get('localeLangID');
      this.marketId = this.localStorageService.get('marketId');
      let reqData = "getOnlineHelp?functionId=" + this.functionId + "&helpFileId=" + this.helpFileId + "&userEid=" + this.sessionStorage.get("userEid") + "&marketId=" + this.marketId + "&localeLangID=" + this.localeLangID;
      let onlineResp: any;

      this.authUserService.getOnlineHelpData(reqData + "&check=true", "").subscribe((data) => {
        onlineResp = data;
        if (onlineResp != null && onlineResp.success == false) {
          this.localStorageService.set(this.functionId, true);
          this.localStorageService.set("onlineHelpMsg", this.helpGuideErrorMsg);
        }
        else {
          let url = '';

          if (connection.RELATIVE_PATH == "true" || connection.RELATIVE_PATH == 'true') {
            url = location.protocol + "//" + location.hostname + ":" +
              connection.RELATIVE_API_PORT + connection.RELATIVE_API_PATH;
          } else {
            url = connection.API_FULL_URL;
          }
          var params = {
            'Authorization': `${this.localStorageService.get('accessToken')}`
          };

          const getOnlineHelpUrl = url + reqData + '&check=false';
          var urlOpen = [getOnlineHelpUrl, $.param(params)].join('&');
          window.open(urlOpen, '_blank');

        }
      });
    }

  }
}
