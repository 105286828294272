import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NoopAnimationsModule, BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageModule } from 'angular-2-local-storage';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { OrderModule } from 'ngx-order-pipe';
import { NgxPaginationModule } from './utilities/ngx-pagination';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { AppComponent } from './app.component';
import { DataTransferComponent } from './components/data-transfer/data-transfer.component';
import { AuthGuard } from './services/auth-guard.service';
import { CookieService } from 'ngx-cookie-service';
import { HttpCoreService } from './services/http-core.service';
import { HeaderComponent } from './components/header/header.component';
import { RestaurantFilterPipe } from './utilities/filter/restaurant-filter.pipe';
import { MenuLazyLoadFilterPipe } from './utilities/filter/menu-lazy-load-filter.pipe';
import { MenuLazyLoadFilterReportPipe } from './utilities/filter/menu-lazy-load-filter-report.pipe';
import { TreeFilterPipe } from './utilities/filter/tree-filter.pipe';

import { DropdownService } from './services/dropdown.service';
import { TreeHelperService } from './services/tree-helper.service';
import { DataService } from './models/dataservice';
import { CommonService } from './services/common.service';
import { AuthUserService } from './services/auth-user.service';
import { SelectiveStrategy } from './services/selective-strategy.service';
import { SessionLiveServiceService } from './services/session-live-service.service';

import { StorageServiceModule } from 'ngx-webstorage-service';
import { BatchSchedulingService } from './components/package/batch-scheduling/services/batch-scheduling-service';
import { HeaderService } from './services/header.service';
import { SelectedLangService } from './services/selected-lang.service';
import { SharedModule } from './layout/shared-modules/shared.module';
import { DialogModule } from 'primeng/dialog';
import { PackageScheduleService } from './components/package/services/package-schedule.service';
import { FooterModule } from './components/footer/footer.module';
import { PackageModule } from './components/package/package.module';
import { DataTransferModule } from './components/data-transfer/data-transfer.module';
import { MassPromotionUpdateModule } from './components/masspromotionupdate/masspromotionupdate.module';
import { ArrayLimitPipe } from './pipes/arrayLimit';
import { OwnerOperatorAuthGuard } from './shared/guard/owner-operator-auth.guard';
import { LayoutModule } from './layout/layout.module';
import { OwnerOperatorHeaderModule } from './layout/shared-modules/owner-operator-header/owner-operator-header.module';
import { OwnerOperatorMenuModule } from './layout/shared-modules/owner-operator-menu/owner-operator-menu.module';
import { PermissionExpressionEvaluatorService } from './services/permission-expression-evaluator.service';
import { MiFilterSharedPipe } from './shared/pipes/menu-item-filter.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { Interceptor } from './services/interceptor';
import { LayoutModule as CDKLayoutModule } from '@angular/cdk/layout';

// routes
export const ROUTES: Routes = [
    {
        path: '',
        canActivate: [AuthGuard,
        OwnerOperatorAuthGuard
        ],
        data: { preload: true },
        loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule)
    },
    {
        path: 'access-denied',
        data: { preload: true },
        loadChildren: () => import('./access-denied/access-denied.module').then(m => m.AccessDeniedModule)
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: 'generate-audit',
        canActivate: [AuthGuard],
        data: { preload: true },
        loadChildren: () => import('./components/audit-log/generate-audit-log/generate-audit-log.module').then(m => m.GenerateAuditLogModule)
    },

    {
        path: 'massmenuitemdelete',
        //canActivate: [AuthGuard],
        //data: { preload: true },
        loadChildren: () => import('./components/massmenuitemdelete/massmenuitemdelete.module').then(m => m.MassMenuItemDeleteModule)
    },
    {
        path: 'massmenuitemdeletereport',
        canActivate: [AuthGuard],
        data: { preload: true },
        loadChildren: () => import('./components/massmenuitemdeletereport/massmenuitemdeletereport.module').then(m => m.MassMenuItemDeleteReportModule)
    },
    {
        path: 'massmenuitemdeletereportresults',
        canActivate: [AuthGuard],
        data: { preload: true },
        loadChildren: () => import('./components/massmenuitemdeletereportresults/massmenuitemdeletereportresults.module').then(m => m.MassMenuItemDeleteReportResultsModule)
    },
    {
        path: 'restaurantdbparameter',
        canActivate: [AuthGuard],
        data: { preload: true },
        loadChildren: () => import('./components/restaurant-db-parameter/restaurant-db-parameter.module').then(m => m.RestaurantModule)
    },
    {
        path: 'generatePTA',
        //canActivate: [AuthGuard],
        //data: { preload: true },
        loadChildren: () => import('./components/generate-pta/generate-pta.module').then(m => m.GeneratePTAModule)
    },
    {
        path: 'mass-menu-item-update',
        canActivate: [AuthGuard],
        data: { preload: true },
        loadChildren: () => import('./components/mass-menu-item-update/mass-menu-item-update.module').then(m => m.MassMenuItemUpdateModule)
    },
    {
        path: 'masspromotionupdate',
        canActivate: [AuthGuard],
        data: { preload: true },
        loadChildren: () => import('./components/masspromotionupdate/masspromotionupdate.module').then(m => m.MassPromotionUpdateModule)
    },
    {
        path: 'package',
        canActivate: [AuthGuard],
        data: { preload: true },
        loadChildren: () => import('./components/package/package.module').then(m => m.PackageModule)
    },
    {
        path: 'reports',
        canActivate: [AuthGuard],
        data: { preload: true },
        loadChildren: () => import('./components/reports/reports.module').then(m => m.ReportsModule)
    },
    {
        path: 'batchScheduling',
        canActivate: [AuthGuard],
        data: { preload: true },
        loadChildren: () => import('./components/package/batch-scheduling/batch-scheduling.module').then(m => m.BatchSchedulingModule)
    },
    {
        path: 'batch-scheduling-confirmation',
        canActivate: [AuthGuard],
        data: { preload: true },
        loadChildren: () => import('./components/package/batch-scheduling/batch-scheduling-confirmation/batch-scheduling-confirmation.module').then(m => m.BatchSchedulingConfirmationModule)
    },
    {
        path: 'data-transfer', component: DataTransferComponent
    },
];

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        RestaurantFilterPipe,
        MenuLazyLoadFilterPipe,
        TreeFilterPipe,
        MenuLazyLoadFilterReportPipe,
        ArrayLimitPipe,
        MiFilterSharedPipe
    ],    
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        HttpClientModule, 
        SharedModule,
        BrowserAnimationsModule,
        NgxSpinnerModule,
        NoopAnimationsModule,
        Ng2SearchPipeModule,
        DialogModule,
        DataTransferModule, 
        FormsModule,
        OrderModule, 
        NgxPaginationModule,
        RouterModule.forRoot(ROUTES, {
            scrollPositionRestoration: 'top'
        }), 
        NgbModule, 
        TranslateModule.forRoot(),
        AngularMultiSelectModule,
        StorageServiceModule,
        NgMultiSelectDropDownModule.forRoot(),
        LocalStorageModule.forRoot({
            prefix: 'my-app',
            storageType: 'localStorage',
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        FooterModule,
        PackageModule,
        MassPromotionUpdateModule,
        OwnerOperatorHeaderModule,
        OwnerOperatorMenuModule,
        LayoutModule,
        InfiniteScrollModule,
        CDKLayoutModule
        ],
    providers: [{
            provide: HTTP_INTERCEPTORS,
            useClass: Interceptor,
            multi: true 
        }, 
        HttpCoreService,
        PackageScheduleService,
        DropdownService,
        AuthUserService,
        AuthGuard,
        CookieService,
        SelectiveStrategy,
        TreeHelperService,
        SessionLiveServiceService,
        DataService,
        CommonService,
        BatchSchedulingService,
        HeaderComponent,
        SelectedLangService,
        HeaderService,
        PermissionExpressionEvaluatorService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
