
export class MiRequestDetailBean {

filterType: string;
filterText: string;
orderBy: string;
sortOrder: string;
status: string;
productClass: any[] = [];
approvalStatus: string;
familyGrpId: any[];
miGrpSelection: string;
miSelection: any[];

/*for new service*/
marketId:number;
userEid:string;
prd_cd:string;
prd_ds:string;
nextRows:number;
fetchRows:number;
status1:any[];
prodClassFilter:any[];
exactMatch:boolean;
effectiveDate:string;
nodesIds:any[];
/*for new service*/
}




