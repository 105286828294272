import { ChangeDetectorRef, Directive, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { PaginationService } from './pagination.service';
import { PaginationInstance } from './pagination-instance';

export interface Page {
    label: string;
    value: any;
}

/**
 * This directive is what powers all pagination controls components, including the default one.
 * It exposes an API which is hooked up to the PaginationService to keep the PaginatePipe in sync
 * with the pagination controls.
 */
@Directive({
    selector: 'pagination-template,[pagination-template]',
    exportAs: 'paginationApi'
})
export class PaginationControlsDirective {
    @Input() id: string;
    // changed code for customization in rfm-refresh
    @Input() maxSize: number = 20000;
    @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
    public pages: Page[] = [];

    /**		
    * changed code for customization in rfm-refresh		
    */
    public firstItemNumber = 1;
    /**		
    * changed code for customization in rfm-refresh		
    */
    public lastItemNumber = 20;

    private changeSub: Subscription;

    constructor(private service: PaginationService,
        private changeDetectorRef: ChangeDetectorRef) {
        this.changeSub = this.service.change
            .subscribe(id => {
                if (this.id === id) {
                    this.updatePageLinks();
                    this.changeDetectorRef.markForCheck();
                    this.changeDetectorRef.detectChanges();
                    this.firstItemNumber = this.getFirstItemNumber();
                    this.lastItemNumber = this.getLastItemNumber();
                }
            });
    }

    ngOnInit() {
        if (this.id === undefined) {
            this.id = this.service.defaultId();
        }
        this.updatePageLinks();
        this.firstItemNumber = this.getFirstItemNumber();
        this.lastItemNumber = this.getLastItemNumber();
    }

    ngOnChanges(changes: any) {
        this.updatePageLinks();
        this.firstItemNumber = this.getFirstItemNumber();
        this.lastItemNumber = this.getLastItemNumber();
    }

    ngOnDestroy() {
        this.changeSub.unsubscribe();
    }

    /**
     * Go to the previous page
     * changed code for customization in rfm-refresh
     */
    previous() {
        this.checkValidId();
        let pagecurrentno = "pageNo" + this.id;
        let page = (<HTMLInputElement>document.getElementById(pagecurrentno)).value;
        let flag = this.isInt(page);
        if (!flag) {
            return false;
        }
        this.setCurrent(this.getCurrent() - 1);
        this.firstItemNumber = this.getFirstItemNumber();
        this.lastItemNumber = this.getLastItemNumber();
    }

    /**
     * Go to the next page
     * changed code for customization in rfm-refresh
     */
    next() {
        this.checkValidId();
        let pagecurrentno = "pageNo" + this.id;
        let page = (<HTMLInputElement>document.getElementById(pagecurrentno)).value;
        let flag = this.isInt(page);
        if (!flag) {
            return false;
        }
        this.setCurrent(this.getCurrent() + 1);
        this.firstItemNumber = this.getFirstItemNumber();
        this.lastItemNumber = this.getLastItemNumber();
    }

    /**
     * Returns true if current page is first page
     */
    isFirstPage(): boolean {
        return this.getCurrent() === 1;
    }

    /**
     * changed code for customization in rfm-refresh
     */
    enterpressalert(event) {
        if (event.keyCode == '13') {
            this.setCurrent();
        }
    };

    /**
     * Returns true if current page is last page
     */
    isLastPage(): boolean {
        return this.getLastPage() === this.getCurrent();
    }

    /**
     * changed code for customization in rfm-refresh
     * Set the current page number.
     */
    setCurrent(page: number = null) {
        let pagecurrentno = "pageNo" + this.id;
        let pageCurrentLength = "pagelen" + this.id;
        let showError = false;
        if ((page == null) && (+(<HTMLInputElement>document.getElementById(pagecurrentno)).value <= +(<HTMLInputElement>document.getElementById(pageCurrentLength)).value) && ((+(<HTMLInputElement>document.getElementById(pagecurrentno)).value) != 0)) {
            page = +(<HTMLInputElement>document.getElementById(pagecurrentno)).value;
            let flag = this.isInt(page);
            if (!flag) {
                return false;
            }
            this.firstItemNumber = this.getFirstItemNumber();
            this.lastItemNumber = this.getLastItemNumber();
        }
        else if (page == null || page == 0) {
            //  setCurrent(1)
        }
        if (page) {
            (<HTMLInputElement>document.getElementById(pagecurrentno)).value = page + "";
            this.pageChange.emit(page);
        }
    }

    /**		
    * changed code for customization in rfm-refresh
    * Set the first page number.		
    */
    setFirstPage(page) {
        let pagecurrentno = "pageNo" + this.id;
        let pageCurrentLength = "pagelen" + this.id;
        let inst = this.service.getInstance(this.id);
        (<HTMLInputElement>document.getElementById(pagecurrentno)).value = page;
        this.pageChange.emit(page);
        this.firstItemNumber = this.getFirstItemNumber();
        this.lastItemNumber = this.getLastItemNumber();

    };
    /**	
    * changed code for customization in rfm-refresh	
    * Set the last page number.		
    */
    setLastPage(lastPage) {
        let pagecurrentno = "pageNo" + this.id;
        let pageCurrentLength = "pagelen" + this.id;
        let inst = this.service.getInstance(this.id);
        (<HTMLInputElement>document.getElementById(pagecurrentno)).value = lastPage;
        this.pageChange.emit(lastPage);
        this.firstItemNumber = this.getFirstItemNumber();
        this.lastItemNumber = this.getLastItemNumber();
    };

    /**
     * Get the current page number.
     */
    getCurrent(): number {
        return this.service.getCurrentPage(this.id);
    }

    /**
     * Returns the last page number
     */
    getLastPage(): number {
        let inst = this.service.getInstance(this.id);
        if (inst.totalItems < 1) {
            // when there are 0 or fewer (an error case) items, there are no "pages" as such,
            // but it makes sense to consider a single, empty page as the last page.
            return 1;
        }
        return Math.ceil(inst.totalItems / inst.itemsPerPage);
    }

    /**		
    * changed code for customization in rfm-refresh
    */
    getUpdateValue() {
        let inst = this.service.getInstance(this.id);
        let selectid = "pagevalue" + this.id;
        let selectedvalue = parseInt((<HTMLInputElement>document.getElementById(selectid)).value);
        if (inst.totalItems < 1) {
            // when there are 0 or fewer (an error case) items, there are no "pages" as such,
            // but it makes sense to consider a single, empty page as the last page.
            return 1;
        }
        inst.itemsPerPage = selectedvalue;
        this.firstItemNumber = this.getFirstItemNumber();
        this.lastItemNumber = this.getLastItemNumber();
        return Math.ceil(inst.totalItems / selectedvalue);
    };
    /**		
    * changed code for customization in rfm-refresh	
    * Returns the last page number		
    */
    getLastItemNumber() {
        let inst = this.service.getInstance(this.id);
        let pageFirstNumber = "pageNo" + this.id;
        let selectfirstid = "pagevalue" + this.id;
        let page = parseInt((<HTMLInputElement>document.getElementById(pageFirstNumber)).value);
        inst.itemsPerPage = parseInt((<HTMLInputElement>document.getElementById(selectfirstid)).value);
        let lastItemNumInPage = inst.itemsPerPage * page;
        if (!inst.totalItems || inst.totalItems < 1) {
            return 0;
        } else if (lastItemNumInPage < inst.totalItems && page > 0 && page <= this.getLastPage()) {
            return lastItemNumInPage;
        } else return inst.totalItems;
    };
    /**		
    * changed code for customization in rfm-refresh		
    */
    getFirstItemNumber() {
        let inst = this.service.getInstance(this.id);
        let pageFirstNumber = "pageNo" + this.id;
        let selectfirstid = "pagevalue" + this.id;
        let page = parseInt((<HTMLInputElement>document.getElementById(pageFirstNumber)).value);
        inst.itemsPerPage = parseInt((<HTMLInputElement>document.getElementById(selectfirstid)).value);
        if (inst.totalItems >= 1 && page > 0 && page <= this.getLastPage())
            return inst.currentPage == 1 && page == 1 ? 1 : inst.itemsPerPage * (page - 1) + 1;
        else return 0;
    };

    /**		
     * changed code for customization in rfm-refresh		
     */
    validPageInPagination(): boolean {
        let pageFirstNumber = "pageNo" + this.id;
        if (document.getElementById(pageFirstNumber)) {
            let page = +(<HTMLInputElement>document.getElementById(pageFirstNumber)).value;
            let pageChk = (<HTMLInputElement>document.getElementById(pageFirstNumber)).value;
            if (page < 1 || page > this.getLastPage() || isNaN(page) || pageChk && pageChk.toString().indexOf('.') != -1) {
                return true;
            }
        }
        return false;
    }

    /**		
     * changed code for customization in rfm-refresh		
     * Returns the last page number		
     */
    getTotalItems(): number {
        let inst = this.service.getInstance(this.id);
        if (inst.totalItems < 1) {
            // when there are 0 or fewer (an error case) items, there are no "pages" as such,
            // but it makes sense to consider a single, empty page as the last page.
            return 1;
        }
        return inst.totalItems;
    }

    private checkValidId() {
        if (!this.service.getInstance(this.id).id) {
            console.warn(`PaginationControlsDirective: the specified id "${this.id}" does not match any registered PaginationInstance`);
        }
    }

    /**
     * Updates the page links and checks that the current page is valid. Should run whenever the
     * PaginationService.change stream emits a value matching the current ID, or when any of the
     * input values changes.
     */
    private updatePageLinks() {
        let inst = this.service.getInstance(this.id);
        const correctedCurrentPage = this.outOfBoundCorrection(inst);

        if (correctedCurrentPage !== inst.currentPage) {
            setTimeout(() => {
                this.setCurrent(correctedCurrentPage);
                this.pages = this.createPageArray(inst.currentPage, inst.itemsPerPage, inst.totalItems, this.maxSize);
            });
        } else {
            this.pages = this.createPageArray(inst.currentPage, inst.itemsPerPage, inst.totalItems, this.maxSize);
        }
    }

    /**
     * Checks that the instance.currentPage property is within bounds for the current page range.
     * If not, return a correct value for currentPage, or the current value if OK.
     */
    private outOfBoundCorrection(instance: PaginationInstance): number {
        const totalPages = Math.ceil(instance.totalItems / instance.itemsPerPage);
        if (totalPages < instance.currentPage && 0 < totalPages) {
            return totalPages;
        } else if (instance.currentPage < 1) {
            return 1;
        }

        return instance.currentPage;
    }

    /**
     * Returns an array of Page objects to use in the pagination controls.
     */
    private createPageArray(currentPage: number, itemsPerPage: number, totalItems: number, paginationRange: number): Page[] {
        // paginationRange could be a string if passed from attribute, so cast to number.
        paginationRange = +paginationRange;
        let pages = [];
        const totalPages = Math.ceil(totalItems / itemsPerPage);
        const halfWay = Math.ceil(paginationRange / 2);

        const isStart = currentPage <= halfWay;
        const isEnd = totalPages - halfWay < currentPage;
        const isMiddle = !isStart && !isEnd;

        let ellipsesNeeded = paginationRange < totalPages;
        let i = 1;

        while (i <= totalPages && i <= paginationRange) {
            let label;
            let pageNumber = this.calculatePageNumber(i, currentPage, paginationRange, totalPages);
            let openingEllipsesNeeded = (i === 2 && (isMiddle || isEnd));
            let closingEllipsesNeeded = (i === paginationRange - 1 && (isMiddle || isStart));
            if (ellipsesNeeded && (openingEllipsesNeeded || closingEllipsesNeeded)) {
                label = '...';
            } else {
                label = pageNumber;
            }
            pages.push({
                label: label,
                value: pageNumber
            });
            i++;
        }
        return pages;
    }

    /**
     * Given the position in the sequence of pagination links [i],
     * figure out what page number corresponds to that position.
     */
    private calculatePageNumber(i: number, currentPage: number, paginationRange: number, totalPages: number) {
        let halfWay = Math.ceil(paginationRange / 2);
        if (i === paginationRange) {
            return totalPages;
        } else if (i === 1) {
            return i;
        } else if (paginationRange < totalPages) {
            if (totalPages - halfWay < currentPage) {
                return totalPages - paginationRange + i;
            } else if (halfWay < currentPage) {
                return currentPage - halfWay + i;
            } else {
                return i;
            }
        } else {
            return i;
        }
    }

    /**
     * changed code for customization in rfm-refresh
     */
    isInt(value) {
        // value should be a number
        if (!value.toString().match(/^\d*$/)) {
            return false;
        }
        return true;
    }
}
