/**
 * The default template and styles for the pagination links are borrowed directly
 * from Zurb Foundation 6: http://foundation.zurb.com/sites/docs/pagination.html
 */


export const DEFAULT_TEMPLATE = `
<pagination-template #p="paginationApi" [id]="id" [maxSize]="maxSize" (pageChange)="pageChange.emit($event)">
    <ul class="ngx-pagination" role="navigation" [attr.aria-label]="screenReaderPaginationLabel" *ngIf="!(autoHide && p?.pages?.length <=1) && p">
        <li class="pagindrop">
            <select id="pagevalue{{id}}" (change)="p?.getUpdateValue()">
                <option>20</option>
                <option>40</option>
                <option>60</option>
            </select>
        </li> &emsp;&emsp;&emsp;&emsp; &emsp;
        <li class="pagination-previous1 circle" [class.disabled]="p?.isFirstPage()" *ngIf="directionLinks">
            <a *ngIf="1 < p?.getCurrent()" (click)="p?.setFirstPage(1)" [attr.aria-label]="previousLabel + ' ' +screenReaderPageLabel">
                <span class="fa fa-angle-double-left fa-lg"></span>
                <span class="show-for-sr">{{ screenReaderPageLabel }} </span>
            </a>
            <span class="fa fa-angle-double-left fa-lg" *ngIf="p?.isFirstPage()">
                {{ previousLabel }} <span class="show-for-sr">{{ screenReaderPageLabel }}</span>
            </span>
        </li>
        <li class="pagination-previous circle" [class.disabled]="p?.isFirstPage()" *ngIf="directionLinks">
            <a *ngIf="1 < p?.getCurrent()" (click)="p?.previous()" [attr.aria-label]=" previousLabel+ ' ' +screenReaderPageLabel">
                {{ previousLabel }} <span class="fa fa-angle-left fa-lg"></span>
                <span class="show-for-sr">{{ screenReaderPageLabel }}</span>
            </a>
            <span class="fa fa-angle-left fa-lg" *ngIf="p?.isFirstPage()">
                {{ previousLabel }} <span class="show-for-sr">{{ screenReaderPageLabel }}</span>
            </span>
        </li>
        <li class="paginpadd">
            <b>Page 
                <input (keyup)="p?.enterpressalert($event)" (blur)="p?.setCurrent(null)" id="pageNo{{id}}" 
                name="pageNo" [class.pginationerror]="showError" class="inptlft" value="1" />
                 of {{p?.pages?.length}}
            </b>
                <span class="pginationerror" *ngIf="p?.validPageInPagination()">Invalid input</span>
        </li>
        <li class="pagination-next circle" [class.disabled]="p?.isLastPage()" *ngIf="directionLinks">
            <a *ngIf="!p?.isLastPage()" (click)="p?.next()" [attr.aria-label]="nextLabel + ' ' +screenReaderPageLabel">
                {{ nextLabel }}<span class="fa fa-angle-right fa-lg"></span>
                <span class="show-for-sr">{{ screenReaderPageLabel }}</span>
            </a>
            <span class="fa fa-angle-right fa-lg" *ngIf="p?.isLastPage()">
                {{ nextLabel }} <span class="show-for-sr">{{ screenReaderPageLabel }}</span>
            </span>
        </li>
        <li class="pagination-next1 circle" [class.disabled]="p?.isLastPage()" *ngIf="directionLinks">
            <a *ngIf="!p?.isLastPage()" (click)="p?.setLastPage((p?.getLastPage())?(p?.getLastPage()): 0)" [attr.aria-label]="nextLabel + ' '+ screenReaderPageLabel">
                {{ nextLabel }} <span class="fa fa-angle-double-right fa-lg"></span>
                <span class="show-for-sr">{{ screenReaderPageLabel }}</span>
            </a>
            <span class="fa fa-angle-double-right fa-lg" *ngIf="p?.isLastPage()">
                {{ nextLabel }} <span class="show-for-sr">{{ screenReaderPageLabel }}</span>
            </span>
        </li>
        <li>&emsp;
            <b> Displaying &nbsp; {{p?.firstItemNumber}} &nbsp; to &nbsp;{{(p?.getTotalItems() > 20)?p?.lastItemNumber:p?.getTotalItems()}} of &nbsp;{{p?.getTotalItems()}} &nbsp;items
                <input type="hidden" id="pagelen{{id}}" name="pagelen" value="{{p?.pages?.length}} " />
            </b>
        </li>
    </ul>
</pagination-template>
`;

export const DEFAULT_STYLES = `
.ngx-pagination {
  margin-left: 0;
  margin-bottom: 1rem;
}

.ngx-pagination::before,
.ngx-pagination::after {
  content: ' ';
  display: table;
}

.ngx-pagination::after {
  clear: both;
}

.ngx-pagination li {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  margin-right: 0.0625rem;
  border-radius: 0;
}

.ngx-pagination li {
  display: inline-block;
}

.ngx-pagination select {
  outline: none;
  border-top: none;
  border-right: none;
  border-left: none;
}

.ngx-pagination input {
  border-top: none;
  border-right: none;
  border-left: none;
  width: 20%;
  border-bottom-color: grey;
  outline: none;
  border-bottom-width: 1px;
}

.ngx-pagination a,
.ngx-pagination button {
  color: #0a0a0a;
  display: block;
  padding: 0.1875rem 0.625rem;
  border-radius: 0;
}

.ngx-pagination a:hover,
.ngx-pagination button:hover {}

.ngx-pagination .current {
  padding: 0.1875rem 0.625rem;
  background: #2199e8;
  color: #fefefe;
  cursor: default;
}

.ngx-pagination .disabled {
  padding: 0.1875rem 0.625rem;
  color: #cacaca;
  cursor: default;
}

.ngx-pagination .disabled:hover {
  background: transparent;
}

span.fa.fa-angle-right.fa-lg {
  margin-left: 4px;
}

span.fa.fa-angle-double-right.fa-lg {
  margin-left: 4px;
}

span.fa.fa-angle-left.fa-lg {
  margin-right: 5px;
}

span.fa.fa-angle-double-left.fa-lg {
  margin-right: 5px;
}

.ngx-pagination a,
.ngx-pagination button {
  cursor: pointer;
}

.ngx-pagination .paginpadd {
  padding-left: 12px;
  position: relative;
}

li.paginpadd {
  width: 220px
}

.ngx-pagination .circle {
  display: inline-block;
  height: 35px;
  width: 35px;
  line-height: 29px;
  border-radius: 50%;
  border: 1px solid green;
  font-size: 13px;
  text-align: center;
}

input#pageNogroupsPaginate {
  direction: rtl;
}

.pginationerror {
  border-bottom-=red;
  color: red;
  position: absolute;
  bottom: -21px;
  left: 21px;
}

.ngx-pagination .circle.disabled {
  border: 1px solid;
}

.inptlft {}

.ngx-pagination .pagination-previous1 a::before,
.ngx-pagination .pagination-previous1.disabled::before {
  content: ' ';
  display: inline-block;
}

.ngx-pagination .pagination-previous a::before,
.ngx-pagination .pagination-previous.disabled::before {
  content: ' ';
  display: inline-block;
}

.ngx-pagination .pagination-next a::after,
.ngx-pagination .pagination-next.disabled::after {
  content: ' ';
  display: inline-block;
}

.ngx-pagination .pagination-next1 a::after,
.ngx-pagination .pagination-next1.disabled::after {
  content: ' ';
  display: inline-block;
}

.ngx-pagination .show-for-sr {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}
`;
