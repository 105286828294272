import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PTAData } from '../../../models/PTAData';
import { IPrice } from '../../../models/PriceVariables';
@Injectable({
  providedIn: 'root'
})
export class DropdownService {
  pta_data: PTAData[] = [];
  price_variables: IPrice;

  fromHeader = new BehaviorSubject(false);
  clickFromHeader = this.fromHeader.asObservable();
   
  constructor() {

  }

  cancelConfirmFromHeader(data){
    this.fromHeader.next(data);
  }
}
