import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutRoutingModule } from '../layout/layout.routing';
import { SharedModule } from './shared-modules/shared.module';
import { LayoutComponent } from './layout.component';
import { OwnerOperatorAuthGuard } from '../shared/guard/owner-operator-auth.guard';

@NgModule({
  declarations: [
      LayoutComponent,  
  ],
  imports: [
    CommonModule,
    SharedModule,
    LayoutRoutingModule    
  ],
  providers: [
    OwnerOperatorAuthGuard
  ],
 
})
export class LayoutModule { }
