import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout.component';
import { AuthGuard } from '../services/auth-guard.service';
import { OwnerOperatorAuthGuard } from '../shared/guard/owner-operator-auth.guard';
import { Permissions } from '../shared/guard/auth-gaurd-permissions';
import { RedirectGuard } from "../shared/guard/redirect-auth.guard";
import { environment } from '../../environments/environment';
const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: 'owner-operator-homepage', pathMatch: 'prefix' },
      {
        path: 'owner-operator-homepage',
        canActivate: [AuthGuard,
          OwnerOperatorAuthGuard
        ],
        data: {
          permissionExpression: Permissions.OOPHomePermission
        },
        loadChildren: () => import('./owner-operator-homepage/owner-operator-homepage.module').then(m => m.OwnerOperatorHomepageModule)
      },
      {
        path: 'packagegeneration',
        canActivate: [AuthGuard,
          OwnerOperatorAuthGuard
        ],
        data: {
          preload: true,
          permissionExpression: Permissions.PackageGenerationPermission
        },
        loadChildren: () => import('./package-generation/package-generation.module').then(m => m.PackageGenerationModule)
      },
      {
        path: 'generateReport',
        canActivate: [AuthGuard,
          OwnerOperatorAuthGuard
        ],
        data: {
          preload: true,
          permissionExpression: Permissions.GenerateReportPermission
        },
        loadChildren: () => import('./generate-report/generate-report.module').then(m => m.ReportModule)
      },
      {
        path: 'pta',
        canActivate: [AuthGuard,
          OwnerOperatorAuthGuard
        ],
        data: {
          preload: true,
          permissionExpression: Permissions.PTAPermission
        },
        loadChildren: () => import('./pta/pta.module').then(m => m.PTAModule)
      },
      {
        path: 'pos',
        canActivate: [AuthGuard,
          OwnerOperatorAuthGuard
        ],
        data: {
          preload: true,
          permissionExpression: Permissions.POSLayoutPermission
        },
        loadChildren: () => import('./PosLayoutDetails/pos-layout-module.module').then(m => m.PosLayoutModuleModule)

      },
      {
        path: 'menu-item-inactivation',
        canActivate: [AuthGuard,
          OwnerOperatorAuthGuard
        ],
        data: {
          preload: true,
          permissionExpression: Permissions.MIIPermission
        },
        loadChildren: () => import('./menu-item-inactivation/mii.module').then(m => m.MenuItemInactivationModule)
      },

      {
        path: 'message-management',
        canActivate: [AuthGuard
          //OwnerOperatorAuthGuard
        ],
        data: {
          preload: true,
          //permissionExpression: Permissions.m
        },
        loadChildren: () => import('./message-creation/message-creation-admin.module').then(m => m.MessageCreationAdminModule)
      },
      {
        path: 'action-item',
        canActivate: [AuthGuard,
          OwnerOperatorAuthGuard
        ],
        data: {
          preload: true,
          permissionExpression: Permissions.ActionItemAdminPermission
        },
        loadChildren: () => import('./action-item/action-item-admin.module').then(m => m.ActionItemModule)
      },
      {
        path: 'owneroperatorview',
        canActivate: [AuthGuard],
        loadChildren: () => import('./owner-operator-overview/owner-operator-overview.module').then(m => m.OwnerOperatorOverviewModule)
      },
      {
        path: 'mass-mi-delete',
        canActivate: [AuthGuard,
          OwnerOperatorAuthGuard
        ],
        data: {
          preload: false,
          permissionExpression: Permissions.MassMIDeletePermission
        },
        loadChildren: () => import('./mass-mi-delete/mass-mi-delete.module').then(m => m.MassMIDeleteModule)

      },
      {
        path: 'dimension-group',
        canActivate: [AuthGuard],
        data: {
          preload: true,
          permissionExpression: Permissions.DimensionGroupPermission
        },
        loadChildren: () => import('./dimension-group/dimension-group.module').then(m => m.DimensionGroupModule)
      },
      {
        path: 'mass-menu-item-activate-inactivate',
        canActivate: [AuthGuard],
        data: {
          preload: false,
        },
        loadChildren: () => import('./mass-menu-item-activate-inactivate/mmiai.module').then(m => m.MassMenuItemAIModule)
      },
      {
        path: "rfm-category",
        canActivate: [RedirectGuard],
        data: {
          externalUrl: environment.categoryCloudFrontURL,
        },
        component: RedirectGuard,
      },
      {
        path: 'data-validation',
        canActivate: [AuthGuard],
        data: {
          preload: false,
        },
        loadChildren: () => import('./data-validation/data-validation.module').then(m => m.DataValidationEngineModule)
      },
      {
        path: 'profile',
        canActivate: [RedirectGuard],
        data: {
          externalUrl: location.origin + "/profile/",
        },
        component: RedirectGuard
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AuthGuard, OwnerOperatorAuthGuard]
})
export class LayoutRoutingModule { }