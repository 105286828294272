export const POS_SLECTION_CONSTANT = {

    /* APIs */
    HTTP_POST_METHOD: 'POST',
    GET_POSLIST_API: 'getRestaurantPOSListByUser',
    GET_REST_POS_PAGINATEDLIST_BYUSER: 'getRestaurantPOSPaginatedListByUser',
    /* URLs */

     
    /* general values */
    FALSE: false,
    TRUE: true,
    NULL: null,
    UNDEFINED:  undefined,
    EMPTY_STRING: '',
    EMPTY_ARRAY: [],
    ARRAY_FIRST_INDEX: 0,
    ZERO: 0,
    ONE: 1,
    RECORD_NO_TO_APPLY_SCROLL: 8,
    
    /* session values */
    MARKET_DATE_FORMAT: 'selectedDateFormat',
    USER_DETAILS: 'userDetails',
    MARKET_NAME: 'marketName',
    TIMEZONE: 'timeZone',
    USER_EID: 'userEid',
    USER_ID: 'userId',
    MARKET_ID: 'marketId',
    MESSAGEMAP: 'messageMap',
    PERMISSIONMAP: 'permissionMap',
    US_COUNTRY_OFFICE: 'US Country Office',
    REST_NODE_LIST: 'restNodeList',
    FUTURE_POS: 'futurePOS',
    DETAIL: 'detail',
    IS_OPENED_POS_LAYOUT: 'isOpenedPOSLayout',
    
    /* property names */
    RESTAURANT_NUMBER: 'restaurantNumber',
    RESTAURANT_NAME: 'restName',
    FIELD_OFFICE: 'fieldOffice',
    Field__Office: 'Field Office',
    COOP: 'coop',
    REGION: 'region',
    CURRENT_DATE: 'CurrentDate',
    FUTURE_DATE: 'FutureDate',
    START_DATE: 'startDate',
    GLOBAL: 'GLOBAL',
    MARKET: 'market',
    BLM_CREATE_FUTURE: 'BLM_CREATE_FUTURE',

    INFO_MSG_MAX_LENGTH: 500,
    INFO_MSG_MAX_LENGTH_MINUS_ONE: 499,

    SELECTED_RESTAURANTS: 'selectedRestaurants',

    /*Analytics Constant*/
    pageName: "POS Layout",

    analyticsData: {
        //RFM-20794
        pageView: {
            pageName: "POS Layout",
            pageType: 'Modal'
        },
        //RFM-20803
        currentDateClick: {
            pageName: 'POS Layout',
            pageType: 'Modal',
            clickName: 'Current Date',
            clickTrackSection: 'Current Date',
            linkType: 'Link Text'
        },
        //RFM-20804
        futureDateClick: {
            pageName: 'POS Layout',
            pageType: 'Modal',
            clickName: 'Fture Date Value placeholder',
            clickTrackSection: 'Future Date',
            linkType: 'Link Text'
        },
        //RFM-20866
        confirmationPageView: {
            pageName: "POS Layout: Confirmation",
            pageType: 'Modal'
        },
        //RFM-20869
        confirmationPageDoneClick: {
            pageName: "POS Layout: Confirmation",
            pageType: 'Modal',
            clickName: 'Done',
            clickTrackSection: 'Content',
            linkType: 'CTA'
        },
        //RFM-20867
        confirmationPageDropdownAction: {
            pageName: "POS Layout: Confirmation",
            pageType: 'Modal',
            // only one value to be sent based on option selected
            clickName: '[Summary] or [POS Preview]',
            clickTrackSection: 'Content',
            linkType: 'Dropdown'
        }, 
        //RFM-20868, RFM-21118
        confirmationPageExportAction: {
            pageName: "POS Layout: Confirmation",
            pageType: 'Modal',
            // only one value to be sent based on option selected
            // POS: PDF, POS: Email, Summary: PDF, Summary: CSV, Summary: Email
            clickName: 'export type placeholder',
            clickTrackSection: 'Content',
            linkType: 'Dropdown'
        },
        //RFM-21084
        futurePOSError: {
            pageName: "POS Layout: Error",
            pageType: 'Modal'
        },
    }

};