(function (w) {
	/*
		data : {
			changed: false/true,
			transactionId: 123
		}
	*/
	w.OOPPOSCallback = function (data) {
		// clear posLayoutOpned flag 
		sessionStorage.removeItem('isOpenedPOSLayout')

		// if user made changes
		if (data.changed) {
			//open confirmation page in a new tab
			window.open('/pos/confirm?transactionId=' + data.transactionId, '_blank')
		}

		// current page is POS layout selection page, refresh
		if (window.location.pathname === '/pos/section') {
			window.location.reload();
		}
	}
})(window);

// after pollyfill for IE-9 above
(function (arr) {
	arr.forEach(function (item) {
		if (item.hasOwnProperty('after')) {
			return;
		}
		Object.defineProperty(item, 'after', {
			configurable: true,
			enumerable: true,
			writable: true,
			value: function after() {
				var argArr = Array.prototype.slice.call(arguments),
					docFrag = document.createDocumentFragment();

				argArr.forEach(function (argItem) {
					var isNode = argItem instanceof Node;
					docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
				});

				this.parentNode.insertBefore(docFrag, this.nextSibling);
			}
		});
	});
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);

(function (arr) {
	arr.forEach(function (item) {
		if (item.hasOwnProperty('remove')) {
			return;
		}
		Object.defineProperty(item, 'remove', {
			configurable: true,
			enumerable: true,
			writable: true,
			value: function remove() {
				this.parentNode.removeChild(this);
			}
		});
	});
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]); 