export const environment = {
  production: false,
  envName: 'json',
  authUserUrl: 'assets/mock-json/auth.json',
  masterMenuListUrl: 'assets/mock-json/master-menuItem.json',
  restaurantListUrl: 'assets/mock-json/restaurant_bulk.json',
  priceDataUrl: 'assets/mock-json/price_data.json',
  treeDataUrl: 'assets/mock-json/tree_response_short.json',
  packageScheduleUrl: 'assets/mock-json/package-schedule-view.json',
  marketRegionCoopList: 'assets/mock-json/market-region-coop.json',
  massMenuItemDeleteDataUrl:'http://localhost:9080/rfmRefreshApp/api/getMenuItem',
  generateAuditLog: 'http://localhost:9080/rfmRefreshApp/api/generateAuditLog',
  auditUsers: 'http://localhost:9080/rfmRefreshApp/api/listSearchUser',
  auditDropdownValues: 'http://localhost:9080/rfmRefreshApp/api/getDropDownValues',
  sessionLiveImageUrl: 'http://localhost:9080/rfm2OnlineApp/images/approve.jpg?',
  menuDataUrl: 'http://localhost:9080/rfmRefreshApp/api/getTreeGenerator',
  deletePkgScheduleUrl: 'http://localhost:9080/rfmRefreshApp/api/deletePkgSchedule'
};

