import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'updateListFilter'
})
export class MassPromoUpdatePipe implements PipeTransform {

  transform(items: any, filter: any, promoStatus: any, num?: any,): any {
    var flag = false;

    if (items && items.length) {
      return items.filter(item => {
        if(promoStatus.length > 0){
          for(var i = 0; i <= promoStatus.length - 1; i++){
            if(promoStatus && item.promoStatus.toString().indexOf(promoStatus[i].id.toString()) === 0){
              flag = true; break;
            } else {
              flag = false;
            }
          }
          return flag;
        }

        if (filter.restNumber && item.restNumber.toString().indexOf(filter.restNumber) === -1 ||
        (filter.restNumber && !item.restNumber)) {
          return false;
        }
        if (filter.nodeName && item.nodeName && item.nodeName.toLowerCase().indexOf(filter.nodeName.toLowerCase()) === -1 ||
        (filter.nodeName && !item.nodeName)) {
          return false;
        }
        if (filter.promoCode && item.promoCode.toString().indexOf(filter.promoCode) === -1 ||
        (filter.promoCode && !item.promoCode)) {
          return false;
        }
        if (filter.promoName && item.promoName && item.promoName.toLowerCase().indexOf(filter.promoName.toLowerCase()) === -1 ||
        (filter.promoName && !item.promoName)) {
          return false;
        }
        if (filter.promoLongDesc && item.promoLongDesc && item.promoLongDesc.toLowerCase().indexOf(filter.promoLongDesc.toLowerCase()) === -1 ||
        (filter.promoLongDesc && !item.promoLongDesc)) {
          return false;
        }
        if (filter.promoTemplateLabel && item.templateName && item.templateName.toLowerCase().indexOf(filter.promoTemplateLabel.toLowerCase()) === -1 ||
        (filter.promoTemplateLabel && !item.templateName)) {
          return false;
        }
        if (filter.strtDate && item.strtDate && item.strtDate.toLowerCase().indexOf(filter.strtDate.toLowerCase()) === -1 ||
        (filter.strtDate && !item.strtDate)) {
          return false;
        }
        if (filter.endDate && item.endDate && item.endDate.toLowerCase().indexOf(filter.endDate.toLowerCase()) === -1 ||
        (filter.endDate && !item.endDate)) {
          return false;
        }
        if (filter.promoStatus && item.promoStatus && item.promoStatus.toLowerCase().indexOf(filter.promoStatus.toLowerCase()) === -1 ||
        (filter.promoStatus && !item.promoStatus)) {
          return false;
        }
        return true;
      });
    } else {
      return items;
    }
  }
}
