import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { HttpCoreService } from '../../../services/http-core.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { MassMenuItemUpdateModel } from '../model/mass-menu-item-update-model';
import { filter, map, pick, partialRight } from 'lodash';

import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { formatDate } from '@angular/common';

@Injectable()
export class MassMenuItemUpdateHttpService {

  constructor(@Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    private httpClient: HttpClient,
    private localStorageService: LocalStorageService,
    public massMenuItemUpdateModel: MassMenuItemUpdateModel,
    private httpCoreService: HttpCoreService,
    private spinnerService: NgxSpinnerService
  ) { }

  public getMenuItemList(apiService, data): Observable<any> {
    return this.httpCoreService.getHttpUrl(apiService, data, 'post');
  }

  public getLoadLookup(apiService, data): Observable<any> {
    return this.httpCoreService.getHttpUrl(apiService, data, 'post');
  }
  
  public getMiUpdateType (apiService): Observable<any> {
    const reqData = this.getUpdateAndReviewReqData();
    return this.httpCoreService.getHttpUrl(apiService, reqData, 'post');
  }

  public getUpdateAndReviewData (apiService): Observable<any> {
    const reqData = this.getUpdateAndReviewReqData();
    return this.httpCoreService.getHttpUrl(apiService, reqData, 'post');
  }

  public updateMenuItems(apiService, data): Observable<any> {
    return this.httpCoreService.getHttpUrl(apiService, this.updateMenuItemsRequest(data), 'post');
  }

  updateMenuItemsRequest (tableData) {
    const userDetails = this.getValidationDetail();
    const selectedDateFormat = JSON.parse(sessionStorage.getItem('auditLogDateFormat'));
    const getDate = localStorage.getItem('onChangeDate');
  
    
    const reqData  = {
      'listMiLangDetail': tableData,
      'userBean': {
        'userEid': userDetails.eid,
        'marketId': userDetails.mkt
      },
      'allowNameValidation': false,
      'excel': false,
      'effectiveDate':getDate,
      'selectedDateFormat': selectedDateFormat
    };

    return reqData;
  }

  getUpdateAndReviewReqData () {
    const userDetails = this.getValidationDetail();
    const req  = {
      'userBean': {
        'userEid': userDetails.eid,
        'marketId': userDetails.mkt
      },
      'menuItemLangSearchVOList': this.menuItemsForOnlineUpdate()
    };
    return req;
  }

  menuItemsForOnlineUpdate () {
    const filteredData =  filter(this.massMenuItemUpdateModel.online.selection.miList, { 'selected': true as any});
    const reqData =  filteredData.map(obj => {
      return pick(obj, ['productId', 'productInstId']);
   });
   return reqData;
  }

  getValidationDetail() {
    let eid = '';
    let marketId = '';
    let userId = '';
    if (this.localStorageService.get('userEid')) {
      eid = this.localStorageService.get('userEid');
    } else{
    	eid = this.sessionStorage.get('userEid');
    }
    if (this.localStorageService.get('marketId')) {
      marketId = this.localStorageService.get('marketId');
    } else {
    	marketId = this.sessionStorage.get('marketId');
    }
    if (this.sessionStorage.get('userId')) {
    	userId = this.sessionStorage.get('userId');
    } else {
    	userId = this.localStorageService.get('userDetails')['id'];
    }
    return {
      eid: eid, mkt: marketId,
      userId: userId
    };
  }

  public getAutoSuggestMI(apiService, searchString): Observable<any> {
    const reqData = {
      'searchText': searchString,
      'userBean': {
        'userEid': this.getValidationDetail().eid,
        'marketId': this.getValidationDetail().mkt
      }
    };
    return this.httpCoreService.getHttpUrl(apiService, reqData, 'post');
  }

  errorHandler(error: any): void {
    console.log(error);
    this.spinnerService.hide();
  }
}
