import {UserBean} from '../models/userbean';
import {MiRequestDetailBean} from '../models/miRequestDetailBean';

export class MiListRequestVO {
userBean: UserBean;
miRequestBean: MiRequestDetailBean;


constructor(userBean: UserBean, miRequestBean : MiRequestDetailBean){
    this.userBean = userBean;
    this.miRequestBean = miRequestBean;
}
}
