import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class SelectedLangService {

selectedLang = '';

  constructor(private translate: TranslateService) { }


setSelectedLang(langauge: string) {
    this.selectedLang = langauge;
}


getSelectedLang(langauge: string) {
    return this.selectedLang;
}



  switchLanguage(language: string) {
    let lang;
    /* if((language == null || language == undefined) || language  == "English"){
      lang="en";
    }
    else{
      lang="es";
    } */

    if (language === 'Spanish' || language === 'SpanishUS' || language === 'Espanol') {
      lang = 'es';
    } else {
      lang = 'en';
    }

    this.setSelectedLang(lang);
    return this.translate.use(lang);
  }


}
