export const PG_CONSTANTS = {

    /* URLs */
    PACKAGE_GENERATION_PAGE: "/packagegeneration",
    PACKAGE_GENERATION_CONFIRMATION_PAGE: "/packagegeneration/confirmation",
    GENERATED_PACKAGES_PAGE: "/packagegeneration/generatedpackages",
    PACKAGE_GENERATION_STATUS_REPORT_PAGE: "/packagegeneration/statusreport",
    OWNER_OPERATOR_HOMEPAGE: '/owner-operator-homepage',

    /* APIs */
    HTTP_POST_METHOD: 'POST',
    GET_GENERATED_PACKAGES_API: 'performSearch',
    GET_STATUS_REPORT_API: 'pkgGenerationStatusReport',
    GENERATE_PKG_GEN_REPORT_EXPORT_API: 'generatePkgGenReport',
    PKG_GEN_STATUS_REPORT_EXPORT_API: 'pkgGenerationStatusReportPDFCSVEmail',
    GENERATE_PACKAGES_API: 'savePkgSchedule',
    GET_WINDOW_PERIOD_FOR_PACKAGE_API: 'getGlobalParam',
    SEND_EMAIL_API: 'sendMail',

    /* package generation */
    INFORMATION_MESSAGE_CHARACTER_LIMIT: 500,
    SHOW_INFORMATION_MESSAGE: 'block',
    HIDE_INFORMATION_MESSAGE: 'none',
    /* package generation - confirmation */
    COLS_NUMBER_NAME: 2,
    COLS_NUMBER_NAME_PROVINCE: 3,
    COLS_NUMBER_NAME_FIELDOFFICE_COOP: 4,
    COLS_NUMBER_NAME_COOP: 1,
    PACKAGEGENERATIONCONFIRMATION_: 'PackageGenerationConfirmation_',
    PACKAGE_GENERATION_CONFIRMATION: 'Package Generation Confirmation',
    PACKAGE_GENERATION_CONFIRMATION_EMAIL_CONTENT: 'Please find attached Package Generation Confirmation Page.',
    
    /* package generation - generated packages */
    ASCENDING: 'ASCENDING',
    CONTAINS: 'CONTAINS',

    /* package generation - status report */
    PACKAGESTATUSREPORT: 'PackageStatusReport',
    PACKAGESTATUSREPORT_: 'PackageStatusReport_',
    PACKAGE_STATUS_REPORT: 'Package Status Report',
    PACKAGE_STATUS_REPORT_EMAIL_CONTENT: 'Please find attached Package Status Report.',
    FAIL: 'fail',
    NO: 'no',

    /* general values */
    FALSE: false,
    TRUE: true,
    NULL: null,
    UNDEFINED:  undefined,
    EMPTY_STRING: '',
    EMPTY_ARRAY: [],
    ARRAY_FIRST_INDEX: 0,
    ZERO: 0,
    ONE: 1,
    TWO: 2,
    SIX: 6,
    STRING_SEPARATOR_COMMA_SPACE: ', ',
    SEPARATOR_RIGHT_ANGULAR_BRACKETS: '>>',
    HYPHEN: '-',
    ELLIPSIS: '...',
    SPACE: ' ',
    PDF: '.PDF',
    CSV: '.CSV',
    CSVType: 'CSV',
    PDFType: 'PDF',
    EmailType: 'Email',
    
    /* session values */
    MARKET_DATE_FORMAT: 'selectedDateFormat',
    USER_DETAILS: 'userDetails',
    MARKET_NAME: 'marketName',
    TIMEZONE: 'timeZone',
    USER_EID: 'userEid',
    MARKET_ID: 'marketId',
    MESSAGEMAP: 'messageMap',
    PERMISSIONMAP: 'permissionMap',
    US_COUNTRY_OFFICE: 'US Country Office',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    IS_REST: 'isRest',
    RESTAURANT_USER: 'R',
    NON_RESTAURANT_USER: 'notR',
    
    /* property names */
    RESTAURANT_NUMBER: 'restaurantNumber',
    RESTAURANT_NAME: 'restName',
    FIELD_OFFICE: 'fieldOffice',
    COOP: 'coop',

    REST_NUMBER: 'Number',
    REST_NAME: 'Name',
    REST_FIELD_OFFICE: 'Field Office',
    REST_COOP: 'Coop',

    PKG_ID: 'Package ID',
    EFF_DATE: 'Effective Date',
    PKG_STATUS: 'Status',
    PKG_DEL_STATUS: 'Delivery Status',
    PKG_DELIVERED: 'Delivered',

    RECORD_DATA: 'recordData',
    START_TIME: 'startTime',
    SHORT_NAME: 'shortName',

    RESTAURANTNAME: 'restaurantName',
    PACKAGE_ID: 'packageId',
    EFFECTIVE_DATE: 'effDate',
    STATUS: 'status',
    DELIVERY_STATUS: 'deliveryStaus',

    /* date formats */
    MM_DD_YYYY: 'MM/DD/YYYY',
    YYYY_MM_DD_HH_mm_ss_0: 'YYYY-MM-DD HH:mm:ss.0',
    YYYY_MM_DD: 'YYYY-MM-DD',
    MMDDYYYY_HHmmss: 'MMDDYYYY_HHmmss',
    
    COMMA_SEPARATED_TIME: ', HH:mm',
    API_DATETIME_FORMAT: 'MM-DD-YYYY, HH:mm',
    API_DATETIME: 'MM-DD-YYYY HH:mm',
    MM_DD_YYYY_hh_mm: 'MM/DD/YYYY, hh:mm',
    DATETIME: 'MM-DD-YYYY, hh:mm',
    DATETIME_FORMAT: 'MM-DD-YYYY, HH:mm:ss',
    TODAY: 'today',
    DAY: 'day',

    momentsDateFormat: 'MMM D, YYYY',
    momentsTimeFormat: 'HH:mm:ss',

    /*Analytics Constant*/
    pageName: "Package Generation",
    generatedPageName: "Package Generation – Generated Packages",
    confirmationPageName: "Package Generation Confirmation"
};