import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { DropdownService } from '../../services/dropdown.service';
import { LocalStorageService } from 'angular-2-local-storage';
import { User } from '../../models/user';
import { filter, map, pick, partialRight, cloneDeep } from 'lodash';
import { MassPromotionUpdateModel } from './model/masspromotionupdate.model';
import { PackageScheduleService } from '../package/services/package-schedule.service';

import * as _ from 'lodash';

import { HeaderComponent } from '../header/header.component';
import { PromoSelectionComponent } from './promoselection/masspromotionupdate.promoselection.component';

import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Inject, Injectable } from '@angular/core';

import { HostListener } from '@angular/core'

declare var $: any;

@Component({
	selector: 'app-masspromotionupdate',
	templateUrl: './masspromotionupdate.component.html',
	styleUrls: ['./masspromotionupdate.component.css'],
	providers: [PromoSelectionComponent]
})

@Injectable()
export class MassPromotionUpdateComponent implements OnInit {
	showHeaderMessage = false;
	userDetails: User;
	permissionMap: any;

	headerMessage = '';

	showPossibleNextStepsMessage = false;
	possibleNextStepsMessage = '';

	urlToCall = '';
	
	isRest = this.sessionStorage.get('isRest');

	nodesSelected = [];
	
	modelIdCancel = '';
	
	constructor(@Inject(SESSION_STORAGE) public sessionStorage: StorageService,
			public commonService: CommonService,
			public localStorageService: LocalStorageService,
			public massPromotionUpdateModel: MassPromotionUpdateModel,
			public dropdownService: DropdownService,
			private packageScheduleService: PackageScheduleService,
			private headerComponent : HeaderComponent,
			private promoSelectionComponent : PromoSelectionComponent) { }

	retrievePermissions(){
		this.userDetails = this.sessionStorage.get('userDetails');
		if(this.userDetails && this.userDetails['permissionMap']){
			this.permissionMap = this.userDetails['permissionMap'];
		}
		console.log('this.permissionMap', this.permissionMap);
	}

	@HostListener('click', ['$event'])
	clickout(event) {
		if (event.target.id == "addNode" && this.packageScheduleService.selectedRestaurants.length > 0) {
			this.restSelectionModel.selectedQty = this.packageScheduleService.selectedRestaurants.length;
		}
		if (event.target.id == "removeNode" && this.restSelectionModel.selectedQty > 0){
			this.restSelectionModel.selectedQty = this.packageScheduleService.selectedRestaurants.length;
		}
		if (event.target.id == "clearNodes"){
			this.restSelectionModel.selectedQty = 0;
		}
	}
	
	restSelectionModel = this.massPromotionUpdateModel.online.restSelection;
	onlinePromoSelectionModel = this.massPromotionUpdateModel.online.promoSelection;

	ngOnInit() {
		this.retrievePermissions();

		$('#collapseOne').collapse({toggle: false});
		$('#collapseTwo').collapse({toggle: false});
		$('#collapseThree').collapse({toggle: false});
	}

	onItemSelect(item:any){
		this.massPromotionUpdateModel.online.results.table = [];
        this.massPromotionUpdateModel.online.results.filterStatus = [];
	}

	OnItemDeSelect(item:any){
	}

	restBackCall(){
		var restSelectObj = [];
		for (let rest of this.massPromotionUpdateModel.online.restSelection.restaurantListForMPU) {
			if (Object.values(this.restSelectionModel.selectedRestaurants).indexOf(rest) > -1) {
				restSelectObj.unshift(rest);
			} else if (Object.values(this.restSelectionModel.selectedRestaurants).indexOf(rest) == -1) {
				restSelectObj.push(rest);
			} 
	    }
		this.massPromotionUpdateModel.online.restSelection.restaurantListForMPU = restSelectObj;

		this.promoSelectionComponent.commonSearchAction();
		this.promoSelectionComponent.filterPromo();
		this.onlinePromoSelectionModel.promoList.sort(function (a, b){
			return a.restNumber - b.restNumber || a.promoCode - b.promoCode;
		});
		
		$('#collapseOne').collapse('show');
		$('#collapseTwo').collapse('hide');
		$('#collapseThree').collapse('hide');
	}
	
	checkOnlineMsg(){
		if(this.localStorageService.get('MASS_PROMOTION_UPDATE_FUNC_ID'))
		{
			this.hideHeaderMsg();
			return true;
		}
		else { return false;}
	}

	hideHeaderMsg () {
		this.showHeaderMessage = false;
	}

	hidePossibleNextStepsMessage () {
		this.showPossibleNextStepsMessage = false;
	}

	navigateTo(url){
		window.location.href = location.origin + "/" + url;  
	}

	loadSelectedRestList(e) {
		if(this.isRest == 'R'){
			const selectedRest = filter(this.restSelectionModel.restaurantListForMPU, { 'selected': true });
			this.restSelectionModel.selectedRestaurants = selectedRest;
		} else {
			this.restSelectionModel.selectedRestaurants = [];
		}
		
		this.fillNodeSelected();
		
		this.removeUnselectedRests();
		this.fetchRecentlyAddedRests();
		this.massPromotionUpdateModel.online.updateAndReview.showUpdateTable = false;

		$('#collapseOne').collapse('hide');
		$('#collapseTwo').collapse('show');
		$('#collapseThree').collapse('hide');

		this.promoSelectionComponent.verifyAllCheckbox();
		e.stopPropagation();
	}

	loadSelectedPromoList(){
		this.promoSelectionComponent.listPromotionMassData();
	}

	removeUnselectedRests() {
		let existFlag;
		let count = 0;
		let toBeDeleted = [];
		for(let promo of this.onlinePromoSelectionModel.promoList){
			existFlag = false;

			for(let res of this.restSelectionModel.selectedRestaurants){
				if(promo.restNumber == res.rest_nu){
					existFlag = true;
				}
			}

			if(!existFlag){
				//remove of promoList
				toBeDeleted.push(count); 
			}
			count++;
		}

		toBeDeleted.sort(function(a,b){ return b - a; });
		for(let i of toBeDeleted){
			this.onlinePromoSelectionModel.promoList.splice(i, 1);
		}

		this.onlinePromoSelectionModel.duplicatePromoList = _.cloneDeep(this.onlinePromoSelectionModel.promoList);
	}

	fetchRecentlyAddedRests() {
		let notExistFlag;
		let toBeAdded = [];
		for(let newRest of this.restSelectionModel.selectedRestaurants){
			notExistFlag = true;

			if(this.onlinePromoSelectionModel.promoList.length){
				for(let promo of this.onlinePromoSelectionModel.promoList){
					if(promo.restNumber == newRest.rest_nu){
						notExistFlag = false;
						break;
					}
				}
			} else {
				notExistFlag = true;
			}
			
			if(notExistFlag){
				//fetch promoList from database (only the newly selected restaurants)
				toBeAdded.push(newRest); 
			}
		}

		//call java backend to fetch promotions to the newly selected restaurants
		if(toBeAdded.length){
			this.onlinePromoSelectionModel.showTable = false;
			let restList = _.cloneDeep(this.restSelectionModel.selectedRestaurants);
			this.restSelectionModel.selectedRestaurants = toBeAdded;
			this.promoSelectionComponent.searchPromoListNewlyAdded();
			this.restSelectionModel.selectedRestaurants = restList;
		}
	}
	
	fillNodeSelected() {
		if(this.isRest != 'R'){
			for (let res of this.packageScheduleService.selectedRestaurants) {
				let node = {
					rest_na: res.name,
					node_id: res.child,
					rest_nu: res.restNu
				}
				this.restSelectionModel.selectedRestaurants.push(node);
			}
		} else {
			this.nodesSelected = [];
			for (let res of this.packageScheduleService.selectedRestaurants) {
				let node = {
						nodeName: res.name,
						nodeNumber: res.child
				}
				this.nodesSelected.push(node);
				this.restSelectionModel.selectedQty = this.nodesSelected.length
			}
		}
	}
	
	resetTreeGen() {
		window.location.href = window.location.href;
	}
	
	onClickCancel(){
		this.headerComponent.goToRFM();
	}
	
	cancel(cancel){
        console.log("cancel");
		if (cancel == 'cancel'){
			this.modelIdCancel = "";
		}else{
			this.resetTreeGen();
			this.modelIdCancel = "";
		}
	}
}
