
export const ReportTypeConstant = {
   SELECTED_ROW_LIST : 'selectedRowList',
   EFCT_DATE: 'effectDateReport',
   REFRESH_VALIDATION_REPORT: 'REFRESH_VALIDATION_REPORT',
   REFRESH_GMA_VALIDATION_REPORT: 'REFRESH_GMA_VALIDATION_REPORT',
   REFRESH_GENERATE_AUDIT_LOG: 'REFRESH_AUDIT_LOG_REPORT',
   REFRESH_PACKAGE_STATUS_REPORT: 'REFRESH_PACKAGE_STATUS_REPORT',
   REFRESH_POS_DTL_REST_NODE_REPORT: 'REFRESH_POS_DTL_REST_NODE_REPORT',
   REFRESH_REST_PTA_REPORT: 'REFRESH_REST_PTA_REPORT',
   ANG: 'ang',
   LEG: 'leg',
   MSG_MAP: 'messageMap',
   PERMISSION: 'permission',

   /*Analytics Constant*/
   pageName: "Reports",
   generatedReports: "Generated Reports"
}
