import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OwnerOperatorHeaderComponent } from './owner-operator-header.component';
import { OwnerOperatorMenuModule } from '../owner-operator-menu/owner-operator-menu.module';
import { RouterModule } from '@angular/router';
import { DialogModule } from 'primeng/dialog';
import { PopModoleMessModule } from '../pop-modole-mess/pop-modole-mess.module';


@NgModule({
  declarations: [OwnerOperatorHeaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    OwnerOperatorMenuModule,
    DialogModule,
    PopModoleMessModule
  ],
  exports: [
    OwnerOperatorHeaderComponent
  ]
})
export class OwnerOperatorHeaderModule { }
