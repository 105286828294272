
export const OOP_CONST = {
    /* session values */
    MARKET_DATE_FORMAT: 'selectedDateFormat',
    USER_DETAILS: 'userDetails',
    MARKET_NAME: 'marketName',
    TIMEZONE: 'timeZone',
    USER_EID: 'userEid',
    USER_ID: 'userId',
    MARKET_ID: 'marketId',
    MESSAGEMAP: 'messageMap',
    PERMISSIONMAP: 'permissionMap',
    US_COUNTRY_OFFICE: 'US Country Office',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    LOCALE_LANG_DESC: 'localeLanguageDesc',
    LOCALE_LANG_NAME: 'languageName',
    LANGUAGE_MAP: 'languageMap',
    REFRESH_USER_CONTEXT: 'refreshUserContext',
    USER_MARKET_LIST: 'userMarketList',
    MARKET_LANGUAGE_MAP: 'marketLanguageMap',
    LOCALE_LANG_ID: 'localeLangID',
    LOCALE_LANG: 'localeLanguage',
    LANG_NAME: 'languageName',
    LOAD_MESSAGE_MAP: 'loadMessageMap',
    SELECTED_LANGUAGE: 'selectedLanguage',
    SELECTED_DATE_FORMAT: 'selectedDateFormat',
    LOGIN_USER_STATUS: 'loginUserStatus',
    LOGIN: 'login',
    DEFAULT: 'default',
    PERMISSION_MAP: 'permissionMap',
    IS_REST: 'isRest',
    REST_NODE_LIST: 'restNodeList',
    RFM_REFRESH_PAGE: 'rfmRefreshPage',
    IS_GLOBAL_USER: 'isGlobalUser',
    PTA_URL: 'ptaURL',
    IS_SESSION_STORAGE: 'isSessionStorage',
    BLANK: '_blank',
    SELF: '_self',
    TRUE: 'true',
    FALSE: 'false',
    AWSRDI: 'AWSRDI',
    R: 'R',
    NULL_SESSION: 'NULL_SESSION',
    NO_MARKET: 'No Market',
    IV_USER: 'iv-user=',
    PARAM_LOGIN_TEST_RDI: '&paramLogin=TEST-RDI',
    PARAM_LOGIN_1: '&paramLogin=1',
    REFRESH_YES: '&refresh=yes',
    IS_VISITED_OOP_PAGE: '&isVisitedOOPPage=',
    SYMBOL_SLASH: '//',
    SYMBOL_SINGLE_SLASH: '/',
    SYMBOL_COLON: ':',
    SYMBOL_QUESTIONMARK: '?',
    SYMBOL_UNDERSCORE: '_',
    SYMBOL_COMMA: ',',
    ZERO: '0',
    NOTIFICATION_COUNT: 'notificationCounts',
    MESSAGE_NOTIFICATION_COUNT: 'messageNotificationCount',
    SHOW_WARNING_MESSAGE_5: 'showWarningMessage:5',
    SHOW_WARNING_MESSAGE_4: 'showWarningMessage:4',
    SHOW_WARNING_MESSAGE_3: 'showWarningMessage:3',
    SHOW_WARNING_MESSAGE_2: 'showWarningMessage:2',
    SHOW_WARNING_MESSAGE_1: 'showWarningMessage:1',
    PERMISSION_OWNER_OPERATOR_MESSAGES: 'REFRESH_OWNER_OPERATOR_HOMEPAGE_MESSAGES',
    PERMISSION_OWNER_OPERATOR_PRICE_TAX_ACTIVATE: 'REFRESH_OWNER_OPERATOR_HOMEPAGE_PRICE_TAX_AND_ACTIVATE',
    PERMISSION_OWNER_OPERATOR_MENU_ITEM_INACTIVATION: 'REFRESH_OWNER_OPERATOR_HOMEPAGE_MENU_ITEM_INACTIVATION',
    ACTION_ITEM_KEY: 'actionItemKey',
    TILE_ID: 'Tile_ID',
    ALERT_TEXT_1: '_alertText_1',
    GENERATE_REPORT_NOTIFICATION: 'generatedReportNotificationCount',
    USER_TOKEN: 'userToken',
    PERMISSION: 'permission',
    CONFIGDATA: 'ConfigData',
    ACCESS_BUTTON_TEXT: '_accessButtonText',
    ALERT_TEXT: '_alertText',
    TILE_TITLE: '_tileTitle',
    POST: 'post',
    TOKEN: 'token',
    USERIDMESSAGE: 'Welcome',
    RFMLABEL: 'RFM',
    VIEW: 'View'
    }