import { Injectable } from '@angular/core';
import {UserBean} from '../../../models/userbean';

@Injectable()
export class MassPromotionUpdateModel {
	online = {
		isUpdate: true,
		restSelection: {
			restaurantNodeList: '',
			formDirty: false,
			restaurantListForMPU: [ 
				{
					'rest_nu': '',
					'rest_na': '',
					'regionFieldOffice': '',
					'coop': '',
					selected: false
				}				
			],
			selectedRestaurants: [],
			selectedQty: 0,
			filter: {
				'rest_nu': '',
				'rest_na': '',
				'fieldOfficeRegion': '',
				'coop': ''
			},
			sortAscending: {
				'rest_nu': false,
				'rest_na': false,
				'fieldOfficeRegion': false,
				'coop': false
			}
		},
		promoSelection: {
			promoList: [],
			duplicatePromoList: [],
			promoDropDownListFilterStatus: [],
			promoDropDownListFilterChecked: [],
			promoStatusFilter: [],
			promoCheckedFilter: [],
			showTable: false,
			formDirty: false,
			isReadOnly: false,	
			disableUpdate: false,
			disableNext: false,
			filterType: 'CONTAINS',
			filterText: '',
			promoCode: '',
			promoTemplate: '',
			promoStatus: '',
			selectAllPromoHeader: false,
			existAnyCheckedVar: false,
			filter: {
				'restNumber': '',
				'nodeName': '',
				'promoCode': '',
				'promoName': '',
				'promoLongDesc': '',
				'promoTemplateLabel': '',
				'strtDate': '',
				'endDate': '',
				'status': ''
			},
			sortAscending: {
				'restNumber': false,
				'nodeName': false,
				'promoCode': false,
				'promoName': false,
				'promoLongDesc': false,
				'promoTemplateLabel': false,
				'strtDate': false,
				'endDate': false,
				'status': false
			}
		},
		updateAndReview: {
			disableCancel: false,
			table: [],
			duplicatePromoUpdateList: [],
			duplicatedSelectedPromoList: [],
			tableCopy: [],
			showTable: false,
			showUpdateTable: false,
			showResultTable: false,
			noRecords: true,
			disableUpdateNoChanges: false,
			disableUpdateDateError: false,
			promoDropDownListFilterStatus: [],
			filter: {
				'restNumber': '',
				'nodeName': '',
				'promoCode': '',
				'promoName': '',
				'promoLongDesc': '',
				'promoTemplateLabel': '',
				'strtDate': '',
				'endDate': '',
				'status': ''
			},
			sortAscending: {
				'restNumber': false,
				'nodeName': false,
				'promoCode': false,
				'promoName': false,
				'promoLongDesc': false,
				'promoTemplateLabel': false,
				'strtDate': false,
				'endDate': false,
				'status': false
			}
		},
		results: {
			table: [],
			duplicatePromoResultList: [],
			filterStatus: [],
			filterStatusCopy: [],
			filterSettings: {},
			updatedQty: '',
			promoDropDownListFilterStatus: [],
			filter: {
				'restNumber': '',
				'nodeName': '',
				'promoCode': '',
				'promoName': '',
				'promoLongDesc': '',
				'promoTemplate': '',
				'strtDate': '',
				'endDate': '',
				'status': ''
			},
			sortAscending: {
				'restNumber': false,
				'nodeName': false,
				'promoCode': false,
				'promoName': false,
				'promoLongDesc': false,
				'promoTemplate': false,
				'strtDate': false,
				'endDate': false,
				'status': false
			}
		}
	};
	
	userBean: UserBean;
}
