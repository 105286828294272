import { Injectable } from '@angular/core';
import {HttpCoreService} from '../../../services/http-core.service';
import {LocalStorageService} from 'angular-2-local-storage';
import { Observable } from 'rxjs';


@Injectable()
export class MassMenuItemDeleteReportService {

	menuSelectedItems = [];
	eid = '';
	marketId = '';
	token= '';
	filteredList: any[];
	restScrollcount: any[] = [];
	eq: any;
	userParam:any;
	
	filteredStartCount: number = 0;
  	originalStartCount: number = 0;
  
	constructor(
		public localStorageService: LocalStorageService, 
  		public httpCoreService: HttpCoreService
  	) {}

	public getMenuItemListReport(apiService, data): Observable<any> {
		console.log("getMenuItemListService Called"); 
		return this.httpCoreService.getHttpUrl(apiService, data, 'post');
	}
	
	public getMenuItemListDetails(apiService, data): Observable<any> {
		console.log("getMenuItemListDetails Called"); 
		return this.httpCoreService.getHttpUrl(apiService, data, 'post');
	}

  	errorHandler(error: any): void {
		console.log(error);
	}
	
	public generateMassMenuItemReport(apiService, data): Observable<any> {
		return this.httpCoreService.getHttpUrl(apiService, data, 'post');
	}
	
}
