import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../environments/environment';
import { connection } from '../../environments/connection.properties';

@Injectable()
export class HttpCoreService {

	urlToCall = '';	

	constructor(
		public httpClient: HttpClient,
		private spinnerService: NgxSpinnerService
    ){}

	getHttpUrl(url, reqParam, methodType){    
    	this.urlToCall = '';
    
    	if(connection.RELATIVE_PATH == "true" || connection.RELATIVE_PATH == 'true'){
			this.urlToCall = location.protocol + "//" + location.hostname + ":" + 
				connection.RELATIVE_API_PORT + connection.RELATIVE_API_PATH;
		} else {
			this.urlToCall = connection.API_FULL_URL;
		}
    
    	if (environment.envName === 'json'){
      		return this.httpClient.get(url).pipe(
        	map((response) => {
          		return response;
        	}),
        	catchError((e: any) => observableThrowError(this.errorHandler(e))),);
    	} else if (methodType === 'json'){
    	
    		return this.httpClient.get(url).pipe(
        	map((response) => {
          		return response;
        	}),
        	catchError((e: any) => observableThrowError(this.errorHandler(e))),);
    	
    	} else {
    		this.urlToCall += url;
    	
      		if(methodType === 'get'){
        		return this.httpClient.get(this.urlToCall).pipe(
          		    map((response) => { return response }),
          		    catchError((e: any) => observableThrowError(this.errorHandler(e)))
                );
      		} else { //POST METHOD
				return this.httpClient.post(this.urlToCall, JSON.stringify(reqParam)).pipe(
          		    map((response) => {	return response }),
                    catchError((e: any) => observableThrowError(this.errorHandler(e)))
                );
			}
		}
	}

	errorHandler(error: any): void {
		console.log(error);
		this.spinnerService.hide();
	}
	
}