import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'miFilter'
})
export class MiFilterSharedPipe implements PipeTransform {

  transform(items: any, miID: any, miName: any, miClass: any, miStatus: any, miApprovalStatus): any {
    var flag = false;
    if (items && items.length) {
      return items.filter(item => {
        // menuItemCode
        if (miID  &&  item.productCode.toString().indexOf(miID.toString()) === -1) {
          return false;
        }
        // menuItemName
        if (miName  && item.productDescription.toLowerCase().indexOf(miName.toLowerCase()) === -1) {
          return false;
        }
        // menuItemClass
        if(miClass.length > 0 && miClass){
          for(var i = 0; i <= miClass.length - 1; i++){
            if(miClass && item.prodClassId.toString() == miClass[i].id.toString()){
              flag = true; break;
            } else {
              flag = false;
            }
          }    
          return flag;      
        }
         // menuItemStatus
         if(miStatus.length > 0 && miStatus){
          for(var i = 0; i <= miStatus.length - 1; i++){
            if(miStatus && item.status.toString().indexOf(miStatus[i].id.toString()) === 0){
              flag = true; break;
            } else {
              flag = false;
            }
          }  
          return flag;        
        }

           // Approval status
           if(miApprovalStatus.length > 0 && miApprovalStatus){
            for(var i = 0; i <= miApprovalStatus.length - 1; i++){
              if(miApprovalStatus && item.approvalStatus.toString().indexOf(miApprovalStatus[i].id.toString()) === 0){
                flag = true; break;
              } else {
                flag = false;
              }
            }   
            return flag;         
          }

        return true;
      });
    } else {
      return items;
    }
  }


  transformMiClass(items: any, miClass: any): any {
    var flag = false;
    if (items && items.length) {
      return items.filter(item => {
        // menuItemClass
        if(miClass.length > 0 && miClass){
          for(var i = 0; i <= miClass.length - 1; i++){
            if(miClass && item.prodClassId.toString() == miClass[i].id.toString()){
              flag = true; break;
            } else {
              flag = false;
            }
          }    
          return flag;      
        }
        return true;
      });
    } else {
      return items;
    }
  }

  transformMiApprovalStatus(items: any, miApprovalStatus): any {
    var flag = false;
    if (items && items.length) {
      return items.filter(item => {
           // Approval status
           if(miApprovalStatus.length > 0 && miApprovalStatus){
            for(var i = 0; i <= miApprovalStatus.length - 1; i++){
              if(miApprovalStatus && item.approvalStatus.toString().indexOf(miApprovalStatus[i].id.toString()) === 0){
                flag = true; break;
              } else {
                flag = false;
              }
            }   
            return flag;         
          }

        return true;
      });
    } else {
      return items;
    }
  }


  transformMItemStatus(items: any, miStatus: any): any {
    var flag = false;
    if (items && items.length) {
      return items.filter(item => {      
        
         // menuItemStatus
         if(miStatus.length > 0 && miStatus){
          for(var i = 0; i <= miStatus.length - 1; i++){
            if(miStatus && item.status.toString().indexOf(miStatus[i].id.toString()) === 0){
              flag = true; break;
            } else {
              flag = false;
            }
          }  
          return flag;        
        }          
        return true;
      });
    } else {
      return items;
    }
  }


}
