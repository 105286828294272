import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from  "@angular/common/http";
import { HttpCoreService } from '../../../services/http-core.service';
import { BehaviorSubject } from 'rxjs';
import { Inject } from '@angular/core';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import * as moment from 'moment';
import { EffectDateService } from '../../shared-modules/select-effective-date/service/effect-date.service';
import { MSG_CONST } from '../constants/message-creation.constant';
@Injectable({
  providedIn: 'root'
})
export class MessageCreationService {

  masterMessageData:  any;
  private msgIndex = new BehaviorSubject(-1);
  private _simulatorOnClickOnHeader:boolean = false;
  currentMessage = this.msgIndex.asObservable();
  currentDate: any;
  fromHeader = new BehaviorSubject(false);
  clickFromHeader = this.fromHeader.asObservable();
  isEditPageRoute: boolean = false;

  constructor(private httpClient: HttpClient, public httpCoreService: HttpCoreService, private dateService: EffectDateService,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService) { }

  public getMessageData(apiService, data) : Observable<any>{
    //return this.httpClient.get("assets/mock-json/mock-json/message_mockData.json");
    return this.httpCoreService.getHttpUrl(apiService, data, MSG_CONST.POST);
  }
  public getSearchData(apiService,data) : Observable<any>{
    //return this.httpClient.get("assets/mock-json/mock-json/message_mockData.json");
    return this.httpCoreService.getHttpUrl(apiService, data, MSG_CONST.POST);
  }

  public getRestList(apiService, data) : Observable<any> {
    return this.httpCoreService.getHttpUrl(apiService, data, MSG_CONST.POST);
  }
  public subjectAutoSuggest(apiService,data) : Observable<any>{
    //return this.httpClient.get("assets/mock-json/mock-json/message_mockData.json");
    return this.httpCoreService.getHttpUrl(apiService, data, MSG_CONST.POST);
  }
  public getMessageType(apiService, data): Observable<any> {
    return this.httpCoreService.getHttpUrl(apiService, data, MSG_CONST.POST);
  }

  createMessage(apiService, data): Observable<any> {
    return this.httpCoreService.getHttpUrl(apiService, data, MSG_CONST.POST);
  }

  changeCurrentMessage(newMessage){
    this.msgIndex.next(newMessage);
  }

  setMasterMessages(data) {
    this.masterMessageData = data;
  }

  getMasterMessages() {
    return this.masterMessageData;
  }

  setCurrentDate(date) {
    this.currentDate = date;
  }

  getCurrentDate() {
    return this.currentDate;
  }

  getStatus(sDate: string, eDate: string){
    let DT_FORMAT = this.sessionStorage.get(MSG_CONST.SELECTED_DATE_FORMAT).toString().toUpperCase();
    let startDate = moment(sDate).format(DT_FORMAT);
    let currentDate = moment(this.currentDate).format(DT_FORMAT);
    let endDate = moment(eDate).format(DT_FORMAT);

    if(moment(currentDate).isAfter(endDate)) {
      return MSG_CONST.EXPIRED;
    } else if(moment(currentDate).isBefore(startDate)) {
      return MSG_CONST.PENDING;
    } else return  MSG_CONST.DELIVERED;
  }

  deleteMessage(apiService, data){
    return this.httpCoreService.getHttpUrl(apiService, data, MSG_CONST.POST);
  }

  cancelConfirmFromHeader() {
    this.fromHeader.next(true);
  }

  setEditPageRoute(flag) {
    this.isEditPageRoute = flag;
  }

  getEditPageRoute() {
    return this.isEditPageRoute;
  }
  get simulatorOnClickOnHeader(): boolean {
    return this._simulatorOnClickOnHeader;
  }
  set simulatorOnClickOnHeader(value: boolean) {
      this._simulatorOnClickOnHeader = value;
  }
}

