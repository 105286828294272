import { Component, Input, OnInit, Inject, Injectable } from '@angular/core';
import { PackageScheduleService } from '../services/package-schedule.service';
import { TreeHelperService } from '../../../services/tree-helper.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as _ from 'lodash';
import { SelectedLangService } from '../../../services/selected-lang.service';
import { LocalStorageService } from 'angular-2-local-storage';
import { BatchSchedulingService } from '../batch-scheduling/services/batch-scheduling-service';

import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { DropdownService } from '../../../services/dropdown.service';
import { MiRequestDetailBean } from '../../../models/miRequestDetailBean';
import { MiListRequestVO } from '../../../models/miListRequestVO';
import { UserBean } from '../../../models/userbean';
import { MassMenuItemUpdateHttpService } from '../../mass-menu-item-update/services/mass-menu-item-update-http.service';
import { GenerateAuditBusinessService } from '../../audit-log/generate-audit-log/services/generate-audit-business.service';
import { MassMenuItemUpdateModel } from '../../../../app/components/mass-menu-item-update/model/mass-menu-item-update-model';
import { appconstant } from '../../../../environments/app.constant';
import { GenerateAuditHttpService } from '../../audit-log/generate-audit-log/services/generate-audit-http.service';
import { MiFilterSharedPipe } from '../../../shared/pipes/menu-item-filter.pipe';
import { RfmApplicationSettingService } from '../../../services/rfm-application-setting.service';

@Component({
	selector: 'app-page-tree',
	templateUrl: './app-page-tree.component.html',
	styleUrls: ['./app-page-tree.component.css'],
	providers: [TreePageComponent,MiFilterSharedPipe]
})

@Injectable()
export class TreePageComponent implements OnInit {
	statusList = [];
	scrollStart: number;
	status: number;
	restaurantTreeData = [];
	selectedTreeItems = [];
	selectionsArray = [];
	selectionsArrayFullList = [];
	returnStatus = 0;
	lastSelectedRow;
	selectedToRemoveArray = [];
	exactMatch = false;
	userStatus = '';
	selectParentOnly = false;
	filterString = '';
	reverse = true;
	// dropdown variables
	dropDownData: any = { headers: [] };

	disableFrstDropdown = false;
	marketList = [];
	regionList = [];
	coopList = [];
	selectedMarketItems = [];
	selectedRegionItems = [];
	selectedCoopItems = [];
	marketListSettings = {};
	regionListSettings = {};
	coopListSettings = {};
	paramSetMultiDropSettings = {};
	selectedStatusItems = [];
	userLanguage: string = '';
	translate: any;
	flag: boolean = true;
	@Input()public setFlag:boolean = true;
	miListRequestVO: MiListRequestVO = new MiListRequestVO(new UserBean(), new MiRequestDetailBean());
	classList = [];
	prodClassSearch: any[] = [];
	prodClassListSettings = {};
	statusClassListSettings = {};
	menuItemDropDownListStatus = [];
	isbatchScheduling: boolean = this.router.url.indexOf('/batchScheduling') === 0;
	lookupList: any[];
	aprvlStatusList: any[];
	menuAprvlStsItem = [];
	layeringLogicType: any;
	menuselectedItems = [];
	showTable = false;
	errorMSG;
	noRecords = false;
	filterClassList = [];
	menuItemDropDownListFilterStatus = [];
	statusResultTableSettings = {};
	miClassFilter = [];
	miStatusFilter = [];
	approvalStatusFilter = [];
	miListSuggest = [];
	miNumberFilter = '';
	miNameFilter = '';
	filteredData: any[];
	miList:any = []
    messageMap: any;
    @Input() maxRestSelection:boolean = false;
    maxRestSelectionCondition: boolean = false;
    maxRestSelectionNumber: number;
    maxRestSelectionHeader: string;	
    @Input() maxmenuItemSelection:boolean = false;
    digitRegex = /^\d+$/;    
    regionSectionLimitValues = new Array();
    errorForMaxRestSelection: boolean = false;
    errorForMaxRestSelectionMsg: string;
    errorForMaxRestSelectionTitle: string;
    addToSelectValues = [];
    addToSelectValuesIndex: number = 0;
    selectedTreeItemsCopy = [];
    lastSelectionToBeRemoved: any[];
    firstTimeSelection: boolean = false;
    errorForEmptyNodeSelection: boolean = false;
    errorForEmptyNodeSelectionTitle:string;
    errorForEmptyNodeSelectionMsg: string;
    errorForEmptyNodeSelectionItems = [];
    ignoreMaxCondDueToOneChild: boolean = false;

	constructor(private packageScheduleService: PackageScheduleService,		
		    private massMenuItemUpdateHttpService: MassMenuItemUpdateHttpService,
			private generateAuditBusinessService: GenerateAuditBusinessService,
			public massMenuItemUpdateModel: MassMenuItemUpdateModel,
			public localStorageService: LocalStorageService,
			private dropdownService: DropdownService,
			private spinnerService: NgxSpinnerService,
			private treeHelperService: TreeHelperService,
			private selectedLangService: SelectedLangService, 
			private router: Router,
			public miFilterPipe: MiFilterSharedPipe,
			private batchSchedulingService: BatchSchedulingService,
			private generateAuditHttpService :GenerateAuditHttpService,
			@Inject(SESSION_STORAGE) private sessionStorage: StorageService,
            private rfmApplicationSettingService: RfmApplicationSettingService) {                
            this.messageMap = this.sessionStorage.get('messageMap');}

	ngOnInit() {

		this.batchSchedulingService.selectedNodesCast.subscribe(
			selectedNodes => this.selectionsArray = selectedNodes
		);

		if (this.isbatchScheduling) {
			this.packageScheduleService.selectedRestaurants = this.selectionsArray;
		}

		this.spinnerService.show();
		if (this.router.url.indexOf('/generatePTA')!== -1 || this.router.url.indexOf('/restaurantdbparameter')!== -1){
			this.returnStatus = 1;
			this.statusList = [
			                   {id: '1' , itemName : 'Active'},
			                   {id: '2' , itemName : 'Inactive'}
			                   ]      
		}
		if (this.sessionStorage.get('localeLangDesc') != null) {
			this.userLanguage = this.sessionStorage.get('localeLangDesc');
		}
		console.log(this.router.url);
		this.translate = this.selectedLangService.switchLanguage(this.userLanguage); 
		this.packageScheduleService.getRestaurantData('getTreeGenerator', this.returnStatus).subscribe((treeData) => {
			this.treeHelperService.addVisibleFlag(treeData);
			if (treeData[0] && treeData[0].name === 'void') {
				this.restaurantTreeData = treeData[0].children; 	
			}else {
				this.restaurantTreeData = treeData;
				if (this.restaurantTreeData && this.restaurantTreeData[0]) {
					this.restaurantTreeData[0].expanded = true;
                    if(this.restaurantTreeData[0].children.length === 1){
                        this.ignoreMaxCondDueToOneChild = true;
                    }
				}
			}
			if (this.isbatchScheduling) {
				this.packageScheduleService.selectedRestaurants = this.selectionsArray;
				this.addToSelection()
			}
			this.spinnerService.hide();
		});

		this.dropdownService.getDropdownData('getMidLevelNodesDetailsNew').subscribe((dropdownData) => {
			console.log('dropdownData', dropdownData);

			if (this.sessionStorage.get('marketName') == 'US Country Office'){
				dropdownData['headers'].Level2 = "Field Office";
			}

			this.dropDownData = dropdownData;
			this.updateDropdownData();
		});
		this.initializeDropdowns();
		this.menuItemListSet();
		this.loadLookup();
		this.getSelectFlag('c');
        this.rfmApplicationSettingService.get(this.miListRequestVO.userBean.marketId , 7180, appconstant.MAXIMUM_RESTAURANT_HIERARCHY_LEVEL_1_LIMIT_FOR_RESTAURANT_PTA_REPORT).subscribe(result => {
            if(this.digitRegex.test(result.value)){
                this.maxRestSelectionNumber = parseInt(result.value) ;
                if(this.maxRestSelection && !isNaN(this.maxRestSelectionNumber) && this.maxRestSelectionNumber > 0){
                    this.maxRestSelectionCondition = true;
                    this.maxRestSelectionHeader = this.messageMap.restPTAReportRestTableHeader;
                    this.maxRestSelectionHeader = this.maxRestSelectionHeader.replace('restSelectionNumber',this.maxRestSelectionNumber.toString());
                    this.errorForMaxRestSelectionTitle = this.messageMap.restPTAReportErrorModalTitle;
                    this.errorForMaxRestSelectionMsg = this.messageMap.restPTAReportRestTableErrorMsg;
                    this.errorForMaxRestSelectionMsg = this.errorForMaxRestSelectionMsg.replace('restSelectionNumber',this.maxRestSelectionNumber.toString());
                    this.errorForEmptyNodeSelectionTitle = "Confirmation";
                    this.errorForEmptyNodeSelectionMsg = this.messageMap.restPTAReportNodeErrorModalMsg;
                }
            }            
        });   
	}
	menuItemListSet(){
		this.miListRequestVO.userBean.userEid = this.sessionStorage.get('userEid');
		this.miListRequestVO.userBean.marketId = this.sessionStorage.get('marketId');
		this.layeringLogicType = this.sessionStorage.get('layeringLogicType');
		this.miListRequestVO.miRequestBean.approvalStatus = '';
		this.miListRequestVO.miRequestBean.status = '';
		this.statusClassListSettings = this.dropdownService.dropDownSingleSettings;
		this.menuItemDropDownListStatus = appconstant.STATUS_MENU_DROPDOWN_OPTIONS;
		this.menuselectedItems.push(this.menuItemDropDownListStatus[0])
		this.statusResultTableSettings = this.dropdownService.dropDownResultTableSettings;



	}
	loadLookup() {
		this.lookupList = [];
		this.massMenuItemUpdateHttpService.getLoadLookup('loadLookup', this.miListRequestVO.userBean)
		.subscribe((lookupList) => {
			this.lookupList = lookupList;
			this.classList = this.generateAuditBusinessService.getClassDropdownData(this.lookupList['productClassList']);
			this.aprvlStatusList = this.generateAuditBusinessService.getClassDropdownData(this.lookupList['approvalStatusList']);
			this.menuAprvlStsItem.push(this.aprvlStatusList[0]);
		});
	}
	searchMenu() {
		this.clearAll();
		let miSts:any;
	if (this.prodClassSearch != null) {
		this.miListRequestVO.miRequestBean.productClass = this.generateAuditBusinessService.getMenuItemClass(this.prodClassSearch);
	}
	if(this.layeringLogicType == 2) {
		this.miListRequestVO.miRequestBean.status = this.menuselectedItems[0].id;
		miSts = 'status';
	}
	else{
		this.miListRequestVO.miRequestBean.approvalStatus = this.menuAprvlStsItem[0] && this.menuAprvlStsItem[0].id? this.menuAprvlStsItem[0].id : '';
		// this.menuAprvlStsItem[0].id;
		miSts = 'approvalStatus';
	}
		this.miListRequestVO.miRequestBean.orderBy = '1';
		this.miListRequestVO.miRequestBean.sortOrder = 'ASC';
		this.spinnerService.show();
		this.generateAuditHttpService.getMenuItemList('listMasterMiStatus', this.miListRequestVO)
		.subscribe((menuItemList) => {
			this.miList = menuItemList.listMenuitem;
			this.generateAuditBusinessService.layeringLogicType = this.layeringLogicType;
			this.generateAuditBusinessService.miList = menuItemList.listMenuitem;
			this.noRecords = true;
			this.showTable = true;
			this.spinnerService.hide();
			if (this.generateAuditBusinessService.miList == null || !this.generateAuditBusinessService.miList)
			{
			this.errorMSG ='0 records found for the search criteria.';
			
			this.noRecords = true;

			 }
			 else {			
				this.noRecords = false;
				this.filterClassList = _.sortBy(this.generateAuditBusinessService.getFltrClassDropdownData(
					this.generateAuditBusinessService.getUniqueData(this.generateAuditBusinessService.miList, 'prodClassId')),
			'itemName');
			this.menuItemDropDownListFilterStatus = _.sortBy(this.generateAuditBusinessService.getFltrSts(
				this.generateAuditBusinessService.getUniqueData(this.generateAuditBusinessService.miList, miSts),
				miSts, 'menu'), 'itemName');

			 }
			 this.filteredData =_.cloneDeep(this.miList)

	})
}
	clearAll(){
		this.generateAuditBusinessService.miList = [];
		this.miStatusFilter = [];
		this.approvalStatusFilter = [];
		this.miClassFilter = []
	}
	getAutoSuggestMI(searchText) {
		this.miListSuggest = [];
		if (searchText.length === 0) {
			this.miListSuggest = [];
		}
		if (searchText.length < 3) {
			return;
		}
		this.massMenuItemUpdateHttpService.getAutoSuggestMI('getAutoFillMenuItems', searchText)
		.subscribe((menuItemList) => {
			this.miListSuggest = menuItemList;
		});
	}
	getSelectFlag(flag){
	if (flag == 'c') {
		this.miListRequestVO.miRequestBean.filterType = 'CONTAINS';	
	}
	else if (flag == 'e') {
		this.miListRequestVO.miRequestBean.filterType = 'EXACT';	
	}
	}
	filterRestaurants(value) {
		this.spinnerService.show();
		if (this.router.url.indexOf('/generatePTA')!== -1 || this.router.url.indexOf('/restaurantdbparameter')!== -1){
			if (this.selectedStatusItems.length ==0){
				this.status = 3;
			}else if(this.selectedStatusItems.length == 2 || this.selectedStatusItems == undefined){
				this.status = 2;
			}else if (this.selectedStatusItems[0] != undefined && this.selectedStatusItems[0].id == 1){
				this.status = 1;
			}else if (this.selectedStatusItems[0] != undefined && this.selectedStatusItems[0].id == 2){
				this.status = 0;
			}
		}

		this.filterString = value;
		this.packageScheduleService.treeSearch('searchTree', this.createFilterData(value, this.status), 'restaurant').subscribe((treeData) => {
			/*
			 * if (!this.filterString) {
			 * this.treeHelperService.addVisibleFlag(treeData); }
			 */
			if(treeData[0]) {
				this.treeHelperService.addVisibleFlagForNode(treeData, this.filterString);
			}
			
			if (treeData[0] && treeData[0].name === 'void') {
				this.restaurantTreeData = [];
				this.restaurantTreeData = treeData[0].children;
			}else {
				this.restaurantTreeData = treeData;
				if (this.restaurantTreeData && this.restaurantTreeData[0]) {
					this.restaurantTreeData[0].expanded = true;
				}
			}
			this.spinnerService.hide();
		});
	}
	// Drop down code start.
	updateDropdownData() {
		this.disableFrstDropdown = false;
		if (this.dropDownData && this.dropDownData.values) {
			if (this.dropDownData.values['Level1'] && this.dropDownData.values['Level1']) {
				this.marketList = this.dropdownService.getFirstDropdownData(this.dropDownData.values);
				// Making the default market selection.
				if (this.marketList.length === 1) {
					this.disableFrstDropdown = true;
					this.selectedMarketItems = this.marketList;
					// Fetch region list for default selected market.
					this.regionList = this.dropdownService.getSecondDropdownData(this.dropDownData.values, this.marketList);
				} else if (this.marketList.length > 1 && this.marketList[0].itemName === 'GLOBAL') {
					this.selectedMarketItems.push(this.marketList[0]);
				}
			}
		}
	}

	onDropDownChange(from, event) {
		if (from === 'market') {
			this.regionList = this.dropdownService.getSecondDropdownData(this.dropDownData.values, this.selectedMarketItems);
			if (this.selectedMarketItems.length === 0) {
				this.selectedRegionItems = [];
			}
		}
		if (from === 'region') {
			this.coopList = this.dropdownService.getThirdDropdownData(this.dropDownData.values, this.selectedRegionItems);
			let tempselectedCoopItems = _.cloneDeep(this.selectedCoopItems);
			this.selectedCoopItems.forEach(coopsSelected => {
				if (_.findIndex(this.coopList, { 'node': coopsSelected.node }) === -1) {
					const index = _.findIndex(tempselectedCoopItems, { 'node': coopsSelected.node});
					tempselectedCoopItems.splice(index,1);
				}
			});
			this.selectedCoopItems = tempselectedCoopItems;
		}
	}

	onDeSelectAll(from, event) {
		if (from === 'market') {
			this.regionList = [];
			this.selectedRegionItems = [];
			this.coopList = [];
			this.selectedCoopItems = [];
		}
		if (from === 'region') {
			this.coopList = [];
			this.selectedCoopItems = [];
		}
	}

	initializeDropdowns() {
		this.marketListSettings = this.dropdownService.dropDownSettings;
		this.marketListSettings['autoPosition']= false;
		this.marketListSettings['position']= 'bottom';
		this.regionListSettings = this.dropdownService.dropDownSettings;
		this.coopListSettings = this.dropdownService.dropDownSettings;
		this.paramSetMultiDropSettings = this.dropdownService.dropDownSettings;
	}

	createFilterData(value, paramStatus:number) {
		const tempArray = [];
		if (this.dropDownData['headers'] && this.dropDownData['headers'].Level1) {
			this.dropdownService.createDropDownSelectionArrayForSearch(this.selectedMarketItems, this.dropDownData['headers'].Level1, tempArray);
		}
		if (this.dropDownData['headers'] && this.dropDownData['headers'].Level2) {
			this.dropdownService.createDropDownSelectionArrayForSearch(this.selectedRegionItems, this.dropDownData['headers'].Level2, tempArray);
		}
		if (this.dropDownData['headers'] && this.dropDownData['headers'].Level3) {
			this.dropdownService.createDropDownSelectionArrayForSearch(this.selectedCoopItems, this.dropDownData['headers'].Level3, tempArray);
		}
		const tempObj = {
				exactMatch: this.exactMatch,
				searchString: value,
				dropDownValues: tempArray,
				status: paramStatus,
				returnStatus: this.returnStatus,
		};
		return tempObj;
	}

	// drop down code end.

	toggleNode(node) {
		node.expanded = !node.expanded;
	}

	selectTreeItem(node) {
		if (!node.disabled) {
			node.selected = !node.selected;
		}
	}

	selectTreeItemShiftKey(node, event, index, regionChildren) {
		if (!node.disabled) {
			node.selected = !node.selected;
		}

		if(event.shiftKey) {
			if (this.lastSelectedRow > index){
				for(let i = index+1; i < this.lastSelectedRow ; i++){
					this.selectTreeItem(regionChildren[i]);
				}
			}else{
				for(let i = this.lastSelectedRow+1; i < index; i++){
					this.selectTreeItem(regionChildren[i]); 
				}
			}
		}else{
			this.lastSelectedRow = index;
		}
	}

	toggleSelection(selection) {
		if (selection !== undefined) {
			selection.selectedToRemove = !selection.selectedToRemove;
		}
	}

	addToSelection() {
        let ignoreMaxSelectionCondition = false;
		if(this.isbatchScheduling){
			this.addToSelecitionBathcShecule();
		}else{
			if (this.selectParentOnly) {
				this.createSelectedItemsListForToggleOn(this.restaurantTreeData);
			} else {
				this.createSelectedItemsListForToggleOff(this.restaurantTreeData);
			}
            this.selectedTreeItems.forEach(data => {
                if(data.level === 2 && data.lastLeaf){
                    ignoreMaxSelectionCondition = true;
                }
            });
            if(this.ignoreMaxCondDueToOneChild){
                ignoreMaxSelectionCondition = true;
            }
            if(this.maxRestSelectionCondition && !ignoreMaxSelectionCondition){
                let regionName = [];
                this.selectedTreeItems.forEach(data => {
                    if(data.typ === 'R' && data.level !== 2){
                        regionName.push(data.hrcyStr.split('>>')[1]);                       
                    }
                    if(!data.lastLeaf){
                        if(data.hrcyStrOfLevels !== "1"){
                            regionName.push(data.hrcyStr.split('>>')[1]);
                        }else{                            
                            regionName.push(data.name);
                        }
                    }
                });
                this.regionSectionLimitValues = [...new Set(regionName)];
                this.lastSelectionValues();
                if(this.regionSectionLimitValues.length <= this.maxRestSelectionNumber){
                    this.errorForEmptyNodeSelectionItems = this.lastSelectionToBeRemoved.filter(x => x.lastLeaf === false);                    
                    if(this.errorForEmptyNodeSelectionItems.length !== 0 && this.lastSelectionToBeRemoved.length === this.errorForEmptyNodeSelectionItems.length){
                        this.errorForEmptyNodeSelection = true;
                    }else{
                        this.selectedTreeItemsCopy = this.selectedTreeItems.filter(x => !this.errorForEmptyNodeSelectionItems.includes(x));  
                        this.selectedTreeItems = [...this.selectedTreeItemsCopy];
                        this.selectionsArray = _.cloneDeep(this.selectedTreeItems);
                        if (this.selectionsArray.length === 0) {
                            this.initializeScroll();
                        }
                    }
                }else{                    
                    this.errorForMaxRestSelection = true;
                }
            }else{
                this.selectionsArray = _.cloneDeep(this.selectedTreeItems);
                if (this.selectionsArray.length === 0) {
                    this.initializeScroll();
                }   
            }   
			// Clearing the selection side arrays.
			/*
			* this.selectedTreeItemsCopy = _.cloneDeep(this.selectedTreeItems);
			* this.selectedTreeItems = [];
			*/
			if (!this.selectParentOnly) {
				if(this.restaurantTreeData)
				this.restaurantTreeData.forEach(element => {
					this.treeHelperService.disableTillLastChild(element);
				});
			}
			// Code should be modified
			this.packageScheduleService.selectedRestaurants = _.cloneDeep(this.selectedTreeItems);
		}
	}
    lastSelectionValues(){
        if(this.selectedTreeItemsCopy.length !== 0){
            this.firstTimeSelection = false;
            this.lastSelectionToBeRemoved = this.selectedTreeItems.filter(x => !this.selectedTreeItemsCopy.includes(x));
        }else{
            this.lastSelectionToBeRemoved = [...this.selectedTreeItems];
            this.selectedTreeItemsCopy = [...this.selectedTreeItems];
            this.firstTimeSelection = true;
        }
    }

	addToSelecitionBathcShecule() {
		this.createSelectedItemsListForToggleOn(this.restaurantTreeData);

		if (this.selectionsArray.length != 0) {
			for (let res of this.selectionsArray) {
				this.selectedTreeItems.push(res);
			}
			this.selectedTreeItems = this.selectedTreeItems.filter(function (a) {
				return !this[a.child] && (this[a.child] = true)
			}, Object.create(null));
		}

		this.selectionsArray = _.cloneDeep(this.selectedTreeItems);
		if (this.selectionsArray.length === 0) {
			this.initializeScroll();
		}

		if (this.flag) {
			this.removeChildren(this.restaurantTreeData);
		}

		this.restaurantTreeData.forEach(element => {
			this.treeHelperService.disableTillLastChild(element);
		});
		
		this.packageScheduleService.selectedRestaurants = _.cloneDeep(this.selectedTreeItems);
	}

	initializeScroll() {
		for (let i = 0; i < 50 && i < this.selectedTreeItems.length; i++) {
			this.selectionsArray.push(this.selectedTreeItems[i]);
		}
		this.scrollStart = 50;
	}

	createSelectedItemsListForToggleOff(tree) {
		tree.forEach(node => {
			if (node.selected) {
				const tempItem = {
						'name': node.name,
						'parentLevel': node.levelName,
						'parentNodeId': node.child
				};
				this.createSelectedRestaurantList(node, tempItem);
			} else if (node.children && node.children.length) {
				this.createSelectedItemsListForToggleOff(node.children);
			}
		});
	}

	public createSelectedRestaurantList(node, tempItem) {
		if (node.children && node.children.length) {
			node.children.forEach((child) => this.createSelectedRestaurantList(child, tempItem));
		} else {
			if (node.visible) {
				const nodeCopy = _.cloneDeep(node);
				nodeCopy.parentLevel = tempItem.parentLevel;
				nodeCopy.parentNodeId = tempItem.parentNodeId;
				// Duplicate check
				if (_.findIndex(this.selectedTreeItems, { 'child': node.child }) === -1) {
					this.selectedTreeItems.push(nodeCopy);
				}
			}
			// this.selectedTreeItemsCopy.push(nodeCopy);
		}
		//this.localStorageService.set('selectedRestaurants', this.selectedTreeItems);
	}

	createSelectedItemsListForToggleOn(tree) {
		tree.forEach(node => {
			if (node.selected) {
				if (node.levelName == "Store" || node.levelName == "Restaurant") {
					this.createSelectedItemsListForToggleOff(tree);
					this.flag = false;
				} else {
					const tempNode = _.cloneDeep(node);
					// delete tempNode.children;
					// Duplicate check
					if (_.findIndex(this.selectedTreeItems, { 'child': node.child }) === -1) {
						this.selectedTreeItems.push(tempNode);
					}
					// this.selectedTreeItemsCopy.push(tempNode);
					node.disabled = true;
					this.isbatchScheduling ? "" : node.selected = this.isbatchScheduling;
				}
			}
			if (node.children && node.children.length) {
				this.createSelectedItemsListForToggleOn(node.children);
			}
		});
	}

	removeFromSelection() {

		if (this.isbatchScheduling) {
			for (let res of this.selectionsArray) {
				this.selectedTreeItems.push(res);
			}

			this.selectedTreeItems = this.selectedTreeItems.filter(function (a) {
				return !this[a.restNu] && (this[a.restNu] = true);
			}, Object.create(null));
		}
		const tempRemove = [];
		// const remainingItems = [];
		for (let i = this.selectionsArray.length; i >= 0; i--) {
			if (this.selectionsArray[i] && this.selectionsArray[i].selectedToRemove) {
				// Also removing elements from selectedTreeItems array.
				const index = _.findIndex(this.selectedTreeItems, { 'child': this.selectionsArray[i].child });
				if (index !== -1) {
					this.selectedTreeItems.splice(index, 1);
				}
				// Removing from selections array.
				tempRemove.push(this.selectionsArray[i]);
				this.selectionsArray.splice(i, 1);
			}
		}
		if (!this.selectParentOnly) {
			this.treeHelperService.enableParentNode(this.restaurantTreeData[0], tempRemove);
		} else {
			this.treeHelperService.enableUnselectedNodes(this.restaurantTreeData, tempRemove);
		}

		if (this.isbatchScheduling) {	
			tempRemove.forEach(level1 => {
				if (level1.children) {
					level1.children.forEach(level2 => {
						tempRemove.push(level2);
						if (level2.children) {
							level2.children.forEach(level3 => {
								tempRemove.push(level3);
							});
						}
					});
				}
			});
			this.treeHelperService.enableUnselectedNodes(this.restaurantTreeData, tempRemove);
		}

		// Code should be modified
		// this.generateAuditBusinessService.selectedRestaurantItems =
		// this.selectionsArray;
		this.localStorageService.set('selectedRestaurants', this.selectedTreeItems);
		this.packageScheduleService.selectedRestaurants = this.selectedTreeItems;
	}

	onScroll() {
		console.log('scrolled');
		for (let i = this.scrollStart;
		i < this.scrollStart + 10 && this.scrollStart < this.selectedTreeItems.length; i++) {
			this.selectionsArray.push(this.selectedTreeItems[i]);
		}
		this.scrollStart = this.scrollStart + 10;
	}

	clearSelections() {
		this.selectedTreeItems = [];
        this.selectedTreeItemsCopy = [];
		// this.selectedTreeItemsCopy = [];
		this.selectionsArray = [];
		this.packageScheduleService.selectedRestaurants = []
		this.restaurantTreeData.forEach(element => {
			this.treeHelperService.disableNodes(element, false);
		});
		// Code should be modified
		// this.generateAuditBusinessService.selectedRestaurantItems = [];
		this.localStorageService.set('selectedRestaurants', this.selectedTreeItems);
        this.firstTimeSelection = false;
	}

	removeChildren(tree) {
		let selectedTreeItemsClone = _.cloneDeep(this.selectedTreeItems);
		tree.forEach(node => {
			if (node.selected) {
				if (node.level == 1) {
					this.clearSelections();
					this.selectTreeItem(node);
					this.flag = false;
					this.addToSelecitionBathcShecule();
				} else {
					if (node.children && node.children.length) {
						for (let res of node.children) {
							// on this interator are check if there any node selected on first higher level 
							for (let nodesSelected of selectedTreeItemsClone) {
								if (res.child == nodesSelected.child) {
									const index = _.findIndex(this.selectedTreeItems, { 'child': nodesSelected.child });
									const indexTwo = _.findIndex(this.selectionsArray, { 'child': nodesSelected.child });
									this.selectedTreeItems.splice(index, 1);
									this.selectionsArray.splice(indexTwo, 1);
								}
							}

							if (res.children && res.children.length) {
								// on this interator are check if there any node selected on secound higher level
								for (let resUp of res.children) {
									for (let nodesSelected of selectedTreeItemsClone) {
										if (resUp.child == nodesSelected.child) {
											const index = _.findIndex(this.selectedTreeItems, { 'child': nodesSelected.child });
											const indexTwo = _.findIndex(this.selectionsArray, { 'child': nodesSelected.child });
											this.selectedTreeItems.splice(index, 1);
											this.selectionsArray.splice(indexTwo, 1);
										}
									}
								}
							}
						}
					}
				}
			}
			if (node.children && node.children.length) {
				this.removeChildren(node.children);
			}
		});
	}

    closeModal(){
        this.errorForMaxRestSelection = false;
        this.restaurantTreeData.forEach(data => this.treeHelperService.enableParentNode(data, this.lastSelectionToBeRemoved));
        if(!this.firstTimeSelection){
            this.selectedTreeItems = this.selectedTreeItems.filter(x => this.selectedTreeItemsCopy.includes(x));
        }else{
            this.selectedTreeItems = [];
        }
        this.lastSelectionToBeRemoved = [];
    }

    closeNodeModal(){
        this.errorForEmptyNodeSelection = false;
        if(this.firstTimeSelection){
            this.selectedTreeItems = [];
        }else{
            this.selectedTreeItemsCopy = this.selectedTreeItems.filter(x => !this.errorForEmptyNodeSelectionItems.includes(x));  
            this.selectedTreeItems = [...this.selectedTreeItemsCopy];
        }
        this.lastSelectionToBeRemoved = [];
    }
}
