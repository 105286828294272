import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MiiInactivationService {
    fromHeader = new BehaviorSubject(false);
    clickFromHeader = this.fromHeader.asObservable();

    constructor(private http: HttpClient) {}

    getmiiConfirmationData(): Observable<any> {
        return this.http.get('../assets/mock-json/mock-json/mii-inactivation-confirmation.json');
    }

    getmiiInactivationData(): Observable<any> {
        return this.http.get('../assets/mock-json/mock-json/mii-inactivation.json');
    }

    cancelConfirmFromHeader() {
        this.fromHeader.next(true);
    }
}
