import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { LocalStorageService } from 'angular-2-local-storage';
import { User } from '../../../models/user';
import { UserBean } from '../../../models/userbean';
import { DropdownService } from '../../../services/dropdown.service';
import { appconstant } from '../../../../environments/app.constant';
import { NgxSpinnerService } from 'ngx-spinner';
import { MassPromotionUpdateHttpService } from '../services/masspromotionupdate-http.service';
import { MassPromoPipe } from '../utilities/mass-promo.pipe';
import { MassPromotionUpdateModel } from '../model/masspromotionupdate.model';
import { CommonService } from '../../../services/common.service';
import { filter, map, pick, partialRight, cloneDeep } from 'lodash';
import { HeaderComponent } from '../../header/header.component';

import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Inject, Injectable } from '@angular/core';

import * as moment from 'moment';
import 'moment-timezone';

declare var $: any;
@Component({
	selector: 'app-promotion-selection',
	templateUrl: './masspromotionupdate.promoselection.component.html',
	styleUrls: ['./masspromotionupdate.promoselection.component.css'],
	providers: [MassPromoPipe]
})

@Injectable()
export class PromoSelectionComponent implements OnInit {

	num = 0;
	userDetails: User;
	permissions: any;
	modelIdCancel = '';
	errorMsg: any;
	promoList: any = [];
	exact = false;
	reverse = false;
	msg = '0 records found for the criteria.';
	
	sortColumn = '';
	nameSearch = "";
	templateTypeSearch = ""; 

	//TEMPLATE FILTER - HEADER
    promoTemplateList = [];
	templateSearch = [];
	promoListSettings = {};

	//STATUS FILTER - HEADER
    statusList = [];
	statusSearch = [];
	statusPromoListSettings = {};

	promoSelectionFilterSettings = {};
	
	constants = appconstant;

	contentSelected: string = 'btn legacy-btn btn-sm btn-success btn-responsive btnshape';
	exactSelected: string = 'btn legacy-btn btn-sm  btn-responsive btnshape';
	messageMap: any = this.sessionStorage.get('messageMap');
  
	onlineSelectionModel = this.massPromotionUpdateModel.online;

	promoSelectionModel = this.massPromotionUpdateModel.online.promoSelection;


	updateModel = this.massPromotionUpdateModel.online.updateAndReview;

	promoDateError: boolean;
	dateFormateSelector: any;
	defaultCalenderDateLocal: any;
	momentsDateFormat = 'MMM D, YYYY';
	timeZone = '';
	
	userBean: UserBean = new UserBean();
    
	constructor(@Inject(SESSION_STORAGE) public sessionStorage: StorageService,
            public commonService : CommonService, 
			private localStorageService: LocalStorageService,
			private promoFilterPipe : MassPromoPipe,
			public massPromotionUpdateModel: MassPromotionUpdateModel,
			public dropdownService: DropdownService,
			private massPromotionUpdateHttpService: MassPromotionUpdateHttpService,
			private spinnerService: NgxSpinnerService,
            private headerComponent : HeaderComponent){
	}
    
    retrieveRFMRefreshMessages(){
        this.userDetails = this.sessionStorage.get('userDetails');
        if(this.userDetails && this.userDetails['messageMap']){
            this.messageMap = this.userDetails['messageMap'];
        }
    }
    
	ngOnInit() {
		//search settings
		this.statusPromoListSettings = this.dropdownService.dropDownSingleSettings;
		this.statusPromoListSettings['autoPosition']=false;
		this.statusPromoListSettings['position']='bottom';
		this.promoListSettings = this.dropdownService.dropDownResultTableSettings;
		this.promoListSettings['autoPosition']=false;
		this.promoListSettings['position']='bottom';

		//filter settings
		this.promoSelectionFilterSettings = this.dropdownService.dropDownResultTableSettings;
		this.promoSelectionFilterSettings['autoPosition']=false;
		this.promoSelectionFilterSettings['position']='bottom';

		this.userBean.userEid = this.sessionStorage.get('userEid');
		this.userBean.marketId = this.sessionStorage.get('marketId');
		this.userBean.localeLangId = this.sessionStorage.get('localeLangID');
		this.massPromotionUpdateModel.userBean = this.userBean;
		this.loadPromoDropdowns();
		this.getSelectFlag('c');

		this.promoSelectionModel.promoDropDownListFilterChecked = this.constants.CHECKED_UNCHECKED_ENTRIES;
	}
		
	/**
	* Function used to sort the table.
	* @param key - Column key.
	*/
	sort(key){
		this.sortColumn = key;
		this.onlineSelectionModel.promoSelection.promoList.sort((a, b) => {
			if (a[key] === b[key]) {
				return 0;
			}
		
			if (a[key] === '' || a[key] === null) {
				return 1;
			} else if (b[key] === '' || b[key] === null) {
				return -1;
			} else if (a[key] > b[key]) {
				return 1;
			} else if (a[key] < b[key]) {
				return -1;
			}
			return 0;
		});
		
		if (this.promoSelectionModel.sortAscending[key]) {
			this.onlineSelectionModel.promoSelection.promoList.reverse();
		}
		this.promoSelectionModel.sortAscending[key] = !this.promoSelectionModel.sortAscending[key];
		//this.triggerPipe();
	}
	
	/**
	* Function used to refresh the view by triggering the pipe transform function.
	*/
	triggerPipe() {
		this.num = Math.random();
	}

	/*
	 * Function to check / uncheck all checkboxes, triggered on 'select all'
	 * click. @param : checked - true/ false.
	 */

	selectAllPromos(selected: boolean) {
        console.log("selectAllMenus");
		const length = this.onlineSelectionModel.promoSelection.promoList.length;
		if(selected == true){
			this.promoSelectionModel.existAnyCheckedVar = true;
		}else{
			this.promoSelectionModel.existAnyCheckedVar = false;
		}       
		for (let i = 0; i < length; i++) {
			this.onlineSelectionModel.promoSelection.promoList[i].selected = selected;      
		}    
		this.onlineSelectionModel.promoSelection.formDirty = true;    
	} 

	toggleCheckBox(promo, index){
		promo.selected = !promo.selected;
		(<HTMLInputElement>document.getElementById('promoCheckbox'+index)).checked = promo.selected;
		this.verifyAllCheckbox();
		this.toggleHeader(promo.selected);
	}

	checkboxClickStopper(event){
		event.preventDefault();
		event.stopPropagation();
	}
	
	toggleHeader(checked) {
		if (!checked) {
			this.promoSelectionModel.selectAllPromoHeader = false;        
		} else {
			this.promoSelectionModel.selectAllPromoHeader = this.onlineSelectionModel.promoSelection.promoList
				.every(menu => menu.selected);         
		}    
		this.onlineSelectionModel.promoSelection.formDirty = true; 
		this.verifyAllCheckbox();
	}

	verifyAllCheckbox(){
		const length = this.onlineSelectionModel.promoSelection.promoList.length;
		for (let i = 0; i < length; i++) {
			if(this.onlineSelectionModel.promoSelection.promoList[i].selected == true){
				this.promoSelectionModel.existAnyCheckedVar = true;
				break;
			}else{
				this.promoSelectionModel.existAnyCheckedVar = false;
			}
		}		
	}

	keyDownFunction(event) {
		if (event.keyCode == 13) {
			this.searchPromoList(this.onlineSelectionModel.promoSelection.filterText);
		}
	}
	
	searchPromoList(promoTextInputOnline){
		this.massPromotionUpdateModel.online.promoSelection.promoCode = promoTextInputOnline;
		this.massPromotionUpdateModel.online.promoSelection.promoTemplate = 
			this.massPromotionUpdateHttpService.getSelectedTemplateData(this.templateSearch);
		this.massPromotionUpdateModel.online.promoSelection.promoStatus = this.statusSearch[0].id;

		this.onlineSelectionModel.promoSelection.promoList = [];
		this.promoSelectionModel.showTable = false;
		this.promoSelectionModel.selectAllPromoHeader = false;
		this.promoSelectionModel.existAnyCheckedVar = false;
		
		$('#collapseOne').collapse('hide');
		$('#collapseTwo').collapse('show');
		$('#collapseThree').collapse('hide');

		this.spinnerService.show();
		this.massPromotionUpdateHttpService.loadPromotionList("promotionSearch", this.massPromotionUpdateModel)
			.subscribe((promoList) => {
				
				if (promoList == null || !promoList.length){
					this.msg = '0 records found for the search criteria.';
				}else{
					this.msg = '0 records found for the criteria.';
                    for (let i = 0; i < promoList.length; i++) {
						promoList[i].promoTemplateLabel = this.messageMap[promoList[i].promoTemplate];
						promoList[i].selected = false;
                    }
				}

				this.onlineSelectionModel.promoSelection.promoList = promoList;
				this.promoSelectionModel.duplicatePromoList = _.cloneDeep(this.onlineSelectionModel.promoSelection.promoList);
				
				this.promoSelectionModel.promoDropDownListFilterStatus =  this.massPromotionUpdateHttpService.getFltrSts(
					this.massPromotionUpdateHttpService.getUniqueData(
						this.onlineSelectionModel.promoSelection.promoList, 'promoStatus'), 'promoStatus');

				this.spinnerService.hide();
				this.promoSelectionModel.showTable = true;
			}, err => this.errorMsg = <any>err);
			this.commonSearchAction();
	}

	searchPromoListNewlyAdded(){
		//this.onlineSelectionModel.promoSelection.promoList = [];
		//this.promoSelectionModel.showTable = false;
		this.promoSelectionModel.selectAllPromoHeader = false;
		this.promoSelectionModel.existAnyCheckedVar = false;
		
		$('#collapseOne').collapse('hide');
		$('#collapseTwo').collapse('show');
		$('#collapseThree').collapse('hide');

		this.spinnerService.show();
		this.massPromotionUpdateHttpService.loadPromotionList("promotionSearch", this.massPromotionUpdateModel)
			.subscribe((promoList) => {
				
				if (promoList == null || !promoList.length){
					this.msg = '0 records found for the search criteria.';
				}else{
					this.msg = '0 records found for the criteria.';
                    for (let i = 0; i < promoList.length; i++) {
						promoList[i].promoTemplateLabel = this.messageMap[promoList[i].promoTemplate];
						promoList[i].selected = false;
                    }
				}

				this.onlineSelectionModel.promoSelection.promoList = 
					this.onlineSelectionModel.promoSelection.promoList.concat(promoList);
				this.promoSelectionModel.duplicatePromoList = _.cloneDeep(this.onlineSelectionModel.promoSelection.promoList);
				
				this.promoSelectionModel.promoDropDownListFilterStatus =  this.massPromotionUpdateHttpService.getFltrSts(
					this.massPromotionUpdateHttpService.getUniqueData(
						this.onlineSelectionModel.promoSelection.promoList, 'promoStatus'), 'promoStatus');

				this.onlineSelectionModel.promoSelection.promoList.sort(function (a, b) {   
					return a.restNumber - b.restNumber || a.promoCode - b.promoCode;
				});

				this.spinnerService.hide();
				this.promoSelectionModel.showTable = true;
			}, err => this.errorMsg = <any>err);
			this.commonSearchAction();
	}

	commonSearchAction() {
		this.promoSelectionModel.filter = {
			restNumber : '',
			nodeName : '',
			promoCode : '',
			promoName : '',
			promoLongDesc : '',
			promoTemplateLabel : '',
			strtDate : '',
			endDate : '',
			status : ''
		};
		this.promoSelectionModel.sortAscending = {
			restNumber: false,
			nodeName: false,
			promoCode: false,
			promoName: false,
			promoLongDesc: false,
			promoTemplateLabel: false,
			strtDate: false,
			endDate: false,
			status: false
		};

		this.promoSelectionModel.promoStatusFilter = [];
		this.promoSelectionModel.promoCheckedFilter = [];
	}

	filterPromo(){
		if (this.onlineSelectionModel.promoSelection.promoList.length === this.promoSelectionModel.duplicatePromoList.length) {
			this.promoSelectionModel.duplicatePromoList = _.cloneDeep(this.onlineSelectionModel.promoSelection.promoList);
		}
		this.onlineSelectionModel.promoSelection.promoList = this.promoFilterPipe.transform(
			this.promoSelectionModel.duplicatePromoList, this.promoSelectionModel.filter,
			this.promoSelectionModel.promoStatusFilter, this.promoSelectionModel.promoCheckedFilter);
	}

	/* Select Contains/Exact Flag */
	getSelectFlag(flag) {
        console.log("getSelectFlag " + flag);
		if (flag == 'c') {
			this.contentSelected = 'btn legacy-btn btn-sm btn-responsive btnshape btn-success btn-contain';
			this.exactSelected = 'btn legacy-btn btn-sm  btn-responsive btnshape';
			this.onlineSelectionModel.promoSelection.filterType = 'CONTAINS';
		} else if (flag == 'e') {
			this.contentSelected = 'btn legacy-btn btn-sm  btn-responsive btnshape';
			this.exactSelected = 'btn legacy-btn btn-sm btn-responsive btnshape  btn-success btn-contain';
			this.onlineSelectionModel.promoSelection.filterType = 'EXACT';
		}
	}

	loadPromoDropdowns() {
		this.massPromotionUpdateHttpService.loadPromoLookup("loadPromoLookup", this.userBean)
			.subscribe((lookupList) => {
				this.statusList = [];
				this.statusList = this.massPromotionUpdateHttpService.getStatusDropdownData(lookupList.statusList);
				if (this.statusList && this.statusList.length) {
					this.statusList.forEach(item => {
					  if(item.itemName == "All"){
						this.statusSearch.push(item);
					  }
					});
				}
		}, err => this.errorMsg = <any>err);
		
		this.promoTemplateList = [];
		this.massPromotionUpdateHttpService.loadTemplateList("loadTemplateList", this.userBean)
			.subscribe((templateList) => {
				this.promoTemplateList = templateList;
				this.promoTemplateList = this.massPromotionUpdateHttpService.getTemplateDropdownData(this.promoTemplateList, this.messageMap);
		}, err => this.errorMsg = <any>err);
	}
 
	listPromotionMassData() {
		const selectedItems = filter(this.massPromotionUpdateModel.online.promoSelection.promoList, { 'selected': true as any});
		if (selectedItems.length) {
			this.spinnerService.show();
				//this.templateSearch = [];
				this.massPromotionUpdateModel.online.promoSelection.isReadOnly = false;
				this.massPromotionUpdateModel.online.promoSelection.disableUpdate = true;
				//this.massPromotionUpdateModel.online.promoSelection.filterText = '';
				
				this.promoSelectionModel.showTable = true;
				this.updateModel.showUpdateTable = true;
				this.updateModel.table = selectedItems;
				

				this.updateModel.promoDropDownListFilterStatus =  this.massPromotionUpdateHttpService.getFltrSts(
					this.massPromotionUpdateHttpService.getUniqueData(
					  this.updateModel.table, 'promoStatus'), 'promoStatus');

				this.updateModel.duplicatePromoUpdateList = _.cloneDeep(this.updateModel.table);

				if (this.updateModel.table && this.updateModel.table) {
					this.updateModel.table.forEach(item => {
					  item.strtDate = moment(item.promotionDateFrom, "MM/DD/YYYY").format(this.momentsDateFormat);
					  item.endDate = moment(item.promotionDateTo, "MM/DD/YYYY").format(this.momentsDateFormat);

					  item.promoStrtDateDisabled = moment(item.promotionDateFrom).isBefore(moment());
					});
				  }
				
				this.updateModel.duplicatedSelectedPromoList = _.cloneDeep(this.updateModel.table);

				// getting time zone from local storage
				this.timeZone = this.sessionStorage.get('timeZone');
				moment.tz.setDefault(this.timeZone);
				moment.locale(this.timeZone);
			
				// setting today's date to default
				this.defaultCalenderDateLocal = moment().tz(this.timeZone).format('MM/DD/YYYY');
				this.dateFormateSelector = 'M d, yyyy';

				$.getScript('assets/js/bootstrap-datepicker.js');
				$.getScript('assets/js/datepicker.js');

				this.spinnerService.hide();
				$('#collapseOne').collapse('hide');
				$('#collapseTwo').collapse('hide');
				$('#collapseThree').collapse('show');
		}
	}

	resetTable() {
		this.onlineSelectionModel.promoSelection.promoList = [];
		this.massPromotionUpdateModel.online.promoSelection.filterText = '';
		this.templateSearch = [];
		this.promoSelectionModel.showTable = false;
		this.promoSelectionModel.existAnyCheckedVar = false;
		this.promoSelectionModel.selectAllPromoHeader = false;
	}

	onClickCancel(){
//		if (this.onlineSelectionModel.promoSelection.promoList.length > 0 ||
//				(this.massPromotionUpdateModel.online.promoSelection.filterText != "" 
//				&& this.massPromotionUpdateModel.online.promoSelection.filterText != undefined) ||
//				this.templateSearch.length > 0){
//			this.modelIdCancel = '#modal-questionPromoSelection';
//		}
        this.headerComponent.goToRFM();
	}

	cancel(cancel){
        console.log("cancel");
		if (cancel == 'cancel'){
			this.modelIdCancel = "";
		}else{
			this.resetTable();
			this.modelIdCancel = "";
		}
	}	

}
