import { Component, OnInit, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { HostListener } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { AuthUserService } from './services/auth-user.service';
import { Router, NavigationStart } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Inject, Injectable } from '@angular/core';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { OwnerOperatorHeaderService } from './shared/services/owner-operator-header.service';
// This is needed for POS Layout popup
import '../assets/js/OOPPOSCallback.js';
import { OOP_URL_CONST } from './shared/constants/rfmOOPUrl.constant';
import { connection } from '../environments/connection.properties';
import { CommonService } from './services/common.service';
import {environment} from '../environments/environment'
import { appconstant } from '../environments/app.constant';
import { NgbModalConfig, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

declare var $: any;
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
@Injectable()
export class AppComponent implements OnInit {

	title = 'app';
	userDetails: any;
	errorMsg: string;
	loginUserStatus: any;
	redirectUrl: any;
	urlFromOnline: any;
	urlParamsFromOnline: any;
	isSessionStorage = "false";
	public ownerOperatorFlag: boolean = true;
	getApplicationUrl: any;
	public newLegacyPageFlag: boolean = false;
	public currentURL: any;
	innerWidth: any;
	hideRoot: boolean = true;
	//RFM-25232
	loaderTimeout: number = environment.ng4LoaderSpinnerTimeout;
	urlToCall: string ='';
	walkmePayload= {
		marketId: 0,
		paramName: ''
	};
	walkmeUrl: string ='';
	
	constructor(
        @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
		private spinnerService: NgxSpinnerService,
		private router: Router, 
        private ownerOperatorHeaderService: OwnerOperatorHeaderService,
		private authUserService: AuthUserService,
		private localStorageService: LocalStorageService,
		private cookieService: CookieService,
		private http: HttpClient, 
		private commonService: CommonService,
        private responsiveObserver: BreakpointObserver,
        private modalConfig: NgbModalConfig,
        private tooltipConfig: NgbTooltipConfig) {
            
        modalConfig.backdrop = 'static';
        modalConfig.backdropClass = 'rfm2-modal-overlay';
        modalConfig.centered = true;
        modalConfig.keyboard = false;
        modalConfig.animation = false;
        tooltipConfig.tooltipClass = 'rfm2-tooltip';
        tooltipConfig.placement = 'top auto';
        tooltipConfig.container = 'body';
		
		this.urlFromOnline = window.location.pathname.split(';');
		var paramcount = window.location.pathname.split(';').length;

		if (window.location.pathname.indexOf('data-transfer') > 0) {
			this.redirectUrl = window.location.pathname;
		} else {
			if (this.urlFromOnline.length == 1) {
				this.redirectUrl = window.location.pathname;
			} else {

				this.urlParamsFromOnline = this.urlFromOnline;
				//				this.sessionStorage.set(this.urlParamsFromOnline[1].split('=')[0], this.urlParamsFromOnline[1].split('=')[1]); //localeLangDesc
				//				this.sessionStorage.set(this.urlParamsFromOnline[2].split('=')[0], this.urlParamsFromOnline[2].split('=')[1]); //layeringLogicType
				//				this.sessionStorage.set(this.urlParamsFromOnline[3].split('=')[0], this.urlParamsFromOnline[3].split('=')[1]); //marketId
				//				this.sessionStorage.set(this.urlParamsFromOnline[4].split('=')[0], this.urlParamsFromOnline[4].split('=')[1]); //selectedLanguage
				//				this.sessionStorage.set(this.urlParamsFromOnline[5].split('=')[0], decodeURI(this.urlParamsFromOnline[5].split('=')[1])); //selectedDateFormat
				//				this.sessionStorage.set(this.urlParamsFromOnline[6].split('=')[0], this.urlParamsFromOnline[6].split('=')[1]); //localeLangID
				//				this.sessionStorage.set(this.urlParamsFromOnline[7].split('=')[0], this.urlParamsFromOnline[7].split('=')[1]); //rfmRefreshPage
				//				this.sessionStorage.set(this.urlParamsFromOnline[8].split('=')[0], this.urlParamsFromOnline[8].split('=')[1]); //ptaURL
				//				this.sessionStorage.set(this.urlParamsFromOnline[9].split('=')[0], decodeURI(this.urlParamsFromOnline[9].split('=')[1])); //marketName
				//				this.sessionStorage.set(this.urlParamsFromOnline[10].split('=')[0], this.urlParamsFromOnline[10].split('=')[1]); //userEid
				//				this.sessionStorage.set(this.urlParamsFromOnline[11].split('=')[0], this.urlParamsFromOnline[11].split('=')[1]); //userToken
				//				this.sessionStorage.set(this.urlParamsFromOnline[12].split('=')[0], this.urlParamsFromOnline[12].split('=')[1]); //timeZone
				//				this.sessionStorage.set(this.urlParamsFromOnline[13].split('=')[0], this.urlParamsFromOnline[13].split('=')[1]); //isSessionStorage
				//				this.sessionStorage.set(this.urlParamsFromOnline[14].split('=')[0], this.urlParamsFromOnline[14].split('=')[1]); //accessToken
				//				this.sessionStorage.set(this.urlParamsFromOnline[15].split('=')[0], this.urlParamsFromOnline[15].split('=')[1]); //userId
				//				this.sessionStorage.set(this.urlParamsFromOnline[16].split('=')[0], this.urlParamsFromOnline[16].split('=')[1]); //firstName
				//				this.sessionStorage.set(this.urlParamsFromOnline[17].split('=')[0], this.urlParamsFromOnline[17].split('=')[1]); //lastName
				//				this.isSessionStorage = this.urlParamsFromOnline[13].split('=')[1];
				for (var i = 1; i < paramcount; i++) {
					if (this.urlParamsFromOnline[i].split('=')[0] == 'selectedDateFormat') {
						this.sessionStorage.set(this.urlParamsFromOnline[i].split('=')[0], decodeURI(this.urlParamsFromOnline[i].split('=')[1]).toUpperCase());
						this.sessionStorage.set('auditLogDateFormat', decodeURI(this.urlParamsFromOnline[i].split('=')[1]));
					} else {
						this.sessionStorage.set(this.urlParamsFromOnline[i].split('=')[0], decodeURI(this.urlParamsFromOnline[i].split('=')[1]));
					}
				}
				this.isSessionStorage = this.sessionStorage.get("isSessionStorage");
			}
		}
	}

	ngOnInit() {
		setInterval(()=>{
			this.hideRoot = false;
		},90);
		
		this.walkmePayload.marketId = this.sessionStorage.get('marketId');
		this.walkmePayload.paramName = appconstant.ENABLE_WALKME;

		if(connection.RELATIVE_PATH == "true" || connection.RELATIVE_PATH == 'true'){
			this.urlToCall = location.protocol + "//" + location.hostname + ":" + 
			connection.RELATIVE_API_PORT + connection.RELATIVE_API_PATH;
		} else {
			this.urlToCall = connection.API_FULL_URL;
		}

		this.router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				this.currentURL = event.url;
			}
		});
		this.ckeckApplictionPage();
		
		this.commonService.detectScreen(window.innerWidth);
		// console.log("login called..");

		// console.log('cookie TK = ' + this.cookieService.get('TK'));

		if (this.isSessionStorage == "true") {
			this.authUserService.getAuthUserDetails('login').subscribe((users) => {
				this.userDetails = users;
				this.sessionStorage.set('loginUserStatus', 'true');
				this.sessionStorage.set('userDetails', users);
				this.sessionStorage.set('permissionMap', users.permissionMap);
				this.sessionStorage.set('messageMap', users.messageMap);
				this.sessionStorage.set('walkmeUrl', users.walkmeUrl);
				this.sessionStorage.set('restProfileMsUrl', users.restProfileMsUrl);
				this.sessionStorage.set('hierarchyMsUrl', users.hierarchyMsUrl);
				if (this.sessionStorage.get('isRest') == 'R') {
					this.sessionStorage.set('restNodeList', users.restNodeList);
				}
				window.location.href = this.sessionStorage.get('rfmRefreshPage');
			}, err => {
				this.errorMsg = <any>err;
				this.spinnerService.hide();
				this.ownerOperatorHeaderService.OOPlogout();
			});


		} else {
			if (this.localStorageService.get('loginUserStatus') != 'true') {
				this.authUserService.getAuthUserDetails('login').subscribe((users) => {
					this.userDetails = users;
					this.localStorageService.set('loginUserStatus', 'true');
					this.localStorageService.set('userDetails', users);
					this.localStorageService.set('permissionMap', users.permissionMap);
					this.localStorageService.set('messageMap', users.messageMap);
					this.localStorageService.set('walkmeUrl', users.walkmeUrl);
					this.localStorageService.set('layeringLogicType', users.layeringLogicType);
					this.localStorageService.set('isSessionStorage', this.isSessionStorage);

					this.spinnerService.hide();
					this.router.navigate([this.redirectUrl]);

				}, err => {
					this.errorMsg = <any>err;
					this.spinnerService.hide();
					this.ownerOperatorHeaderService.OOPlogout();
				});
			}
		}

		this.walkmeUrl = this.sessionStorage.get('walkmeUrl')!=''?this.sessionStorage.get('walkmeUrl'):this.localStorageService.get('walkmeUrl');

		this.http.post(this.urlToCall+'getValueFromGlobalParam', this.walkmePayload, {responseType: 'text'})
		.subscribe((paramValue: string) => {
			if(paramValue != null && paramValue.toString().toLowerCase() === 'true' && this.walkmeUrl != ''){
				this.loadWalkme();
			}
		});
        this.responsiveObserver.observe([Breakpoints.Tablet]).subscribe(result => {
            if (result.matches) {
                this.commonService.setIsTablet(true);
            }else{
                this.commonService.setIsTablet(false);
            }
        });
	}
	
	@HostListener('window:resize', ['$event'])
  	onResize(event) {
		this.innerWidth = window.innerWidth;
		this.commonService.detectScreen(this.innerWidth);
  	}
	ckeckApplictionPage() {
		this.http.get(OOP_URL_CONST.APPLICATION_ROUTING_URLS).subscribe(data => {
			let urlData = data['url'];
			var routingEventUrl = location.pathname;
			
			if (location.pathname == this.currentURL || location.pathname == "" || location.pathname == "/")
				routingEventUrl = this.currentURL;
			else if (this.currentURL == "" || this.currentURL == "/")
				routingEventUrl = location.pathname;

			var event_url;
			var regex = /[;?]/g;
			if (regex.exec(routingEventUrl)) {
				event_url = routingEventUrl.split(regex)[0];
			}
			else
				event_url = routingEventUrl;

			var isUrlPresent = urlData.filter(function (urlDataObj) {
				return urlDataObj.routingUrl == event_url;
			});
			if (isUrlPresent.length != 0 ) {
				if (isUrlPresent[0].routingUrl == event_url && isUrlPresent[0].pageIn == 'NewRfm') {
					this.ownerOperatorFlag = true;
					if ( isUrlPresent[0].pageType == 'Admin')
						this.newLegacyPageFlag = true;
					else
						this.newLegacyPageFlag = false;
				}
				if (isUrlPresent[0].routingUrl == event_url && isUrlPresent[0].pageIn == 'OldRfm')
					this.ownerOperatorFlag = false;
			}
			else if(this.currentURL!=OOP_URL_CONST.OWNER_OPERATOR_HOMEPAGE){
				this.ownerOperatorFlag = false;
			}
            
			this.applyStyles(this.ownerOperatorFlag);

		});
	}

	applyStyles(appliStatus)
	{
		if(appliStatus == true){ 

			$('<link/>', {
				id: 'cssRefresh3',
				rel: 'stylesheet',
				type: 'text/css',
				href: '../assets/css/rfm_uitable.css'
			}).appendTo('head');
			$('#cssLegacy1').remove();
		}
		else if(appliStatus == false){ //apply old RFM style and scripts

			$('<link/>', {
				id: 'cssLegacy1',
				rel: 'stylesheet',
				type: 'text/css',
				href: '../assets/css/bootstrap3.min.css'
			}).appendTo('head');

			$('<link/>', {
				id: 'cssLegacy3',
				rel: 'stylesheet',
				type: 'text/css',
				href: '../assets/css/style.css'
			}).appendTo('head');
			 
			$('#cssRefresh1').remove();
			$('#cssRefresh2').remove();
			$('#cssRefresh3').remove();
			$('#cssRefresh4').remove();
			$('#cssRefresh5').remove();

			$.getScript('../assets/js/bootstrap3.min.js');
		}
		
	}

	loadWalkme(){
		var walkme = document.createElement('script');
		walkme.type = 'text/javascript';
		walkme.async = true;
		walkme.src = this.walkmeUrl;
		var s = document.getElementsByTagName('script')[0];
		s.parentNode.insertBefore(walkme, s);
		window['_walkmeConfig'] = { smartLoad: true };
	}
}
