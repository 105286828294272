import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
	selector: 'app-treegen-selection',
	templateUrl: './masspromotionupdate.treegenselection.component.html',
	styleUrls: ['./masspromotionupdate.treegenselection.component.css']
})

export class MassPromotionUpdateTreeGenSelectionComponent implements OnInit {

	constructor() { 
	}

	ngOnInit() {
	}

}