import { Injectable, Inject} from '@angular/core';
import { HttpCoreService } from '../../services/http-core.service';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Observable, BehaviorSubject} from 'rxjs';
import { OOP_CONST } from '../constants/rfmOOP.constant';

@Injectable({
  providedIn: 'root'
})
export class OwnerOperatorChangeMarketLanguageService {
  userBean:any=
  {
  "userEid": "",
  "marketId":"",
  "localeLangID":"",
  "userToken": "",
  "isRest":"",
  "selectedLanguage":""
  } 

  constructor(  @Inject(SESSION_STORAGE) private sessionStorage: StorageService, public httpCoreService: HttpCoreService) {}
     public changeLanguageMarket(apiService,data): Observable<any>{
      ////selected Market/////
      this.userBean.marketId = data.selectedMarket;
      this.userBean.userEid = this.sessionStorage.get(OOP_CONST.USER_EID);
      this.userBean.userToken = this.sessionStorage.get(OOP_CONST.USER_TOKEN);
      this.userBean.isRest = this.sessionStorage.get(OOP_CONST.IS_REST);
      
      ////selected Language/////
      this.userBean.selectedLanguage = data.selectedLanguage;
      this.userBean.localeLangID = data.selectedLanguageId;

      return this.httpCoreService.getHttpUrl(apiService, this.userBean, OOP_CONST.POST);

     }
   }

