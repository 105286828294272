export const Permissions = {

    PackageGenerationPermission: 'PackageGenerationPermission',
    MIIPermission: 'MIIPermission',
    ActionItemAdminPermission: 'ActionItemAdminPermission',
    GenerateReportPermission: 'GenerateReportPermission',
    POSLayoutPermission: 'POSLayoutPermission',
    PTAPermission: 'PTAPermission',
    OOPHomePermission: 'OOPHomePermission',
    ActionItemCreatePermission: 'ActionItemCreatePermission',
    ActionItemEditPermission: 'ActionItemEditPermission',
    MassMIDeletePermission: 'MassMIDeletePermission',
    DimensionGroupPermission: 'DimensionGroupPermission',
}
