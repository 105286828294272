import { Injectable, Inject } from '@angular/core';
import { Observable, from } from 'rxjs';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { connection } from '../../../../../environments/connection.properties';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EffectDateService {

  dateRequest:any = {
		"userId": "",
		"marketId":"",
    "exactMatch":"",
    "searchString": "",
		"setType":"",
		"node":""
	};

  constructor(
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    private http:HttpClient, ) { }

  getMarketDate(url) {
    var path = connection.API_FULL_URL + url;
    if(connection.RELATIVE_PATH == "true" || connection.RELATIVE_PATH == 'true'){
			path = location.protocol + "//" + location.hostname + ":" + 
				connection.RELATIVE_API_PORT + connection.RELATIVE_API_PATH+url;
    }

    this.dateRequest.userEID = this.sessionStorage.get("userEid");
    this.dateRequest.marketId =Number(this.sessionStorage.get("marketId")) ;
    this.dateRequest.exactMatch = this.sessionStorage.get("exactMatch")==undefined?false:this.sessionStorage.get("exactMatch");
    this.dateRequest.searchString = this.sessionStorage.get("searchString")==undefined?"":this.sessionStorage.get("searchString");
    this.dateRequest.setType = this.sessionStorage.get("setType")==undefined?"":this.sessionStorage.get("setType");
    this.dateRequest.node = this.sessionStorage.get("node")==undefined?null:this.sessionStorage.get("node");
    // return this.http.post(path, JSON.stringify(this.dateRequest), {responseType: 'text'})
    return this.http.post(path, JSON.stringify(this.dateRequest), {headers: new HttpHeaders({"Content-Type": "application/json"}), responseType: "text"} )
      .pipe(map(res => res));
  }

}
